// Generated by purs bundle 0.12.5
var PS = {};

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Alt"] = $PS["Control.Alt"] || {};
  var exports = $PS["Control.Alt"];

  var Alt = function Alt(Functor0, alt) {
    this.Functor0 = Functor0;
    this.alt = alt;
  };

  var alt = function alt(dict) {
    return dict.alt;
  };

  exports["Alt"] = Alt;
  exports["alt"] = alt;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Semigroupoid"] = $PS["Control.Semigroupoid"] || {};
  var exports = $PS["Control.Semigroupoid"];

  var Semigroupoid = function Semigroupoid(compose) {
    this.compose = compose;
  };

  var semigroupoidFn = new Semigroupoid(function (f) {
    return function (g) {
      return function (x) {
        return f(g(x));
      };
    };
  });

  var compose = function compose(dict) {
    return dict.compose;
  };

  exports["compose"] = compose;
  exports["Semigroupoid"] = Semigroupoid;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Category"] = $PS["Control.Category"] || {};
  var exports = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];

  var Category = function Category(Semigroupoid0, identity) {
    this.Semigroupoid0 = Semigroupoid0;
    this.identity = identity;
  };

  var identity = function identity(dict) {
    return dict.identity;
  };

  var categoryFn = new Category(function () {
    return Control_Semigroupoid.semigroupoidFn;
  }, function (x) {
    return x;
  });
  exports["Category"] = Category;
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Function"] = $PS["Data.Function"] || {};
  var exports = $PS["Data.Function"];

  var flip = function flip(f) {
    return function (b) {
      return function (a) {
        return f(a)(b);
      };
    };
  };

  var $$const = function $$const(a) {
    return function (v) {
      return a;
    };
  };

  exports["flip"] = flip;
  exports["const"] = $$const;
})(PS);

(function (exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);

      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }

      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});

(function (exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Unit"] = $PS["Data.Unit"] || {};
  var exports = $PS["Data.Unit"];
  var $foreign = $PS["Data.Unit"];
  exports["unit"] = $foreign.unit;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Functor"] = $PS["Data.Functor"] || {};
  var exports = $PS["Data.Functor"];
  var $foreign = $PS["Data.Functor"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];

  var Functor = function Functor(map) {
    this.map = map;
  };

  var map = function map(dict) {
    return dict.map;
  };

  var mapFlipped = function mapFlipped(dictFunctor) {
    return function (fa) {
      return function (f) {
        return map(dictFunctor)(f)(fa);
      };
    };
  };

  var $$void = function $$void(dictFunctor) {
    return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };

  var voidLeft = function voidLeft(dictFunctor) {
    return function (f) {
      return function (x) {
        return map(dictFunctor)(Data_Function["const"](x))(f);
      };
    };
  };

  var voidRight = function voidRight(dictFunctor) {
    return function (x) {
      return map(dictFunctor)(Data_Function["const"](x));
    };
  };

  var functorFn = new Functor(Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn));
  var functorArray = new Functor($foreign.arrayMap);
  exports["Functor"] = Functor;
  exports["map"] = map;
  exports["mapFlipped"] = mapFlipped;
  exports["void"] = $$void;
  exports["voidRight"] = voidRight;
  exports["voidLeft"] = voidLeft;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Apply"] = $PS["Control.Apply"] || {};
  var exports = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];

  var Apply = function Apply(Functor0, apply) {
    this.Functor0 = Functor0;
    this.apply = apply;
  };

  var apply = function apply(dict) {
    return dict.apply;
  };

  var applySecond = function applySecond(dictApply) {
    return function (a) {
      return function (b) {
        return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
      };
    };
  };

  var lift2 = function lift2(dictApply) {
    return function (f) {
      return function (a) {
        return function (b) {
          return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b);
        };
      };
    };
  };

  exports["Apply"] = Apply;
  exports["apply"] = apply;
  exports["applySecond"] = applySecond;
  exports["lift2"] = lift2;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Applicative"] = $PS["Control.Applicative"] || {};
  var exports = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Unit = $PS["Data.Unit"];

  var Applicative = function Applicative(Apply0, pure) {
    this.Apply0 = Apply0;
    this.pure = pure;
  };

  var pure = function pure(dict) {
    return dict.pure;
  };

  var when = function when(dictApplicative) {
    return function (v) {
      return function (v1) {
        if (v) {
          return v1;
        }

        ;

        if (!v) {
          return pure(dictApplicative)(Data_Unit.unit);
        }

        ;
        throw new Error("Failed pattern match at Control.Applicative (line 57, column 1 - line 57, column 63): " + [v.constructor.name, v1.constructor.name]);
      };
    };
  };

  var liftA1 = function liftA1(dictApplicative) {
    return function (f) {
      return function (a) {
        return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
      };
    };
  };

  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["when"] = when;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Bind"] = $PS["Control.Bind"] || {};
  var exports = $PS["Control.Bind"];
  var Data_Function = $PS["Data.Function"];

  var Discard = function Discard(discard) {
    this.discard = discard;
  };

  var Bind = function Bind(Apply0, bind) {
    this.Apply0 = Apply0;
    this.bind = bind;
  };

  var discard = function discard(dict) {
    return dict.discard;
  };

  var bind = function bind(dict) {
    return dict.bind;
  };

  var bindFlipped = function bindFlipped(dictBind) {
    return Data_Function.flip(bind(dictBind));
  };

  var composeKleisli = function composeKleisli(dictBind) {
    return function (f) {
      return function (g) {
        return function (a) {
          return bind(dictBind)(f(a))(g);
        };
      };
    };
  };

  var discardUnit = new Discard(function (dictBind) {
    return bind(dictBind);
  });
  exports["Bind"] = Bind;
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["Discard"] = Discard;
  exports["discard"] = discard;
  exports["composeKleisli"] = composeKleisli;
  exports["discardUnit"] = discardUnit;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Monad"] = $PS["Control.Monad"] || {};
  var exports = $PS["Control.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];

  var Monad = function Monad(Applicative0, Bind1) {
    this.Applicative0 = Applicative0;
    this.Bind1 = Bind1;
  };

  var ap = function ap(dictMonad) {
    return function (f) {
      return function (a) {
        return Control_Bind.bind(dictMonad.Bind1())(f)(function (v) {
          return Control_Bind.bind(dictMonad.Bind1())(a)(function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(v(v1));
          });
        });
      };
    };
  };

  exports["Monad"] = Monad;
  exports["ap"] = ap;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Either"] = $PS["Data.Either"] || {};
  var exports = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];

  var Left = function () {
    function Left(value0) {
      this.value0 = value0;
    }

    ;

    Left.create = function (value0) {
      return new Left(value0);
    };

    return Left;
  }();

  var Right = function () {
    function Right(value0) {
      this.value0 = value0;
    }

    ;

    Right.create = function (value0) {
      return new Right(value0);
    };

    return Right;
  }();

  var functorEither = new Data_Functor.Functor(function (f) {
    return function (m) {
      if (m instanceof Left) {
        return new Left(m.value0);
      }

      ;

      if (m instanceof Right) {
        return new Right(f(m.value0));
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 38, column 8 - line 38, column 52): " + [m.constructor.name]);
    };
  });

  var fromRight = function fromRight(dictPartial) {
    return function (v) {
      if (v instanceof Right) {
        return v.value0;
      }

      ;
      throw new Error("Failed pattern match at Data.Either (line 261, column 1 - line 261, column 52): " + [v.constructor.name]);
    };
  };

  var either = function either(v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Left) {
          return v(v2.value0);
        }

        ;

        if (v2 instanceof Right) {
          return v1(v2.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Either (line 238, column 1 - line 238, column 64): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
      };
    };
  };

  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["fromRight"] = fromRight;
  exports["functorEither"] = functorEither;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Monad.Error.Class"] = $PS["Control.Monad.Error.Class"] || {};
  var exports = $PS["Control.Monad.Error.Class"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];

  var MonadThrow = function MonadThrow(Monad0, throwError) {
    this.Monad0 = Monad0;
    this.throwError = throwError;
  };

  var MonadError = function MonadError(MonadThrow0, catchError) {
    this.MonadThrow0 = MonadThrow0;
    this.catchError = catchError;
  };

  var throwError = function throwError(dict) {
    return dict.throwError;
  };

  var catchError = function catchError(dict) {
    return dict.catchError;
  };

  var $$try = function $$try(dictMonadError) {
    return function (a) {
      return catchError(dictMonadError)(Data_Functor.map(dictMonadError.MonadThrow0().Monad0().Bind1().Apply0().Functor0())(Data_Either.Right.create)(a))(function ($21) {
        return Control_Applicative.pure(dictMonadError.MonadThrow0().Monad0().Applicative0())(Data_Either.Left.create($21));
      });
    };
  };

  exports["catchError"] = catchError;
  exports["throwError"] = throwError;
  exports["MonadThrow"] = MonadThrow;
  exports["MonadError"] = MonadError;
  exports["try"] = $$try;
})(PS);

(function (exports) {
  "use strict";

  exports.concatString = function (s1) {
    return function (s2) {
      return s1 + s2;
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Semigroup"] = $PS["Data.Semigroup"] || {};
  var exports = $PS["Data.Semigroup"];
  var $foreign = $PS["Data.Semigroup"];

  var Semigroup = function Semigroup(append) {
    this.append = append;
  };

  var semigroupString = new Semigroup($foreign.concatString);

  var append = function append(dict) {
    return dict.append;
  };

  exports["Semigroup"] = Semigroup;
  exports["append"] = append;
  exports["semigroupString"] = semigroupString;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Monad.Except.Trans"] = $PS["Control.Monad.Except.Trans"] || {};
  var exports = $PS["Control.Monad.Except.Trans"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Semigroup = $PS["Data.Semigroup"];

  var ExceptT = function ExceptT(x) {
    return x;
  };

  var withExceptT = function withExceptT(dictFunctor) {
    return function (f) {
      return function (v) {
        var mapLeft = function mapLeft(v1) {
          return function (v2) {
            if (v2 instanceof Data_Either.Right) {
              return new Data_Either.Right(v2.value0);
            }

            ;

            if (v2 instanceof Data_Either.Left) {
              return new Data_Either.Left(v1(v2.value0));
            }

            ;
            throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 42, column 3 - line 42, column 32): " + [v1.constructor.name, v2.constructor.name]);
          };
        };

        return ExceptT(Data_Functor.map(dictFunctor)(mapLeft(f))(v));
      };
    };
  };

  var runExceptT = function runExceptT(v) {
    return v;
  };

  var mapExceptT = function mapExceptT(f) {
    return function (v) {
      return f(v);
    };
  };

  var functorExceptT = function functorExceptT(dictFunctor) {
    return new Data_Functor.Functor(function (f) {
      return mapExceptT(Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Either.functorEither)(f)));
    });
  };

  var except = function except(dictApplicative) {
    return function ($96) {
      return ExceptT(Control_Applicative.pure(dictApplicative)($96));
    };
  };

  var monadExceptT = function monadExceptT(dictMonad) {
    return new Control_Monad.Monad(function () {
      return applicativeExceptT(dictMonad);
    }, function () {
      return bindExceptT(dictMonad);
    });
  };

  var bindExceptT = function bindExceptT(dictMonad) {
    return new Control_Bind.Bind(function () {
      return applyExceptT(dictMonad);
    }, function (v) {
      return function (k) {
        return Control_Bind.bind(dictMonad.Bind1())(v)(Data_Either.either(function ($97) {
          return Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Left.create($97));
        })(function (a) {
          var v1 = k(a);
          return v1;
        }));
      };
    });
  };

  var applyExceptT = function applyExceptT(dictMonad) {
    return new Control_Apply.Apply(function () {
      return functorExceptT(dictMonad.Bind1().Apply0().Functor0());
    }, Control_Monad.ap(monadExceptT(dictMonad)));
  };

  var applicativeExceptT = function applicativeExceptT(dictMonad) {
    return new Control_Applicative.Applicative(function () {
      return applyExceptT(dictMonad);
    }, function ($98) {
      return ExceptT(Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Right.create($98)));
    });
  };

  var monadThrowExceptT = function monadThrowExceptT(dictMonad) {
    return new Control_Monad_Error_Class.MonadThrow(function () {
      return monadExceptT(dictMonad);
    }, function ($102) {
      return ExceptT(Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Left.create($102)));
    });
  };

  var altExceptT = function altExceptT(dictSemigroup) {
    return function (dictMonad) {
      return new Control_Alt.Alt(function () {
        return functorExceptT(dictMonad.Bind1().Apply0().Functor0());
      }, function (v) {
        return function (v1) {
          return Control_Bind.bind(dictMonad.Bind1())(v)(function (v2) {
            if (v2 instanceof Data_Either.Right) {
              return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(v2.value0));
            }

            ;

            if (v2 instanceof Data_Either.Left) {
              return Control_Bind.bind(dictMonad.Bind1())(v1)(function (v3) {
                if (v3 instanceof Data_Either.Right) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(v3.value0));
                }

                ;

                if (v3 instanceof Data_Either.Left) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(Data_Semigroup.append(dictSemigroup)(v2.value0)(v3.value0)));
                }

                ;
                throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 86, column 9 - line 88, column 49): " + [v3.constructor.name]);
              });
            }

            ;
            throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 82, column 5 - line 88, column 49): " + [v2.constructor.name]);
          });
        };
      });
    };
  };

  exports["ExceptT"] = ExceptT;
  exports["runExceptT"] = runExceptT;
  exports["withExceptT"] = withExceptT;
  exports["mapExceptT"] = mapExceptT;
  exports["except"] = except;
  exports["functorExceptT"] = functorExceptT;
  exports["applyExceptT"] = applyExceptT;
  exports["applicativeExceptT"] = applicativeExceptT;
  exports["bindExceptT"] = bindExceptT;
  exports["monadExceptT"] = monadExceptT;
  exports["altExceptT"] = altExceptT;
  exports["monadThrowExceptT"] = monadThrowExceptT;
})(PS);

(function (exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.HeytingAlgebra"] = $PS["Data.HeytingAlgebra"] || {};
  var exports = $PS["Data.HeytingAlgebra"];
  var $foreign = $PS["Data.HeytingAlgebra"];

  var HeytingAlgebra = function HeytingAlgebra(conj, disj, ff, implies, not, tt) {
    this.conj = conj;
    this.disj = disj;
    this.ff = ff;
    this.implies = implies;
    this.not = not;
    this.tt = tt;
  };

  var tt = function tt(dict) {
    return dict.tt;
  };

  var not = function not(dict) {
    return dict.not;
  };

  var implies = function implies(dict) {
    return dict.implies;
  };

  var ff = function ff(dict) {
    return dict.ff;
  };

  var disj = function disj(dict) {
    return dict.disj;
  };

  var heytingAlgebraBoolean = new HeytingAlgebra($foreign.boolConj, $foreign.boolDisj, false, function (a) {
    return function (b) {
      return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
    };
  }, $foreign.boolNot, true);

  var conj = function conj(dict) {
    return dict.conj;
  };

  exports["HeytingAlgebra"] = HeytingAlgebra;
  exports["tt"] = tt;
  exports["ff"] = ff;
  exports["implies"] = implies;
  exports["conj"] = conj;
  exports["disj"] = disj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Boolean"] = $PS["Data.Boolean"] || {};
  var exports = $PS["Data.Boolean"];
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS);

(function (exports) {
  "use strict";

  exports.intDegree = function (x) {
    return Math.min(Math.abs(x), 2147483647);
  }; // See the Euclidean definition in
  // https://en.m.wikipedia.org/wiki/Modulo_operation.


  exports.intDiv = function (x) {
    return function (y) {
      if (y === 0) return 0;
      return y > 0 ? Math.floor(x / y) : -Math.floor(x / -y);
    };
  };

  exports.intMod = function (x) {
    return function (y) {
      if (y === 0) return 0;
      var yy = Math.abs(y);
      return (x % yy + yy) % yy;
    };
  };
})(PS["Data.EuclideanRing"] = PS["Data.EuclideanRing"] || {});

(function (exports) {
  "use strict";

  exports.intSub = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x - y | 0;
    };
  };
})(PS["Data.Ring"] = PS["Data.Ring"] || {});

(function (exports) {
  "use strict";

  exports.intAdd = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x + y | 0;
    };
  };

  exports.intMul = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x * y | 0;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Semiring"] = $PS["Data.Semiring"] || {};
  var exports = $PS["Data.Semiring"];
  var $foreign = $PS["Data.Semiring"];

  var Semiring = function Semiring(add, mul, one, zero) {
    this.add = add;
    this.mul = mul;
    this.one = one;
    this.zero = zero;
  };

  var zero = function zero(dict) {
    return dict.zero;
  };

  var semiringInt = new Semiring($foreign.intAdd, $foreign.intMul, 1, 0);

  var one = function one(dict) {
    return dict.one;
  };

  var mul = function mul(dict) {
    return dict.mul;
  };

  var add = function add(dict) {
    return dict.add;
  };

  exports["Semiring"] = Semiring;
  exports["add"] = add;
  exports["zero"] = zero;
  exports["mul"] = mul;
  exports["one"] = one;
  exports["semiringInt"] = semiringInt;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Ring"] = $PS["Data.Ring"] || {};
  var exports = $PS["Data.Ring"];
  var $foreign = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];

  var Ring = function Ring(Semiring0, sub) {
    this.Semiring0 = Semiring0;
    this.sub = sub;
  };

  var sub = function sub(dict) {
    return dict.sub;
  };

  var ringInt = new Ring(function () {
    return Data_Semiring.semiringInt;
  }, $foreign.intSub);
  exports["Ring"] = Ring;
  exports["sub"] = sub;
  exports["ringInt"] = ringInt;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.CommutativeRing"] = $PS["Data.CommutativeRing"] || {};
  var exports = $PS["Data.CommutativeRing"];
  var Data_Ring = $PS["Data.Ring"];

  var CommutativeRing = function CommutativeRing(Ring0) {
    this.Ring0 = Ring0;
  };

  var commutativeRingInt = new CommutativeRing(function () {
    return Data_Ring.ringInt;
  });
  exports["CommutativeRing"] = CommutativeRing;
  exports["commutativeRingInt"] = commutativeRingInt;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.EuclideanRing"] = $PS["Data.EuclideanRing"] || {};
  var exports = $PS["Data.EuclideanRing"];
  var $foreign = $PS["Data.EuclideanRing"];
  var Data_CommutativeRing = $PS["Data.CommutativeRing"];

  var EuclideanRing = function EuclideanRing(CommutativeRing0, degree, div, mod) {
    this.CommutativeRing0 = CommutativeRing0;
    this.degree = degree;
    this.div = div;
    this.mod = mod;
  };

  var mod = function mod(dict) {
    return dict.mod;
  };

  var euclideanRingInt = new EuclideanRing(function () {
    return Data_CommutativeRing.commutativeRingInt;
  }, $foreign.intDegree, $foreign.intDiv, $foreign.intMod);

  var div = function div(dict) {
    return dict.div;
  };

  var degree = function degree(dict) {
    return dict.degree;
  };

  exports["EuclideanRing"] = EuclideanRing;
  exports["degree"] = degree;
  exports["div"] = div;
  exports["mod"] = mod;
  exports["euclideanRingInt"] = euclideanRingInt;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Monoid"] = $PS["Data.Monoid"] || {};
  var exports = $PS["Data.Monoid"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Semigroup = $PS["Data.Semigroup"];

  var Monoid = function Monoid(Semigroup0, mempty) {
    this.Semigroup0 = Semigroup0;
    this.mempty = mempty;
  };

  var monoidString = new Monoid(function () {
    return Data_Semigroup.semigroupString;
  }, "");

  var mempty = function mempty(dict) {
    return dict.mempty;
  };

  var power = function power(dictMonoid) {
    return function (x) {
      var go = function go(p) {
        if (p <= 0) {
          return mempty(dictMonoid);
        }

        ;

        if (p === 1) {
          return x;
        }

        ;

        if (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(p)(2) === 0) {
          var x$prime = go(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(p)(2));
          return Data_Semigroup.append(dictMonoid.Semigroup0())(x$prime)(x$prime);
        }

        ;

        if (Data_Boolean.otherwise) {
          var x$prime = go(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(p)(2));
          return Data_Semigroup.append(dictMonoid.Semigroup0())(x$prime)(Data_Semigroup.append(dictMonoid.Semigroup0())(x$prime)(x));
        }

        ;
        throw new Error("Failed pattern match at Data.Monoid (line 65, column 3 - line 65, column 17): " + [p.constructor.name]);
      };

      return go;
    };
  };

  exports["Monoid"] = Monoid;
  exports["mempty"] = mempty;
  exports["power"] = power;
  exports["monoidString"] = monoidString;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Monoid.Conj"] = $PS["Data.Monoid.Conj"] || {};
  var exports = $PS["Data.Monoid.Conj"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];

  var Conj = function Conj(x) {
    return x;
  };

  var semigroupConj = function semigroupConj(dictHeytingAlgebra) {
    return new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
        return Data_HeytingAlgebra.conj(dictHeytingAlgebra)(v)(v1);
      };
    });
  };

  var monoidConj = function monoidConj(dictHeytingAlgebra) {
    return new Data_Monoid.Monoid(function () {
      return semigroupConj(dictHeytingAlgebra);
    }, Data_HeytingAlgebra.tt(dictHeytingAlgebra));
  };

  exports["Conj"] = Conj;
  exports["semigroupConj"] = semigroupConj;
  exports["monoidConj"] = monoidConj;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Newtype"] = $PS["Data.Newtype"] || {};
  var exports = $PS["Data.Newtype"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid_Conj = $PS["Data.Monoid.Conj"];

  var Newtype = function Newtype(unwrap, wrap) {
    this.unwrap = unwrap;
    this.wrap = wrap;
  };

  var wrap = function wrap(dict) {
    return dict.wrap;
  };

  var unwrap = function unwrap(dict) {
    return dict.unwrap;
  };

  var newtypeConj = new Newtype(function (v) {
    return v;
  }, Data_Monoid_Conj.Conj);

  var alaF = function alaF(dictFunctor) {
    return function (dictFunctor1) {
      return function (dictNewtype) {
        return function (dictNewtype1) {
          return function (v) {
            return function (f) {
              return function ($80) {
                return Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1))(f(Data_Functor.map(dictFunctor)(wrap(dictNewtype))($80)));
              };
            };
          };
        };
      };
    };
  };

  exports["unwrap"] = unwrap;
  exports["wrap"] = wrap;
  exports["Newtype"] = Newtype;
  exports["alaF"] = alaF;
  exports["newtypeConj"] = newtypeConj;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Identity"] = $PS["Data.Identity"] || {};
  var exports = $PS["Data.Identity"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Newtype = $PS["Data.Newtype"];

  var Identity = function Identity(x) {
    return x;
  };

  var newtypeIdentity = new Data_Newtype.Newtype(function (n) {
    return n;
  }, Identity);
  var functorIdentity = new Data_Functor.Functor(function (f) {
    return function (m) {
      return f(m);
    };
  });
  var applyIdentity = new Control_Apply.Apply(function () {
    return functorIdentity;
  }, function (v) {
    return function (v1) {
      return v(v1);
    };
  });
  var bindIdentity = new Control_Bind.Bind(function () {
    return applyIdentity;
  }, function (v) {
    return function (f) {
      return f(v);
    };
  });
  var applicativeIdentity = new Control_Applicative.Applicative(function () {
    return applyIdentity;
  }, Identity);
  var monadIdentity = new Control_Monad.Monad(function () {
    return applicativeIdentity;
  }, function () {
    return bindIdentity;
  });
  exports["Identity"] = Identity;
  exports["newtypeIdentity"] = newtypeIdentity;
  exports["functorIdentity"] = functorIdentity;
  exports["applyIdentity"] = applyIdentity;
  exports["applicativeIdentity"] = applicativeIdentity;
  exports["bindIdentity"] = bindIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Monad.Except"] = $PS["Control.Monad.Except"] || {};
  var exports = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Newtype = $PS["Data.Newtype"];
  var withExcept = Control_Monad_Except_Trans.withExceptT(Data_Identity.functorIdentity);

  var runExcept = function runExcept($0) {
    return Data_Newtype.unwrap(Data_Identity.newtypeIdentity)(Control_Monad_Except_Trans.runExceptT($0));
  };

  exports["runExcept"] = runExcept;
  exports["withExcept"] = withExcept;
})(PS);

(function (exports) {
  "use strict";

  var refEq = function refEq(r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqIntImpl = refEq;
  exports.eqCharImpl = refEq;
})(PS["Data.Eq"] = PS["Data.Eq"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Eq"] = $PS["Data.Eq"] || {};
  var exports = $PS["Data.Eq"];
  var $foreign = $PS["Data.Eq"];

  var Eq1 = function Eq1(eq1) {
    this.eq1 = eq1;
  };

  var Eq = function Eq(eq) {
    this.eq = eq;
  };

  var eqInt = new Eq($foreign.eqIntImpl);
  var eqChar = new Eq($foreign.eqCharImpl);

  var eq1 = function eq1(dict) {
    return dict.eq1;
  };

  var eq = function eq(dict) {
    return dict.eq;
  };

  exports["Eq"] = Eq;
  exports["eq"] = eq;
  exports["Eq1"] = Eq1;
  exports["eq1"] = eq1;
  exports["eqInt"] = eqInt;
  exports["eqChar"] = eqChar;
})(PS);

(function (exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;

        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }

        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;

        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }

        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Maybe"] = $PS["Data.Maybe"] || {};
  var exports = $PS["Data.Maybe"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];

  var Nothing = function () {
    function Nothing() {}

    ;
    Nothing.value = new Nothing();
    return Nothing;
  }();

  var Just = function () {
    function Just(value0) {
      this.value0 = value0;
    }

    ;

    Just.create = function (value0) {
      return new Just(value0);
    };

    return Just;
  }();

  var maybe = function maybe(v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Nothing) {
          return v;
        }

        ;

        if (v2 instanceof Just) {
          return v1(v2.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Maybe (line 217, column 1 - line 217, column 51): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
      };
    };
  };

  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = new Data_Functor.Functor(function (v) {
    return function (v1) {
      if (v1 instanceof Just) {
        return new Just(v(v1.value0));
      }

      ;
      return Nothing.value;
    };
  });

  var fromMaybe = function fromMaybe(a) {
    return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  };

  var fromJust = function fromJust(dictPartial) {
    return function (v) {
      if (v instanceof Just) {
        return v.value0;
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 268, column 1 - line 268, column 46): " + [v.constructor.name]);
    };
  };

  var applyMaybe = new Control_Apply.Apply(function () {
    return functorMaybe;
  }, function (v) {
    return function (v1) {
      if (v instanceof Just) {
        return Data_Functor.map(functorMaybe)(v.value0)(v1);
      }

      ;

      if (v instanceof Nothing) {
        return Nothing.value;
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 67, column 1 - line 67, column 35): " + [v.constructor.name, v1.constructor.name]);
    };
  });
  var bindMaybe = new Control_Bind.Bind(function () {
    return applyMaybe;
  }, function (v) {
    return function (v1) {
      if (v instanceof Just) {
        return v1(v.value0);
      }

      ;

      if (v instanceof Nothing) {
        return Nothing.value;
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 125, column 1 - line 125, column 33): " + [v.constructor.name, v1.constructor.name]);
    };
  });
  var applicativeMaybe = new Control_Applicative.Applicative(function () {
    return applyMaybe;
  }, Just.create);
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["applyMaybe"] = applyMaybe;
  exports["applicativeMaybe"] = applicativeMaybe;
  exports["bindMaybe"] = bindMaybe;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Foldable"] = $PS["Data.Foldable"] || {};
  var exports = $PS["Data.Foldable"];
  var $foreign = $PS["Data.Foldable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Monoid_Conj = $PS["Data.Monoid.Conj"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];

  var Foldable = function Foldable(foldMap, foldl, foldr) {
    this.foldMap = foldMap;
    this.foldl = foldl;
    this.foldr = foldr;
  };

  var foldr = function foldr(dict) {
    return dict.foldr;
  };

  var traverse_ = function traverse_(dictApplicative) {
    return function (dictFoldable) {
      return function (f) {
        return foldr(dictFoldable)(function ($195) {
          return Control_Apply.applySecond(dictApplicative.Apply0())(f($195));
        })(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
      };
    };
  };

  var for_ = function for_(dictApplicative) {
    return function (dictFoldable) {
      return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
    };
  };

  var foldl = function foldl(dict) {
    return dict.foldl;
  };

  var intercalate = function intercalate(dictFoldable) {
    return function (dictMonoid) {
      return function (sep) {
        return function (xs) {
          var go = function go(v) {
            return function (x) {
              if (v.init) {
                return {
                  init: false,
                  acc: x
                };
              }

              ;
              return {
                init: false,
                acc: Data_Semigroup.append(dictMonoid.Semigroup0())(v.acc)(Data_Semigroup.append(dictMonoid.Semigroup0())(sep)(x))
              };
            };
          };

          return foldl(dictFoldable)(go)({
            init: true,
            acc: Data_Monoid.mempty(dictMonoid)
          })(xs).acc;
        };
      };
    };
  };

  var foldableMaybe = new Foldable(function (dictMonoid) {
    return function (f) {
      return function (v) {
        if (v instanceof Data_Maybe.Nothing) {
          return Data_Monoid.mempty(dictMonoid);
        }

        ;

        if (v instanceof Data_Maybe.Just) {
          return f(v.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [f.constructor.name, v.constructor.name]);
      };
    };
  }, function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Data_Maybe.Nothing) {
          return z;
        }

        ;

        if (v1 instanceof Data_Maybe.Just) {
          return v(z)(v1.value0);
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
      };
    };
  }, function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Data_Maybe.Nothing) {
          return z;
        }

        ;

        if (v1 instanceof Data_Maybe.Just) {
          return v(v1.value0)(z);
        }

        ;
        throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 129, column 41): " + [v.constructor.name, z.constructor.name, v1.constructor.name]);
      };
    };
  });

  var foldMapDefaultR = function foldMapDefaultR(dictFoldable) {
    return function (dictMonoid) {
      return function (f) {
        return foldr(dictFoldable)(function (x) {
          return function (acc) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
          };
        })(Data_Monoid.mempty(dictMonoid));
      };
    };
  };

  var foldableArray = new Foldable(function (dictMonoid) {
    return foldMapDefaultR(foldableArray)(dictMonoid);
  }, $foreign.foldlArray, $foreign.foldrArray);

  var foldMap = function foldMap(dict) {
    return dict.foldMap;
  };

  var all = function all(dictFoldable) {
    return function (dictHeytingAlgebra) {
      return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeConj)(Data_Newtype.newtypeConj)(Data_Monoid_Conj.Conj)(foldMap(dictFoldable)(Data_Monoid_Conj.monoidConj(dictHeytingAlgebra)));
    };
  };

  exports["Foldable"] = Foldable;
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["foldMapDefaultR"] = foldMapDefaultR;
  exports["traverse_"] = traverse_;
  exports["for_"] = for_;
  exports["intercalate"] = intercalate;
  exports["all"] = all;
  exports["foldableArray"] = foldableArray;
  exports["foldableMaybe"] = foldableMaybe;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Plus"] = $PS["Control.Plus"] || {};
  var exports = $PS["Control.Plus"];

  var Plus = function Plus(Alt0, empty) {
    this.Alt0 = Alt0;
    this.empty = empty;
  };

  var empty = function empty(dict) {
    return dict.empty;
  };

  exports["Plus"] = Plus;
  exports["empty"] = empty;
})(PS);

(function (exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showNumberImpl = function (n) {
    var str = n.toString();
    return isNaN(str + ".0") ? str : str + ".0";
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(/[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
    function (c, i) {
      switch (c) {
        case "\"":
        case "\\":
          return "\\" + c;

        case "\x07":
          return "\\a";

        case "\b":
          return "\\b";

        case "\f":
          return "\\f";

        case "\n":
          return "\\n";

        case "\r":
          return "\\r";

        case "\t":
          return "\\t";

        case "\v":
          return "\\v";
      }

      var k = i + 1;
      var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
      return "\\" + c.charCodeAt(0).toString(10) + empty;
    }) + "\"";
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Show"] = $PS["Data.Show"] || {};
  var exports = $PS["Data.Show"];
  var $foreign = $PS["Data.Show"];

  var Show = function Show(show) {
    this.show = show;
  };

  var showString = new Show($foreign.showStringImpl);
  var showNumber = new Show($foreign.showNumberImpl);
  var showInt = new Show($foreign.showIntImpl);

  var show = function show(dict) {
    return dict.show;
  };

  exports["Show"] = Show;
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showNumber"] = showNumber;
  exports["showString"] = showString;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.NonEmpty"] = $PS["Data.NonEmpty"] || {};
  var exports = $PS["Data.NonEmpty"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Show = $PS["Data.Show"];

  var NonEmpty = function () {
    function NonEmpty(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    NonEmpty.create = function (value0) {
      return function (value1) {
        return new NonEmpty(value0, value1);
      };
    };

    return NonEmpty;
  }();

  var singleton = function singleton(dictPlus) {
    return function (a) {
      return new NonEmpty(a, Control_Plus.empty(dictPlus));
    };
  };

  var showNonEmpty = function showNonEmpty(dictShow) {
    return function (dictShow1) {
      return new Data_Show.Show(function (v) {
        return "(NonEmpty " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
      });
    };
  };

  var functorNonEmpty = function functorNonEmpty(dictFunctor) {
    return new Data_Functor.Functor(function (f) {
      return function (m) {
        return new NonEmpty(f(m.value0), Data_Functor.map(dictFunctor)(f)(m.value1));
      };
    });
  };

  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
  exports["showNonEmpty"] = showNonEmpty;
  exports["functorNonEmpty"] = functorNonEmpty;
})(PS);

(function (exports) {
  "use strict";

  var unsafeCompareImpl = function unsafeCompareImpl(lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };

  exports.ordIntImpl = unsafeCompareImpl;
  exports.ordCharImpl = unsafeCompareImpl;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Ordering"] = $PS["Data.Ordering"] || {};
  var exports = $PS["Data.Ordering"];

  var LT = function () {
    function LT() {}

    ;
    LT.value = new LT();
    return LT;
  }();

  var GT = function () {
    function GT() {}

    ;
    GT.value = new GT();
    return GT;
  }();

  var EQ = function () {
    function EQ() {}

    ;
    EQ.value = new EQ();
    return EQ;
  }();

  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Ord"] = $PS["Data.Ord"] || {};
  var exports = $PS["Data.Ord"];
  var $foreign = $PS["Data.Ord"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ordering = $PS["Data.Ordering"];

  var Ord1 = function Ord1(Eq10, compare1) {
    this.Eq10 = Eq10;
    this.compare1 = compare1;
  };

  var Ord = function Ord(Eq0, compare) {
    this.Eq0 = Eq0;
    this.compare = compare;
  };

  var ordInt = new Ord(function () {
    return Data_Eq.eqInt;
  }, $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var ordChar = new Ord(function () {
    return Data_Eq.eqChar;
  }, $foreign.ordCharImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));

  var compare1 = function compare1(dict) {
    return dict.compare1;
  };

  var compare = function compare(dict) {
    return dict.compare;
  };

  var greaterThan = function greaterThan(dictOrd) {
    return function (a1) {
      return function (a2) {
        var v = compare(dictOrd)(a1)(a2);

        if (v instanceof Data_Ordering.GT) {
          return true;
        }

        ;
        return false;
      };
    };
  };

  var lessThan = function lessThan(dictOrd) {
    return function (a1) {
      return function (a2) {
        var v = compare(dictOrd)(a1)(a2);

        if (v instanceof Data_Ordering.LT) {
          return true;
        }

        ;
        return false;
      };
    };
  };

  var max = function max(dictOrd) {
    return function (x) {
      return function (y) {
        var v = compare(dictOrd)(x)(y);

        if (v instanceof Data_Ordering.LT) {
          return y;
        }

        ;

        if (v instanceof Data_Ordering.EQ) {
          return x;
        }

        ;

        if (v instanceof Data_Ordering.GT) {
          return x;
        }

        ;
        throw new Error("Failed pattern match at Data.Ord (line 167, column 3 - line 170, column 12): " + [v.constructor.name]);
      };
    };
  };

  var min = function min(dictOrd) {
    return function (x) {
      return function (y) {
        var v = compare(dictOrd)(x)(y);

        if (v instanceof Data_Ordering.LT) {
          return x;
        }

        ;

        if (v instanceof Data_Ordering.EQ) {
          return x;
        }

        ;

        if (v instanceof Data_Ordering.GT) {
          return y;
        }

        ;
        throw new Error("Failed pattern match at Data.Ord (line 158, column 3 - line 161, column 12): " + [v.constructor.name]);
      };
    };
  };

  var clamp = function clamp(dictOrd) {
    return function (low) {
      return function (hi) {
        return function (x) {
          return min(dictOrd)(hi)(max(dictOrd)(low)(x));
        };
      };
    };
  };

  exports["Ord"] = Ord;
  exports["compare"] = compare;
  exports["Ord1"] = Ord1;
  exports["compare1"] = compare1;
  exports["lessThan"] = lessThan;
  exports["greaterThan"] = greaterThan;
  exports["min"] = min;
  exports["max"] = max;
  exports["clamp"] = clamp;
  exports["ordInt"] = ordInt;
  exports["ordChar"] = ordChar;
})(PS);

(function (exports) {
  "use strict"; // jshint maxparams: 3

  exports.traverseArrayImpl = function () {
    function array1(a) {
      return [a];
    }

    function array2(a) {
      return function (b) {
        return [a, b];
      };
    }

    function array3(a) {
      return function (b) {
        return function (c) {
          return [a, b, c];
        };
      };
    }

    function concat2(xs) {
      return function (ys) {
        return xs.concat(ys);
      };
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            return function (array) {
              function go(bot, top) {
                switch (top - bot) {
                  case 0:
                    return pure([]);

                  case 1:
                    return map(array1)(f(array[bot]));

                  case 2:
                    return apply(map(array2)(f(array[bot])))(f(array[bot + 1]));

                  case 3:
                    return apply(apply(map(array3)(f(array[bot])))(f(array[bot + 1])))(f(array[bot + 2]));

                  default:
                    // This slightly tricky pivot selection aims to produce two
                    // even-length partitions where possible.
                    var pivot = bot + Math.floor((top - bot) / 4) * 2;
                    return apply(map(concat2)(go(bot, pivot)))(go(pivot, top));
                }
              }

              return go(0, array.length);
            };
          };
        };
      };
    };
  }();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Traversable"] = $PS["Data.Traversable"] || {};
  var exports = $PS["Data.Traversable"];
  var $foreign = $PS["Data.Traversable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];

  var Traversable = function Traversable(Foldable1, Functor0, sequence, traverse) {
    this.Foldable1 = Foldable1;
    this.Functor0 = Functor0;
    this.sequence = sequence;
    this.traverse = traverse;
  };

  var traverse = function traverse(dict) {
    return dict.traverse;
  };

  var sequenceDefault = function sequenceDefault(dictTraversable) {
    return function (dictApplicative) {
      return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
    };
  };

  var traversableArray = new Traversable(function () {
    return Data_Foldable.foldableArray;
  }, function () {
    return Data_Functor.functorArray;
  }, function (dictApplicative) {
    return sequenceDefault(traversableArray)(dictApplicative);
  }, function (dictApplicative) {
    return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map(dictApplicative.Apply0().Functor0()))(Control_Applicative.pure(dictApplicative));
  });

  var sequence = function sequence(dict) {
    return dict.sequence;
  };

  exports["Traversable"] = Traversable;
  exports["traverse"] = traverse;
  exports["sequence"] = sequence;
  exports["sequenceDefault"] = sequenceDefault;
  exports["traversableArray"] = traversableArray;
})(PS);

(function (exports) {
  "use strict";

  exports.unfoldrArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;

              while (true) {
                // eslint-disable-line no-constant-condition
                var maybe = f(value);
                if (isNothing(maybe)) return result;
                var tuple = fromJust(maybe);
                result.push(fst(tuple));
                value = snd(tuple);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Tuple"] = $PS["Data.Tuple"] || {};
  var exports = $PS["Data.Tuple"];

  var Tuple = function () {
    function Tuple(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Tuple.create = function (value0) {
      return function (value1) {
        return new Tuple(value0, value1);
      };
    };

    return Tuple;
  }();

  var snd = function snd(v) {
    return v.value1;
  };

  var fst = function fst(v) {
    return v.value0;
  };

  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
})(PS);

(function (exports) {
  "use strict";

  exports.unfoldr1ArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;

              while (true) {
                // eslint-disable-line no-constant-condition
                var tuple = f(value);
                result.push(fst(tuple));
                var maybe = snd(tuple);
                if (isNothing(maybe)) return result;
                value = fromJust(maybe);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Unfoldable1"] = $PS["Data.Unfoldable1"] || {};
  var exports = $PS["Data.Unfoldable1"];
  var $foreign = $PS["Data.Unfoldable1"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];

  var Unfoldable1 = function Unfoldable1(unfoldr1) {
    this.unfoldr1 = unfoldr1;
  };

  var unfoldr1 = function unfoldr1(dict) {
    return dict.unfoldr1;
  };

  var unfoldable1Array = new Unfoldable1($foreign.unfoldr1ArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  exports["Unfoldable1"] = Unfoldable1;
  exports["unfoldr1"] = unfoldr1;
  exports["unfoldable1Array"] = unfoldable1Array;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Unfoldable"] = $PS["Data.Unfoldable"] || {};
  var exports = $PS["Data.Unfoldable"];
  var $foreign = $PS["Data.Unfoldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];

  var Unfoldable = function Unfoldable(Unfoldable10, unfoldr) {
    this.Unfoldable10 = Unfoldable10;
    this.unfoldr = unfoldr;
  };

  var unfoldr = function unfoldr(dict) {
    return dict.unfoldr;
  };

  var unfoldableArray = new Unfoldable(function () {
    return Data_Unfoldable1.unfoldable1Array;
  }, $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));

  var replicate = function replicate(dictUnfoldable) {
    return function (n) {
      return function (v) {
        var step = function step(i) {
          var $7 = i <= 0;

          if ($7) {
            return Data_Maybe.Nothing.value;
          }

          ;
          return new Data_Maybe.Just(new Data_Tuple.Tuple(v, i - 1 | 0));
        };

        return unfoldr(dictUnfoldable)(step)(n);
      };
    };
  };

  exports["Unfoldable"] = Unfoldable;
  exports["unfoldr"] = unfoldr;
  exports["replicate"] = replicate;
  exports["unfoldableArray"] = unfoldableArray;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.List.Types"] = $PS["Data.List.Types"] || {};
  var exports = $PS["Data.List.Types"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Show = $PS["Data.Show"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];

  var Nil = function () {
    function Nil() {}

    ;
    Nil.value = new Nil();
    return Nil;
  }();

  var Cons = function () {
    function Cons(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Cons.create = function (value0) {
      return function (value1) {
        return new Cons(value0, value1);
      };
    };

    return Cons;
  }();

  var NonEmptyList = function NonEmptyList(x) {
    return x;
  };

  var toList = function toList(v) {
    return new Cons(v.value0, v.value1);
  };

  var listMap = function listMap(f) {
    var chunkedRevMap = function chunkedRevMap($copy_chunksAcc) {
      return function ($copy_v) {
        var $tco_var_chunksAcc = $copy_chunksAcc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(chunksAcc, v) {
          if (v instanceof Cons && v.value1 instanceof Cons && v.value1.value1 instanceof Cons) {
            $tco_var_chunksAcc = new Cons(v, chunksAcc);
            $copy_v = v.value1.value1.value1;
            return;
          }

          ;

          var unrolledMap = function unrolledMap(v1) {
            if (v1 instanceof Cons && v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil) {
              return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
            }

            ;

            if (v1 instanceof Cons && v1.value1 instanceof Nil) {
              return new Cons(f(v1.value0), Nil.value);
            }

            ;
            return Nil.value;
          };

          var reverseUnrolledMap = function reverseUnrolledMap($copy_v1) {
            return function ($copy_acc) {
              var $tco_var_v1 = $copy_v1;
              var $tco_done = false;
              var $tco_result;

              function $tco_loop(v1, acc) {
                if (v1 instanceof Cons && v1.value0 instanceof Cons && v1.value0.value1 instanceof Cons && v1.value0.value1.value1 instanceof Cons) {
                  $tco_var_v1 = v1.value1;
                  $copy_acc = new Cons(f(v1.value0.value0), new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc)));
                  return;
                }

                ;
                $tco_done = true;
                return acc;
              }

              ;

              while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v1, $copy_acc);
              }

              ;
              return $tco_result;
            };
          };

          $tco_done = true;
          return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return chunkedRevMap(Nil.value);
  };

  var functorList = new Data_Functor.Functor(listMap);
  var functorNonEmptyList = Data_NonEmpty.functorNonEmpty(functorList);
  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
    return function (f) {
      return Data_Foldable.foldl(foldableList)(function (acc) {
        return function ($202) {
          return Data_Semigroup.append(dictMonoid.Semigroup0())(acc)(f($202));
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  }, function (f) {
    var go = function go($copy_b) {
      return function ($copy_v) {
        var $tco_var_b = $copy_b;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(b, v) {
          if (v instanceof Nil) {
            $tco_done = true;
            return b;
          }

          ;

          if (v instanceof Cons) {
            $tco_var_b = f(b)(v.value0);
            $copy_v = v.value1;
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List.Types (line 109, column 12 - line 111, column 30): " + [v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_b, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go;
  }, function (f) {
    return function (b) {
      var rev = Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value);
      return function ($203) {
        return Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b)(rev($203));
      };
    };
  });
  var semigroupList = new Data_Semigroup.Semigroup(function (xs) {
    return function (ys) {
      return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
    };
  });
  var monoidList = new Data_Monoid.Monoid(function () {
    return semigroupList;
  }, Nil.value);
  var semigroupNonEmptyList = new Data_Semigroup.Semigroup(function (v) {
    return function (as$prime) {
      return new Data_NonEmpty.NonEmpty(v.value0, Data_Semigroup.append(semigroupList)(v.value1)(toList(as$prime)));
    };
  });

  var showList = function showList(dictShow) {
    return new Data_Show.Show(function (v) {
      if (v instanceof Nil) {
        return "Nil";
      }

      ;
      return "(" + (Data_Foldable.intercalate(foldableList)(Data_Monoid.monoidString)(" : ")(Data_Functor.map(functorList)(Data_Show.show(dictShow))(v)) + " : Nil)");
    });
  };

  var showNonEmptyList = function showNonEmptyList(dictShow) {
    return new Data_Show.Show(function (v) {
      return "(NonEmptyList " + (Data_Show.show(Data_NonEmpty.showNonEmpty(dictShow)(showList(dictShow)))(v) + ")");
    });
  };

  var traversableList = new Data_Traversable.Traversable(function () {
    return foldableList;
  }, function () {
    return functorList;
  }, function (dictApplicative) {
    return Data_Traversable.traverse(traversableList)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  }, function (dictApplicative) {
    return function (f) {
      return function ($210) {
        return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value))(Data_Foldable.foldl(foldableList)(function (acc) {
          return function ($211) {
            return Control_Apply.lift2(dictApplicative.Apply0())(Data_Function.flip(Cons.create))(acc)(f($211));
          };
        })(Control_Applicative.pure(dictApplicative)(Nil.value))($210));
      };
    };
  });
  var unfoldable1List = new Data_Unfoldable1.Unfoldable1(function (f) {
    return function (b) {
      var go = function go($copy_source) {
        return function ($copy_memo) {
          var $tco_var_source = $copy_source;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(source, memo) {
            var v = f(source);

            if (v.value1 instanceof Data_Maybe.Just) {
              $tco_var_source = v.value1.value0;
              $copy_memo = new Cons(v.value0, memo);
              return;
            }

            ;

            if (v.value1 instanceof Data_Maybe.Nothing) {
              $tco_done = true;
              return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(new Cons(v.value0, memo));
            }

            ;
            throw new Error("Failed pattern match at Data.List.Types (line 133, column 22 - line 135, column 61): " + [v.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_source, $copy_memo);
          }

          ;
          return $tco_result;
        };
      };

      return go(b)(Nil.value);
    };
  });
  var unfoldableList = new Data_Unfoldable.Unfoldable(function () {
    return unfoldable1List;
  }, function (f) {
    return function (b) {
      var go = function go($copy_source) {
        return function ($copy_memo) {
          var $tco_var_source = $copy_source;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(source, memo) {
            var v = f(source);

            if (v instanceof Data_Maybe.Nothing) {
              $tco_done = true;
              return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(memo);
            }

            ;

            if (v instanceof Data_Maybe.Just) {
              $tco_var_source = v.value0.value1;
              $copy_memo = new Cons(v.value0.value0, memo);
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.List.Types (line 140, column 22 - line 142, column 52): " + [v.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_source, $copy_memo);
          }

          ;
          return $tco_result;
        };
      };

      return go(b)(Nil.value);
    };
  });
  var eq1List = new Data_Eq.Eq1(function (dictEq) {
    return function (xs) {
      return function (ys) {
        var go = function go($copy_v) {
          return function ($copy_v1) {
            return function ($copy_v2) {
              var $tco_var_v = $copy_v;
              var $tco_var_v1 = $copy_v1;
              var $tco_done = false;
              var $tco_result;

              function $tco_loop(v, v1, v2) {
                if (!v2) {
                  $tco_done = true;
                  return false;
                }

                ;

                if (v instanceof Nil && v1 instanceof Nil) {
                  $tco_done = true;
                  return v2;
                }

                ;

                if (v instanceof Cons && v1 instanceof Cons) {
                  $tco_var_v = v.value1;
                  $tco_var_v1 = v1.value1;
                  $copy_v2 = v2 && Data_Eq.eq(dictEq)(v1.value0)(v.value0);
                  return;
                }

                ;
                $tco_done = true;
                return false;
              }

              ;

              while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_v2);
              }

              ;
              return $tco_result;
            };
          };
        };

        return go(xs)(ys)(true);
      };
    };
  });

  var eqList = function eqList(dictEq) {
    return new Data_Eq.Eq(Data_Eq.eq1(eq1List)(dictEq));
  };

  var ord1List = new Data_Ord.Ord1(function () {
    return eq1List;
  }, function (dictOrd) {
    return function (xs) {
      return function (ys) {
        var go = function go($copy_v) {
          return function ($copy_v1) {
            var $tco_var_v = $copy_v;
            var $tco_done = false;
            var $tco_result;

            function $tco_loop(v, v1) {
              if (v instanceof Nil && v1 instanceof Nil) {
                $tco_done = true;
                return Data_Ordering.EQ.value;
              }

              ;

              if (v instanceof Nil) {
                $tco_done = true;
                return Data_Ordering.LT.value;
              }

              ;

              if (v1 instanceof Nil) {
                $tco_done = true;
                return Data_Ordering.GT.value;
              }

              ;

              if (v instanceof Cons && v1 instanceof Cons) {
                var v2 = Data_Ord.compare(dictOrd)(v.value0)(v1.value0);

                if (v2 instanceof Data_Ordering.EQ) {
                  $tco_var_v = v.value1;
                  $copy_v1 = v1.value1;
                  return;
                }

                ;
                $tco_done = true;
                return v2;
              }

              ;
              throw new Error("Failed pattern match at Data.List.Types (line 61, column 5 - line 61, column 20): " + [v.constructor.name, v1.constructor.name]);
            }

            ;

            while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_v, $copy_v1);
            }

            ;
            return $tco_result;
          };
        };

        return go(xs)(ys);
      };
    };
  });

  var ordList = function ordList(dictOrd) {
    return new Data_Ord.Ord(function () {
      return eqList(dictOrd.Eq0());
    }, Data_Ord.compare1(ord1List)(dictOrd));
  };

  var applyList = new Control_Apply.Apply(function () {
    return functorList;
  }, function (v) {
    return function (v1) {
      if (v instanceof Nil) {
        return Nil.value;
      }

      ;

      if (v instanceof Cons) {
        return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
      }

      ;
      throw new Error("Failed pattern match at Data.List.Types (line 155, column 1 - line 155, column 33): " + [v.constructor.name, v1.constructor.name]);
    };
  });
  var applicativeList = new Control_Applicative.Applicative(function () {
    return applyList;
  }, function (a) {
    return new Cons(a, Nil.value);
  });
  var altList = new Control_Alt.Alt(function () {
    return functorList;
  }, Data_Semigroup.append(semigroupList));
  var plusList = new Control_Plus.Plus(function () {
    return altList;
  }, Nil.value);
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["toList"] = toList;
  exports["showList"] = showList;
  exports["eqList"] = eqList;
  exports["eq1List"] = eq1List;
  exports["ordList"] = ordList;
  exports["ord1List"] = ord1List;
  exports["semigroupList"] = semigroupList;
  exports["monoidList"] = monoidList;
  exports["functorList"] = functorList;
  exports["foldableList"] = foldableList;
  exports["unfoldable1List"] = unfoldable1List;
  exports["unfoldableList"] = unfoldableList;
  exports["traversableList"] = traversableList;
  exports["applyList"] = applyList;
  exports["applicativeList"] = applicativeList;
  exports["altList"] = altList;
  exports["plusList"] = plusList;
  exports["showNonEmptyList"] = showNonEmptyList;
  exports["functorNonEmptyList"] = functorNonEmptyList;
  exports["semigroupNonEmptyList"] = semigroupNonEmptyList;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.List"] = $PS["Data.List"] || {};
  var exports = $PS["Data.List"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];

  var uncons = function uncons(v) {
    if (v instanceof Data_List_Types.Nil) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v instanceof Data_List_Types.Cons) {
      return new Data_Maybe.Just({
        head: v.value0,
        tail: v.value1
      });
    }

    ;
    throw new Error("Failed pattern match at Data.List (line 259, column 1 - line 259, column 66): " + [v.constructor.name]);
  };

  var span = function span(v) {
    return function (v1) {
      if (v1 instanceof Data_List_Types.Cons && v(v1.value0)) {
        var v2 = span(v)(v1.value1);
        return {
          init: new Data_List_Types.Cons(v1.value0, v2.init),
          rest: v2.rest
        };
      }

      ;
      return {
        init: Data_List_Types.Nil.value,
        rest: v1
      };
    };
  };

  var reverse = function () {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return acc;
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
            $copy_v = v.value1;
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 368, column 3 - line 368, column 19): " + [acc.constructor.name, v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  }();

  var take = function () {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        return function ($copy_v1) {
          var $tco_var_acc = $copy_acc;
          var $tco_var_v = $copy_v;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(acc, v, v1) {
            if (v < 1) {
              $tco_done = true;
              return reverse(acc);
            }

            ;

            if (v1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return reverse(acc);
            }

            ;

            if (v1 instanceof Data_List_Types.Cons) {
              $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
              $tco_var_v = v - 1 | 0;
              $copy_v1 = v1.value1;
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.List (line 520, column 3 - line 520, column 35): " + [acc.constructor.name, v.constructor.name, v1.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_acc, $tco_var_v, $copy_v1);
          }

          ;
          return $tco_result;
        };
      };
    };

    return go(Data_List_Types.Nil.value);
  }();

  var takeWhile = function takeWhile(p) {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Cons && p(v.value0)) {
            $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
            $copy_v = v.value1;
            return;
          }

          ;
          $tco_done = true;
          return reverse(acc);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  };

  var unsnoc = function unsnoc(lst) {
    var go = function go($copy_v) {
      return function ($copy_acc) {
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(v, acc) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return Data_Maybe.Nothing.value;
          }

          ;

          if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return new Data_Maybe.Just({
              revInit: acc,
              last: v.value0
            });
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            $tco_var_v = v.value1;
            $copy_acc = new Data_List_Types.Cons(v.value0, acc);
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 270, column 3 - line 270, column 23): " + [v.constructor.name, acc.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_v, $copy_acc);
        }

        ;
        return $tco_result;
      };
    };

    return Data_Functor.map(Data_Maybe.functorMaybe)(function (h) {
      return {
        init: reverse(h.revInit),
        last: h.last
      };
    })(go(lst)(Data_List_Types.Nil.value));
  };

  var zipWith = function zipWith(f) {
    return function (xs) {
      return function (ys) {
        var go = function go($copy_v) {
          return function ($copy_v1) {
            return function ($copy_acc) {
              var $tco_var_v = $copy_v;
              var $tco_var_v1 = $copy_v1;
              var $tco_done = false;
              var $tco_result;

              function $tco_loop(v, v1, acc) {
                if (v instanceof Data_List_Types.Nil) {
                  $tco_done = true;
                  return acc;
                }

                ;

                if (v1 instanceof Data_List_Types.Nil) {
                  $tco_done = true;
                  return acc;
                }

                ;

                if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                  $tco_var_v = v.value1;
                  $tco_var_v1 = v1.value1;
                  $copy_acc = new Data_List_Types.Cons(f(v.value0)(v1.value0), acc);
                  return;
                }

                ;
                throw new Error("Failed pattern match at Data.List (line 718, column 3 - line 718, column 21): " + [v.constructor.name, v1.constructor.name, acc.constructor.name]);
              }

              ;

              while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_acc);
              }

              ;
              return $tco_result;
            };
          };
        };

        return reverse(go(xs)(ys)(Data_List_Types.Nil.value));
      };
    };
  };

  var zip = zipWith(Data_Tuple.Tuple.create);

  var $$null = function $$null(v) {
    if (v instanceof Data_List_Types.Nil) {
      return true;
    }

    ;
    return false;
  };

  var mapMaybe = function mapMaybe(f) {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return reverse(acc);
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            var v1 = f(v.value0);

            if (v1 instanceof Data_Maybe.Nothing) {
              $tco_var_acc = acc;
              $copy_v = v.value1;
              return;
            }

            ;

            if (v1 instanceof Data_Maybe.Just) {
              $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
              $copy_v = v.value1;
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.List (line 419, column 5 - line 421, column 32): " + [v1.constructor.name]);
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 417, column 3 - line 417, column 27): " + [acc.constructor.name, v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  };

  var length = Data_Foldable.foldl(Data_List_Types.foldableList)(function (acc) {
    return function (v) {
      return acc + 1 | 0;
    };
  })(0);

  var last = function last($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
        $tco_done = true;
        return new Data_Maybe.Just(v.value0);
      }

      ;

      if (v instanceof Data_List_Types.Cons) {
        $copy_v = v.value1;
        return;
      }

      ;
      $tco_done = true;
      return Data_Maybe.Nothing.value;
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  var insertAt = function insertAt(v) {
    return function (v1) {
      return function (v2) {
        if (v === 0) {
          return new Data_Maybe.Just(new Data_List_Types.Cons(v1, v2));
        }

        ;

        if (v2 instanceof Data_List_Types.Cons) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return new Data_List_Types.Cons(v2.value0, v3);
          })(insertAt(v - 1 | 0)(v1)(v2.value1));
        }

        ;
        return Data_Maybe.Nothing.value;
      };
    };
  };

  var init = function init(lst) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
      return v.init;
    })(unsnoc(lst));
  };

  var head = function head(v) {
    if (v instanceof Data_List_Types.Nil) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v instanceof Data_List_Types.Cons) {
      return new Data_Maybe.Just(v.value0);
    }

    ;
    throw new Error("Failed pattern match at Data.List (line 230, column 1 - line 230, column 22): " + [v.constructor.name]);
  };

  var fromFoldable = function fromFoldable(dictFoldable) {
    return Data_Foldable.foldr(dictFoldable)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
  };

  var findIndex = function findIndex(fn) {
    var go = function go($copy_v) {
      return function ($copy_v1) {
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(v, v1) {
          if (v1 instanceof Data_List_Types.Cons) {
            if (fn(v1.value0)) {
              $tco_done = true;
              return new Data_Maybe.Just(v);
            }

            ;

            if (Data_Boolean.otherwise) {
              $tco_var_v = v + 1 | 0;
              $copy_v1 = v1.value1;
              return;
            }

            ;
          }

          ;

          if (v1 instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return Data_Maybe.Nothing.value;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 301, column 3 - line 301, column 35): " + [v.constructor.name, v1.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_v, $copy_v1);
        }

        ;
        return $tco_result;
      };
    };

    return go(0);
  };

  var filter = function filter(p) {
    var go = function go($copy_acc) {
      return function ($copy_v) {
        var $tco_var_acc = $copy_acc;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v) {
          if (v instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return reverse(acc);
          }

          ;

          if (v instanceof Data_List_Types.Cons) {
            if (p(v.value0)) {
              $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
              $copy_v = v.value1;
              return;
            }

            ;

            if (Data_Boolean.otherwise) {
              $tco_var_acc = acc;
              $copy_v = v.value1;
              return;
            }

            ;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 390, column 3 - line 390, column 27): " + [acc.constructor.name, v.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $copy_v);
        }

        ;
        return $tco_result;
      };
    };

    return go(Data_List_Types.Nil.value);
  };

  var elemIndex = function elemIndex(dictEq) {
    return function (x) {
      return findIndex(function (v) {
        return Data_Eq.eq(dictEq)(v)(x);
      });
    };
  };

  var dropWhile = function dropWhile(p) {
    var go = function go($copy_v) {
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v) {
        if (v instanceof Data_List_Types.Cons && p(v.value0)) {
          $copy_v = v.value1;
          return;
        }

        ;
        $tco_done = true;
        return v;
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
      }

      ;
      return $tco_result;
    };

    return go;
  };

  var drop = function drop($copy_v) {
    return function ($copy_v1) {
      var $tco_var_v = $copy_v;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v, v1) {
        if (v < 1) {
          $tco_done = true;
          return v1;
        }

        ;

        if (v1 instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return Data_List_Types.Nil.value;
        }

        ;

        if (v1 instanceof Data_List_Types.Cons) {
          $tco_var_v = v - 1 | 0;
          $copy_v1 = v1.value1;
          return;
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 543, column 1 - line 543, column 42): " + [v.constructor.name, v1.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_v, $copy_v1);
      }

      ;
      return $tco_result;
    };
  };

  var deleteAt = function deleteAt(v) {
    return function (v1) {
      if (v === 0 && v1 instanceof Data_List_Types.Cons) {
        return new Data_Maybe.Just(v1.value1);
      }

      ;

      if (v1 instanceof Data_List_Types.Cons) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
          return new Data_List_Types.Cons(v1.value0, v2);
        })(deleteAt(v - 1 | 0)(v1.value1));
      }

      ;
      return Data_Maybe.Nothing.value;
    };
  };

  exports["fromFoldable"] = fromFoldable;
  exports["null"] = $$null;
  exports["length"] = length;
  exports["head"] = head;
  exports["last"] = last;
  exports["init"] = init;
  exports["uncons"] = uncons;
  exports["unsnoc"] = unsnoc;
  exports["elemIndex"] = elemIndex;
  exports["findIndex"] = findIndex;
  exports["insertAt"] = insertAt;
  exports["deleteAt"] = deleteAt;
  exports["reverse"] = reverse;
  exports["filter"] = filter;
  exports["mapMaybe"] = mapMaybe;
  exports["take"] = take;
  exports["takeWhile"] = takeWhile;
  exports["drop"] = drop;
  exports["dropWhile"] = dropWhile;
  exports["span"] = span;
  exports["zipWith"] = zipWith;
  exports["zip"] = zip;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.CatQueue"] = $PS["Data.CatQueue"] || {};
  var exports = $PS["Data.CatQueue"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];

  var CatQueue = function () {
    function CatQueue(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    CatQueue.create = function (value0) {
      return function (value1) {
        return new CatQueue(value0, value1);
      };
    };

    return CatQueue;
  }();

  var uncons = function uncons($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
        $tco_done = true;
        return Data_Maybe.Nothing.value;
      }

      ;

      if (v.value0 instanceof Data_List_Types.Nil) {
        $copy_v = new CatQueue(Data_List.reverse(v.value1), Data_List_Types.Nil.value);
        return;
      }

      ;

      if (v.value0 instanceof Data_List_Types.Cons) {
        $tco_done = true;
        return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0, new CatQueue(v.value0.value1, v.value1)));
      }

      ;
      throw new Error("Failed pattern match at Data.CatQueue (line 83, column 1 - line 83, column 63): " + [v.constructor.name]);
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  var snoc = function snoc(v) {
    return function (a) {
      return new CatQueue(v.value0, new Data_List_Types.Cons(a, v.value1));
    };
  };

  var $$null = function $$null(v) {
    if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
      return true;
    }

    ;
    return false;
  };

  var empty = new CatQueue(Data_List_Types.Nil.value, Data_List_Types.Nil.value);
  exports["CatQueue"] = CatQueue;
  exports["empty"] = empty;
  exports["null"] = $$null;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.CatList"] = $PS["Data.CatList"] || {};
  var exports = $PS["Data.CatList"];
  var Data_CatQueue = $PS["Data.CatQueue"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];

  var CatNil = function () {
    function CatNil() {}

    ;
    CatNil.value = new CatNil();
    return CatNil;
  }();

  var CatCons = function () {
    function CatCons(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    CatCons.create = function (value0) {
      return function (value1) {
        return new CatCons(value0, value1);
      };
    };

    return CatCons;
  }();

  var link = function link(v) {
    return function (v1) {
      if (v instanceof CatNil) {
        return v1;
      }

      ;

      if (v1 instanceof CatNil) {
        return v;
      }

      ;

      if (v instanceof CatCons) {
        return new CatCons(v.value0, Data_CatQueue.snoc(v.value1)(v1));
      }

      ;
      throw new Error("Failed pattern match at Data.CatList (line 109, column 1 - line 109, column 54): " + [v.constructor.name, v1.constructor.name]);
    };
  };

  var foldr = function foldr(k) {
    return function (b) {
      return function (q) {
        var foldl = function foldl($copy_v) {
          return function ($copy_c) {
            return function ($copy_v1) {
              var $tco_var_v = $copy_v;
              var $tco_var_c = $copy_c;
              var $tco_done = false;
              var $tco_result;

              function $tco_loop(v, c, v1) {
                if (v1 instanceof Data_List_Types.Nil) {
                  $tco_done = true;
                  return c;
                }

                ;

                if (v1 instanceof Data_List_Types.Cons) {
                  $tco_var_v = v;
                  $tco_var_c = v(c)(v1.value0);
                  $copy_v1 = v1.value1;
                  return;
                }

                ;
                throw new Error("Failed pattern match at Data.CatList (line 125, column 3 - line 125, column 59): " + [v.constructor.name, c.constructor.name, v1.constructor.name]);
              }

              ;

              while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $tco_var_c, $copy_v1);
              }

              ;
              return $tco_result;
            };
          };
        };

        var go = function go($copy_xs) {
          return function ($copy_ys) {
            var $tco_var_xs = $copy_xs;
            var $tco_done = false;
            var $tco_result;

            function $tco_loop(xs, ys) {
              var v = Data_CatQueue.uncons(xs);

              if (v instanceof Data_Maybe.Nothing) {
                $tco_done = true;
                return foldl(function (x) {
                  return function (i) {
                    return i(x);
                  };
                })(b)(ys);
              }

              ;

              if (v instanceof Data_Maybe.Just) {
                $tco_var_xs = v.value0.value1;
                $copy_ys = new Data_List_Types.Cons(k(v.value0.value0), ys);
                return;
              }

              ;
              throw new Error("Failed pattern match at Data.CatList (line 121, column 14 - line 123, column 67): " + [v.constructor.name]);
            }

            ;

            while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_xs, $copy_ys);
            }

            ;
            return $tco_result;
          };
        };

        return go(q)(Data_List_Types.Nil.value);
      };
    };
  };

  var uncons = function uncons(v) {
    if (v instanceof CatNil) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v instanceof CatCons) {
      return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, function () {
        var $44 = Data_CatQueue["null"](v.value1);

        if ($44) {
          return CatNil.value;
        }

        ;
        return foldr(link)(CatNil.value)(v.value1);
      }()));
    }

    ;
    throw new Error("Failed pattern match at Data.CatList (line 100, column 1 - line 100, column 61): " + [v.constructor.name]);
  };

  var empty = CatNil.value;
  var append = link;
  var semigroupCatList = new Data_Semigroup.Semigroup(append);

  var snoc = function snoc(cat) {
    return function (a) {
      return append(cat)(new CatCons(a, Data_CatQueue.empty));
    };
  };

  exports["CatNil"] = CatNil;
  exports["CatCons"] = CatCons;
  exports["empty"] = empty;
  exports["append"] = append;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
  exports["semigroupCatList"] = semigroupCatList;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Monad.Free"] = $PS["Control.Monad.Free"] || {};
  var exports = $PS["Control.Monad.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_CatList = $PS["Data.CatList"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];

  var Free = function () {
    function Free(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Free.create = function (value0) {
      return function (value1) {
        return new Free(value0, value1);
      };
    };

    return Free;
  }();

  var Return = function () {
    function Return(value0) {
      this.value0 = value0;
    }

    ;

    Return.create = function (value0) {
      return new Return(value0);
    };

    return Return;
  }();

  var Bind = function () {
    function Bind(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    Bind.create = function (value0) {
      return function (value1) {
        return new Bind(value0, value1);
      };
    };

    return Bind;
  }();

  var toView = function toView($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      var runExpF = function runExpF(v2) {
        return v2;
      };

      var concatF = function concatF(v2) {
        return function (r) {
          return new Free(v2.value0, Data_Semigroup.append(Data_CatList.semigroupCatList)(v2.value1)(r));
        };
      };

      if (v.value0 instanceof Return) {
        var v2 = Data_CatList.uncons(v.value1);

        if (v2 instanceof Data_Maybe.Nothing) {
          $tco_done = true;
          return new Return(v.value0.value0);
        }

        ;

        if (v2 instanceof Data_Maybe.Just) {
          $copy_v = concatF(runExpF(v2.value0.value0)(v.value0.value0))(v2.value0.value1);
          return;
        }

        ;
        throw new Error("Failed pattern match at Control.Monad.Free (line 227, column 7 - line 231, column 64): " + [v2.constructor.name]);
      }

      ;

      if (v.value0 instanceof Bind) {
        $tco_done = true;
        return new Bind(v.value0.value0, function (a) {
          return concatF(v.value0.value1(a))(v.value1);
        });
      }

      ;
      throw new Error("Failed pattern match at Control.Monad.Free (line 225, column 3 - line 233, column 56): " + [v.value0.constructor.name]);
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  var resume$prime = function resume$prime(k) {
    return function (j) {
      return function (f) {
        var v = toView(f);

        if (v instanceof Return) {
          return j(v.value0);
        }

        ;

        if (v instanceof Bind) {
          return k(v.value0)(v.value1);
        }

        ;
        throw new Error("Failed pattern match at Control.Monad.Free (line 213, column 17 - line 215, column 20): " + [v.constructor.name]);
      };
    };
  };

  var resume = function resume(dictFunctor) {
    return resume$prime(function (g) {
      return function (i) {
        return new Data_Either.Left(Data_Functor.map(dictFunctor)(i)(g));
      };
    })(Data_Either.Right.create);
  };

  var fromView = function fromView(f) {
    return new Free(f, Data_CatList.empty);
  };

  var freeMonad = new Control_Monad.Monad(function () {
    return freeApplicative;
  }, function () {
    return freeBind;
  });
  var freeFunctor = new Data_Functor.Functor(function (k) {
    return function (f) {
      return Control_Bind.bindFlipped(freeBind)(function ($120) {
        return Control_Applicative.pure(freeApplicative)(k($120));
      })(f);
    };
  });
  var freeBind = new Control_Bind.Bind(function () {
    return freeApply;
  }, function (v) {
    return function (k) {
      return new Free(v.value0, Data_CatList.snoc(v.value1)(k));
    };
  });
  var freeApply = new Control_Apply.Apply(function () {
    return freeFunctor;
  }, Control_Monad.ap(freeMonad));
  var freeApplicative = new Control_Applicative.Applicative(function () {
    return freeApply;
  }, function ($121) {
    return fromView(Return.create($121));
  });

  var liftF = function liftF(f) {
    return fromView(new Bind(f, function ($122) {
      return Control_Applicative.pure(freeApplicative)($122);
    }));
  };

  exports["liftF"] = liftF;
  exports["resume"] = resume;
  exports["freeFunctor"] = freeFunctor;
  exports["freeBind"] = freeBind;
  exports["freeApplicative"] = freeApplicative;
  exports["freeApply"] = freeApply;
  exports["freeMonad"] = freeMonad;
})(PS);

(function (exports) {
  "use strict";

  exports.map_ = function (f) {
    return function (a) {
      return function () {
        return f(a());
      };
    };
  };

  exports.pure_ = function (a) {
    return function () {
      return a;
    };
  };

  exports.bind_ = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Control.Monad.ST.Internal"] = PS["Control.Monad.ST.Internal"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Monad.ST.Internal"] = $PS["Control.Monad.ST.Internal"] || {};
  var exports = $PS["Control.Monad.ST.Internal"];
  var $foreign = $PS["Control.Monad.ST.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];
  var functorST = new Data_Functor.Functor($foreign.map_);
  var monadST = new Control_Monad.Monad(function () {
    return applicativeST;
  }, function () {
    return bindST;
  });
  var bindST = new Control_Bind.Bind(function () {
    return applyST;
  }, $foreign.bind_);
  var applyST = new Control_Apply.Apply(function () {
    return functorST;
  }, Control_Monad.ap(monadST));
  var applicativeST = new Control_Applicative.Applicative(function () {
    return applyST;
  }, $foreign.pure_);
  exports["functorST"] = functorST;
  exports["applyST"] = applyST;
  exports["applicativeST"] = applicativeST;
  exports["bindST"] = bindST;
  exports["monadST"] = monadST;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Parallel.Class"] = $PS["Control.Parallel.Class"] || {};
  var exports = $PS["Control.Parallel.Class"];

  var Parallel = function Parallel(Applicative1, Monad0, parallel, sequential) {
    this.Applicative1 = Applicative1;
    this.Monad0 = Monad0;
    this.parallel = parallel;
    this.sequential = sequential;
  };

  var sequential = function sequential(dict) {
    return dict.sequential;
  };

  var parallel = function parallel(dict) {
    return dict.parallel;
  };

  exports["parallel"] = parallel;
  exports["sequential"] = sequential;
  exports["Parallel"] = Parallel;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Parallel"] = $PS["Control.Parallel"] || {};
  var exports = $PS["Control.Parallel"];
  var Control_Category = $PS["Control.Category"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Foldable = $PS["Data.Foldable"];

  var parTraverse_ = function parTraverse_(dictParallel) {
    return function (dictFoldable) {
      return function (f) {
        return function ($17) {
          return Control_Parallel_Class.sequential(dictParallel)(Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)(function ($18) {
            return Control_Parallel_Class.parallel(dictParallel)(f($18));
          })($17));
        };
      };
    };
  };

  var parSequence_ = function parSequence_(dictParallel) {
    return function (dictFoldable) {
      return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
    };
  };

  exports["parTraverse_"] = parTraverse_;
  exports["parSequence_"] = parSequence_;
})(PS);

(function (exports) {
  // module Control.Promise
  exports.promise = function (f) {
    return function () {
      return new Promise(function (success, error) {
        var succF = function succF(s) {
          return function () {
            return success(s);
          };
        };

        var failF = function failF(s) {
          return function () {
            return error(s);
          };
        }; // This indicates the aff was wrong?


        try {
          f(succF)(failF)();
        } catch (e) {
          error(e);
        }
      });
    };
  };

  exports.thenImpl = function (promise) {
    return function (errCB) {
      return function (succCB) {
        return function () {
          promise.then(succCB, errCB);
        };
      };
    };
  };
})(PS["Control.Promise"] = PS["Control.Promise"] || {});

(function (exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect"] = $PS["Effect"] || {};
  var exports = $PS["Effect"];
  var $foreign = $PS["Effect"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];
  var monadEffect = new Control_Monad.Monad(function () {
    return applicativeEffect;
  }, function () {
    return bindEffect;
  });
  var bindEffect = new Control_Bind.Bind(function () {
    return applyEffect;
  }, $foreign.bindE);
  var applyEffect = new Control_Apply.Apply(function () {
    return functorEffect;
  }, Control_Monad.ap(monadEffect));
  var applicativeEffect = new Control_Applicative.Applicative(function () {
    return applyEffect;
  }, $foreign.pureE);
  var functorEffect = new Data_Functor.Functor(Control_Applicative.liftA1(applicativeEffect));
  exports["functorEffect"] = functorEffect;
  exports["applyEffect"] = applyEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS);

(function (exports) {
  /* globals setImmediate, clearImmediate, setTimeout, clearTimeout */

  /* jshint -W083, -W098, -W003 */
  "use strict";

  var Aff = function () {
    // A unique value for empty.
    var EMPTY = {};
    /*
    An awkward approximation. We elide evidence we would otherwise need in PS for
    efficiency sake.
    data Aff eff a
    = Pure a
    | Throw Error
    | Catch (Aff eff a) (Error -> Aff eff a)
    | Sync (Eff eff a)
    | Async ((Either Error a -> Eff eff Unit) -> Eff eff (Canceler eff))
    | forall b. Bind (Aff eff b) (b -> Aff eff a)
    | forall b. Bracket (Aff eff b) (BracketConditions eff b) (b -> Aff eff a)
    | forall b. Fork Boolean (Aff eff b) ?(Fiber eff b -> a)
    | Sequential (ParAff aff a)
    */

    var PURE = "Pure";
    var THROW = "Throw";
    var CATCH = "Catch";
    var SYNC = "Sync";
    var ASYNC = "Async";
    var BIND = "Bind";
    var BRACKET = "Bracket";
    var FORK = "Fork";
    var SEQ = "Sequential";
    /*
    data ParAff eff a
    = forall b. Map (b -> a) (ParAff eff b)
    | forall b. Apply (ParAff eff (b -> a)) (ParAff eff b)
    | Alt (ParAff eff a) (ParAff eff a)
    | ?Par (Aff eff a)
    */

    var MAP = "Map";
    var APPLY = "Apply";
    var ALT = "Alt"; // Various constructors used in interpretation

    var CONS = "Cons"; // Cons-list, for stacks

    var RESUME = "Resume"; // Continue indiscriminately

    var RELEASE = "Release"; // Continue with bracket finalizers

    var FINALIZER = "Finalizer"; // A non-interruptible effect

    var FINALIZED = "Finalized"; // Marker for finalization

    var FORKED = "Forked"; // Reference to a forked fiber, with resumption stack

    var FIBER = "Fiber"; // Actual fiber reference

    var THUNK = "Thunk"; // Primed effect, ready to invoke

    function Aff(tag, _1, _2, _3) {
      this.tag = tag;
      this._1 = _1;
      this._2 = _2;
      this._3 = _3;
    }

    function AffCtr(tag) {
      var fn = function fn(_1, _2, _3) {
        return new Aff(tag, _1, _2, _3);
      };

      fn.tag = tag;
      return fn;
    }

    function nonCanceler(error) {
      return new Aff(PURE, void 0);
    }

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function runSync(left, right, eff) {
      try {
        return right(eff());
      } catch (error) {
        return left(error);
      }
    }

    function runAsync(left, eff, k) {
      try {
        return eff(k)();
      } catch (error) {
        k(left(error))();
        return nonCanceler;
      }
    }

    var Scheduler = function () {
      var limit = 1024;
      var size = 0;
      var ix = 0;
      var queue = new Array(limit);
      var draining = false;

      function drain() {
        var thunk;
        draining = true;

        while (size !== 0) {
          size--;
          thunk = queue[ix];
          queue[ix] = void 0;
          ix = (ix + 1) % limit;
          thunk();
        }

        draining = false;
      }

      return {
        isDraining: function isDraining() {
          return draining;
        },
        enqueue: function enqueue(cb) {
          var i, tmp;

          if (size === limit) {
            tmp = draining;
            drain();
            draining = tmp;
          }

          queue[(ix + size) % limit] = cb;
          size++;

          if (!draining) {
            drain();
          }
        }
      };
    }();

    function Supervisor(util) {
      var fibers = {};
      var fiberId = 0;
      var count = 0;
      return {
        register: function register(fiber) {
          var fid = fiberId++;
          fiber.onComplete({
            rethrow: true,
            handler: function handler(result) {
              return function () {
                count--;
                delete fibers[fid];
              };
            }
          });
          fibers[fid] = fiber;
          count++;
        },
        isEmpty: function isEmpty() {
          return count === 0;
        },
        killAll: function killAll(killError, cb) {
          return function () {
            if (count === 0) {
              return cb();
            }

            var killCount = 0;
            var kills = {};

            function kill(fid) {
              kills[fid] = fibers[fid].kill(killError, function (result) {
                return function () {
                  delete kills[fid];
                  killCount--;

                  if (util.isLeft(result) && util.fromLeft(result)) {
                    setTimeout(function () {
                      throw util.fromLeft(result);
                    }, 0);
                  }

                  if (killCount === 0) {
                    cb();
                  }
                };
              })();
            }

            for (var k in fibers) {
              if (fibers.hasOwnProperty(k)) {
                killCount++;
                kill(k);
              }
            }

            fibers = {};
            fiberId = 0;
            count = 0;
            return function (error) {
              return new Aff(SYNC, function () {
                for (var k in kills) {
                  if (kills.hasOwnProperty(k)) {
                    kills[k]();
                  }
                }
              });
            };
          };
        }
      };
    } // Fiber state machine


    var SUSPENDED = 0; // Suspended, pending a join.

    var CONTINUE = 1; // Interpret the next instruction.

    var STEP_BIND = 2; // Apply the next bind.

    var STEP_RESULT = 3; // Handle potential failure from a result.

    var PENDING = 4; // An async effect is running.

    var RETURN = 5; // The current stack has returned.

    var COMPLETED = 6; // The entire fiber has completed.

    function Fiber(util, supervisor, aff) {
      // Monotonically increasing tick, increased on each asynchronous turn.
      var runTick = 0; // The current branch of the state machine.

      var status = SUSPENDED; // The current point of interest for the state machine branch.

      var step = aff; // Successful step

      var fail = null; // Failure step

      var interrupt = null; // Asynchronous interrupt
      // Stack of continuations for the current fiber.

      var bhead = null;
      var btail = null; // Stack of attempts and finalizers for error recovery. Every `Cons` is also
      // tagged with current `interrupt` state. We use this to track which items
      // should be ignored or evaluated as a result of a kill.

      var attempts = null; // A special state is needed for Bracket, because it cannot be killed. When
      // we enter a bracket acquisition or finalizer, we increment the counter,
      // and then decrement once complete.

      var bracketCount = 0; // Each join gets a new id so they can be revoked.

      var joinId = 0;
      var joins = null;
      var rethrow = true; // Each invocation of `run` requires a tick. When an asynchronous effect is
      // resolved, we must check that the local tick coincides with the fiber
      // tick before resuming. This prevents multiple async continuations from
      // accidentally resuming the same fiber. A common example may be invoking
      // the provided callback in `makeAff` more than once, but it may also be an
      // async effect resuming after the fiber was already cancelled.

      function _run(localRunTick) {
        var tmp, result, attempt;

        while (true) {
          tmp = null;
          result = null;
          attempt = null;

          switch (status) {
            case STEP_BIND:
              status = CONTINUE;
              step = bhead(step);

              if (btail === null) {
                bhead = null;
              } else {
                bhead = btail._1;
                btail = btail._2;
              }

              break;

            case STEP_RESULT:
              if (util.isLeft(step)) {
                status = RETURN;
                fail = step;
                step = null;
              } else if (bhead === null) {
                status = RETURN;
              } else {
                status = STEP_BIND;
                step = util.fromRight(step);
              }

              break;

            case CONTINUE:
              switch (step.tag) {
                case BIND:
                  if (bhead) {
                    btail = new Aff(CONS, bhead, btail);
                  }

                  bhead = step._2;
                  status = CONTINUE;
                  step = step._1;
                  break;

                case PURE:
                  if (bhead === null) {
                    status = RETURN;
                    step = util.right(step._1);
                  } else {
                    status = STEP_BIND;
                    step = step._1;
                  }

                  break;

                case SYNC:
                  status = STEP_RESULT;
                  step = runSync(util.left, util.right, step._1);
                  break;

                case ASYNC:
                  status = PENDING;
                  step = runAsync(util.left, step._1, function (result) {
                    return function () {
                      if (runTick !== localRunTick) {
                        return;
                      }

                      runTick++;
                      Scheduler.enqueue(function () {
                        // It's possible to interrupt the fiber between enqueuing and
                        // resuming, so we need to check that the runTick is still
                        // valid.
                        if (runTick !== localRunTick + 1) {
                          return;
                        }

                        status = STEP_RESULT;
                        step = result;

                        _run(runTick);
                      });
                    };
                  });
                  return;

                case THROW:
                  status = RETURN;
                  fail = util.left(step._1);
                  step = null;
                  break;
                // Enqueue the Catch so that we can call the error handler later on
                // in case of an exception.

                case CATCH:
                  if (bhead === null) {
                    attempts = new Aff(CONS, step, attempts, interrupt);
                  } else {
                    attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
                  }

                  bhead = null;
                  btail = null;
                  status = CONTINUE;
                  step = step._1;
                  break;
                // Enqueue the Bracket so that we can call the appropriate handlers
                // after resource acquisition.

                case BRACKET:
                  bracketCount++;

                  if (bhead === null) {
                    attempts = new Aff(CONS, step, attempts, interrupt);
                  } else {
                    attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
                  }

                  bhead = null;
                  btail = null;
                  status = CONTINUE;
                  step = step._1;
                  break;

                case FORK:
                  status = STEP_RESULT;
                  tmp = Fiber(util, supervisor, step._2);

                  if (supervisor) {
                    supervisor.register(tmp);
                  }

                  if (step._1) {
                    tmp.run();
                  }

                  step = util.right(tmp);
                  break;

                case SEQ:
                  status = CONTINUE;
                  step = sequential(util, supervisor, step._1);
                  break;
              }

              break;

            case RETURN:
              bhead = null;
              btail = null; // If the current stack has returned, and we have no other stacks to
              // resume or finalizers to run, the fiber has halted and we can
              // invoke all join callbacks. Otherwise we need to resume.

              if (attempts === null) {
                status = COMPLETED;
                step = interrupt || fail || step;
              } else {
                // The interrupt status for the enqueued item.
                tmp = attempts._3;
                attempt = attempts._1;
                attempts = attempts._2;

                switch (attempt.tag) {
                  // We cannot recover from an unmasked interrupt. Otherwise we should
                  // continue stepping, or run the exception handler if an exception
                  // was raised.
                  case CATCH:
                    // We should compare the interrupt status as well because we
                    // only want it to apply if there has been an interrupt since
                    // enqueuing the catch.
                    if (interrupt && interrupt !== tmp && bracketCount === 0) {
                      status = RETURN;
                    } else if (fail) {
                      status = CONTINUE;
                      step = attempt._2(util.fromLeft(fail));
                      fail = null;
                    }

                    break;
                  // We cannot resume from an unmasked interrupt or exception.

                  case RESUME:
                    // As with Catch, we only want to ignore in the case of an
                    // interrupt since enqueing the item.
                    if (interrupt && interrupt !== tmp && bracketCount === 0 || fail) {
                      status = RETURN;
                    } else {
                      bhead = attempt._1;
                      btail = attempt._2;
                      status = STEP_BIND;
                      step = util.fromRight(step);
                    }

                    break;
                  // If we have a bracket, we should enqueue the handlers,
                  // and continue with the success branch only if the fiber has
                  // not been interrupted. If the bracket acquisition failed, we
                  // should not run either.

                  case BRACKET:
                    bracketCount--;

                    if (fail === null) {
                      result = util.fromRight(step); // We need to enqueue the Release with the same interrupt
                      // status as the Bracket that is initiating it.

                      attempts = new Aff(CONS, new Aff(RELEASE, attempt._2, result), attempts, tmp); // We should only coninue as long as the interrupt status has not changed or
                      // we are currently within a non-interruptable finalizer.

                      if (interrupt === tmp || bracketCount > 0) {
                        status = CONTINUE;
                        step = attempt._3(result);
                      }
                    }

                    break;
                  // Enqueue the appropriate handler. We increase the bracket count
                  // because it should not be cancelled.

                  case RELEASE:
                    attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                    status = CONTINUE; // It has only been killed if the interrupt status has changed
                    // since we enqueued the item, and the bracket count is 0. If the
                    // bracket count is non-zero then we are in a masked state so it's
                    // impossible to be killed.

                    if (interrupt && interrupt !== tmp && bracketCount === 0) {
                      step = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                    } else if (fail) {
                      step = attempt._1.failed(util.fromLeft(fail))(attempt._2);
                    } else {
                      step = attempt._1.completed(util.fromRight(step))(attempt._2);
                    }

                    fail = null;
                    bracketCount++;
                    break;

                  case FINALIZER:
                    bracketCount++;
                    attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                    status = CONTINUE;
                    step = attempt._1;
                    break;

                  case FINALIZED:
                    bracketCount--;
                    status = RETURN;
                    step = attempt._1;
                    fail = attempt._2;
                    break;
                }
              }

              break;

            case COMPLETED:
              for (var k in joins) {
                if (joins.hasOwnProperty(k)) {
                  rethrow = rethrow && joins[k].rethrow;
                  runEff(joins[k].handler(step));
                }
              }

              joins = null; // If we have an interrupt and a fail, then the thread threw while
              // running finalizers. This should always rethrow in a fresh stack.

              if (interrupt && fail) {
                setTimeout(function () {
                  throw util.fromLeft(fail);
                }, 0); // If we have an unhandled exception, and no other fiber has joined
                // then we need to throw the exception in a fresh stack.
              } else if (util.isLeft(step) && rethrow) {
                setTimeout(function () {
                  // Guard on reathrow because a completely synchronous fiber can
                  // still have an observer which was added after-the-fact.
                  if (rethrow) {
                    throw util.fromLeft(step);
                  }
                }, 0);
              }

              return;

            case SUSPENDED:
              status = CONTINUE;
              break;

            case PENDING:
              return;
          }
        }
      }

      function onComplete(join) {
        return function () {
          if (status === COMPLETED) {
            rethrow = rethrow && join.rethrow;
            join.handler(step)();
            return function () {};
          }

          var jid = joinId++;
          joins = joins || {};
          joins[jid] = join;
          return function () {
            if (joins !== null) {
              delete joins[jid];
            }
          };
        };
      }

      function kill(error, cb) {
        return function () {
          if (status === COMPLETED) {
            cb(util.right(void 0))();
            return function () {};
          }

          var canceler = onComplete({
            rethrow: false,
            handler: function handler()
            /* unused */
            {
              return cb(util.right(void 0));
            }
          })();

          switch (status) {
            case SUSPENDED:
              interrupt = util.left(error);
              status = COMPLETED;
              step = interrupt;

              _run(runTick);

              break;

            case PENDING:
              if (interrupt === null) {
                interrupt = util.left(error);
              }

              if (bracketCount === 0) {
                if (status === PENDING) {
                  attempts = new Aff(CONS, new Aff(FINALIZER, step(error)), attempts, interrupt);
                }

                status = RETURN;
                step = null;
                fail = null;

                _run(++runTick);
              }

              break;

            default:
              if (interrupt === null) {
                interrupt = util.left(error);
              }

              if (bracketCount === 0) {
                status = RETURN;
                step = null;
                fail = null;
              }

          }

          return canceler;
        };
      }

      function join(cb) {
        return function () {
          var canceler = onComplete({
            rethrow: false,
            handler: cb
          })();

          if (status === SUSPENDED) {
            _run(runTick);
          }

          return canceler;
        };
      }

      return {
        kill: kill,
        join: join,
        onComplete: onComplete,
        isSuspended: function isSuspended() {
          return status === SUSPENDED;
        },
        run: function run() {
          if (status === SUSPENDED) {
            if (!Scheduler.isDraining()) {
              Scheduler.enqueue(function () {
                _run(runTick);
              });
            } else {
              _run(runTick);
            }
          }
        }
      };
    }

    function runPar(util, supervisor, par, cb) {
      // Table of all forked fibers.
      var fiberId = 0;
      var fibers = {}; // Table of currently running cancelers, as a product of `Alt` behavior.

      var killId = 0;
      var kills = {}; // Error used for early cancelation on Alt branches.

      var early = new Error("[ParAff] Early exit"); // Error used to kill the entire tree.

      var interrupt = null; // The root pointer of the tree.

      var root = EMPTY; // Walks a tree, invoking all the cancelers. Returns the table of pending
      // cancellation fibers.

      function kill(error, par, cb) {
        var step = par;
        var head = null;
        var tail = null;
        var count = 0;
        var kills = {};
        var tmp, kid;

        loop: while (true) {
          tmp = null;

          switch (step.tag) {
            case FORKED:
              if (step._3 === EMPTY) {
                tmp = fibers[step._1];
                kills[count++] = tmp.kill(error, function (result) {
                  return function () {
                    count--;

                    if (count === 0) {
                      cb(result)();
                    }
                  };
                });
              } // Terminal case.


              if (head === null) {
                break loop;
              } // Go down the right side of the tree.


              step = head._2;

              if (tail === null) {
                head = null;
              } else {
                head = tail._1;
                tail = tail._2;
              }

              break;

            case MAP:
              step = step._2;
              break;

            case APPLY:
            case ALT:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }

              head = step;
              step = step._1;
              break;
          }
        }

        if (count === 0) {
          cb(util.right(void 0))();
        } else {
          // Run the cancelation effects. We alias `count` because it's mutable.
          kid = 0;
          tmp = count;

          for (; kid < tmp; kid++) {
            kills[kid] = kills[kid]();
          }
        }

        return kills;
      } // When a fiber resolves, we need to bubble back up the tree with the
      // result, computing the applicative nodes.


      function join(result, head, tail) {
        var fail, step, lhs, rhs, tmp, kid;

        if (util.isLeft(result)) {
          fail = result;
          step = null;
        } else {
          step = result;
          fail = null;
        }

        loop: while (true) {
          lhs = null;
          rhs = null;
          tmp = null;
          kid = null; // We should never continue if the entire tree has been interrupted.

          if (interrupt !== null) {
            return;
          } // We've made it all the way to the root of the tree, which means
          // the tree has fully evaluated.


          if (head === null) {
            cb(fail || step)();
            return;
          } // The tree has already been computed, so we shouldn't try to do it
          // again. This should never happen.
          // TODO: Remove this?


          if (head._3 !== EMPTY) {
            return;
          }

          switch (head.tag) {
            case MAP:
              if (fail === null) {
                head._3 = util.right(head._1(util.fromRight(step)));
                step = head._3;
              } else {
                head._3 = fail;
              }

              break;

            case APPLY:
              lhs = head._1._3;
              rhs = head._2._3; // If we have a failure we should kill the other side because we
              // can't possible yield a result anymore.

              if (fail) {
                head._3 = fail;
                tmp = true;
                kid = killId++;
                kills[kid] = kill(early, fail === lhs ? head._2 : head._1, function ()
                /* unused */
                {
                  return function () {
                    delete kills[kid];

                    if (tmp) {
                      tmp = false;
                    } else if (tail === null) {
                      join(fail, null, null);
                    } else {
                      join(fail, tail._1, tail._2);
                    }
                  };
                });

                if (tmp) {
                  tmp = false;
                  return;
                }
              } else if (lhs === EMPTY || rhs === EMPTY) {
                // We can only proceed if both sides have resolved.
                return;
              } else {
                step = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
                head._3 = step;
              }

              break;

            case ALT:
              lhs = head._1._3;
              rhs = head._2._3; // We can only proceed if both have resolved or we have a success

              if (lhs === EMPTY && util.isLeft(rhs) || rhs === EMPTY && util.isLeft(lhs)) {
                return;
              } // If both sides resolve with an error, we should continue with the
              // first error


              if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
                fail = step === lhs ? rhs : lhs;
                step = null;
                head._3 = fail;
              } else {
                head._3 = step;
                tmp = true;
                kid = killId++; // Once a side has resolved, we need to cancel the side that is still
                // pending before we can continue.

                kills[kid] = kill(early, step === lhs ? head._2 : head._1, function ()
                /* unused */
                {
                  return function () {
                    delete kills[kid];

                    if (tmp) {
                      tmp = false;
                    } else if (tail === null) {
                      join(step, null, null);
                    } else {
                      join(step, tail._1, tail._2);
                    }
                  };
                });

                if (tmp) {
                  tmp = false;
                  return;
                }
              }

              break;
          }

          if (tail === null) {
            head = null;
          } else {
            head = tail._1;
            tail = tail._2;
          }
        }
      }

      function resolve(fiber) {
        return function (result) {
          return function () {
            delete fibers[fiber._1];
            fiber._3 = result;
            join(result, fiber._2._1, fiber._2._2);
          };
        };
      } // Walks the applicative tree, substituting non-applicative nodes with
      // `FORKED` nodes. In this tree, all applicative nodes use the `_3` slot
      // as a mutable slot for memoization. In an unresolved state, the `_3`
      // slot is `EMPTY`. In the cases of `ALT` and `APPLY`, we always walk
      // the left side first, because both operations are left-associative. As
      // we `RETURN` from those branches, we then walk the right side.


      function run() {
        var status = CONTINUE;
        var step = par;
        var head = null;
        var tail = null;
        var tmp, fid;

        loop: while (true) {
          tmp = null;
          fid = null;

          switch (status) {
            case CONTINUE:
              switch (step.tag) {
                case MAP:
                  if (head) {
                    tail = new Aff(CONS, head, tail);
                  }

                  head = new Aff(MAP, step._1, EMPTY, EMPTY);
                  step = step._2;
                  break;

                case APPLY:
                  if (head) {
                    tail = new Aff(CONS, head, tail);
                  }

                  head = new Aff(APPLY, EMPTY, step._2, EMPTY);
                  step = step._1;
                  break;

                case ALT:
                  if (head) {
                    tail = new Aff(CONS, head, tail);
                  }

                  head = new Aff(ALT, EMPTY, step._2, EMPTY);
                  step = step._1;
                  break;

                default:
                  // When we hit a leaf value, we suspend the stack in the `FORKED`.
                  // When the fiber resolves, it can bubble back up the tree.
                  fid = fiberId++;
                  status = RETURN;
                  tmp = step;
                  step = new Aff(FORKED, fid, new Aff(CONS, head, tail), EMPTY);
                  tmp = Fiber(util, supervisor, tmp);
                  tmp.onComplete({
                    rethrow: false,
                    handler: resolve(step)
                  })();
                  fibers[fid] = tmp;

                  if (supervisor) {
                    supervisor.register(tmp);
                  }

              }

              break;

            case RETURN:
              // Terminal case, we are back at the root.
              if (head === null) {
                break loop;
              } // If we are done with the right side, we need to continue down the
              // left. Otherwise we should continue up the stack.


              if (head._1 === EMPTY) {
                head._1 = step;
                status = CONTINUE;
                step = head._2;
                head._2 = EMPTY;
              } else {
                head._2 = step;
                step = head;

                if (tail === null) {
                  head = null;
                } else {
                  head = tail._1;
                  tail = tail._2;
                }
              }

          }
        } // Keep a reference to the tree root so it can be cancelled.


        root = step;

        for (fid = 0; fid < fiberId; fid++) {
          fibers[fid].run();
        }
      } // Cancels the entire tree. If there are already subtrees being canceled,
      // we need to first cancel those joins. We will then add fresh joins for
      // all pending branches including those that were in the process of being
      // canceled.


      function cancel(error, cb) {
        interrupt = util.left(error);
        var innerKills;

        for (var kid in kills) {
          if (kills.hasOwnProperty(kid)) {
            innerKills = kills[kid];

            for (kid in innerKills) {
              if (innerKills.hasOwnProperty(kid)) {
                innerKills[kid]();
              }
            }
          }
        }

        kills = null;
        var newKills = kill(error, root, cb);
        return function (killError) {
          return new Aff(ASYNC, function (killCb) {
            return function () {
              for (var kid in newKills) {
                if (newKills.hasOwnProperty(kid)) {
                  newKills[kid]();
                }
              }

              return nonCanceler;
            };
          });
        };
      }

      run();
      return function (killError) {
        return new Aff(ASYNC, function (killCb) {
          return function () {
            return cancel(killError, killCb);
          };
        });
      };
    }

    function sequential(util, supervisor, par) {
      return new Aff(ASYNC, function (cb) {
        return function () {
          return runPar(util, supervisor, par, cb);
        };
      });
    }

    Aff.EMPTY = EMPTY;
    Aff.Pure = AffCtr(PURE);
    Aff.Throw = AffCtr(THROW);
    Aff.Catch = AffCtr(CATCH);
    Aff.Sync = AffCtr(SYNC);
    Aff.Async = AffCtr(ASYNC);
    Aff.Bind = AffCtr(BIND);
    Aff.Bracket = AffCtr(BRACKET);
    Aff.Fork = AffCtr(FORK);
    Aff.Seq = AffCtr(SEQ);
    Aff.ParMap = AffCtr(MAP);
    Aff.ParApply = AffCtr(APPLY);
    Aff.ParAlt = AffCtr(ALT);
    Aff.Fiber = Fiber;
    Aff.Supervisor = Supervisor;
    Aff.Scheduler = Scheduler;
    Aff.nonCanceler = nonCanceler;
    return Aff;
  }();

  exports._pure = Aff.Pure;
  exports._throwError = Aff.Throw;

  exports._catchError = function (aff) {
    return function (k) {
      return Aff.Catch(aff, k);
    };
  };

  exports._map = function (f) {
    return function (aff) {
      if (aff.tag === Aff.Pure.tag) {
        return Aff.Pure(f(aff._1));
      } else {
        return Aff.Bind(aff, function (value) {
          return Aff.Pure(f(value));
        });
      }
    };
  };

  exports._bind = function (aff) {
    return function (k) {
      return Aff.Bind(aff, k);
    };
  };

  exports._liftEffect = Aff.Sync;

  exports._parAffMap = function (f) {
    return function (aff) {
      return Aff.ParMap(f, aff);
    };
  };

  exports._parAffApply = function (aff1) {
    return function (aff2) {
      return Aff.ParApply(aff1, aff2);
    };
  };

  exports.makeAff = Aff.Async;

  exports._makeFiber = function (util, aff) {
    return function () {
      return Aff.Fiber(util, null, aff);
    };
  };

  exports._sequential = Aff.Seq;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Class"] = $PS["Effect.Class"] || {};
  var exports = $PS["Effect.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect = $PS["Effect"];

  var MonadEffect = function MonadEffect(Monad0, liftEffect) {
    this.Monad0 = Monad0;
    this.liftEffect = liftEffect;
  };

  var monadEffectEffect = new MonadEffect(function () {
    return Effect.monadEffect;
  }, Control_Category.identity(Control_Category.categoryFn));

  var liftEffect = function liftEffect(dict) {
    return dict.liftEffect;
  };

  exports["liftEffect"] = liftEffect;
  exports["MonadEffect"] = MonadEffect;
  exports["monadEffectEffect"] = monadEffectEffect;
})(PS);

(function (exports) {
  "use strict"; // module Partial.Unsafe

  exports.unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});

(function (exports) {
  "use strict"; // module Partial

  exports.crashWith = function () {
    return function (msg) {
      throw new Error(msg);
    };
  };
})(PS["Partial"] = PS["Partial"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Partial"] = $PS["Partial"] || {};
  var exports = $PS["Partial"];
  var $foreign = $PS["Partial"];
  exports["crashWith"] = $foreign.crashWith;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Partial.Unsafe"] = $PS["Partial.Unsafe"] || {};
  var exports = $PS["Partial.Unsafe"];
  var $foreign = $PS["Partial.Unsafe"];
  var Partial = $PS["Partial"];

  var unsafeCrashWith = function unsafeCrashWith(msg) {
    return $foreign.unsafePartial(function (dictPartial) {
      return Partial.crashWith(dictPartial)(msg);
    });
  };

  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS);

(function (exports) {
  "use strict"; // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Unsafe.Coerce"] = $PS["Unsafe.Coerce"] || {};
  var exports = $PS["Unsafe.Coerce"];
  var $foreign = $PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Aff"] = $PS["Effect.Aff"] || {};
  var exports = $PS["Effect.Aff"];
  var $foreign = $PS["Effect.Aff"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var functorParAff = new Data_Functor.Functor($foreign["_parAffMap"]);
  var functorAff = new Data_Functor.Functor($foreign["_map"]);

  var ffiUtil = function () {
    var unsafeFromRight = function unsafeFromRight(v) {
      if (v instanceof Data_Either.Right) {
        return v.value0;
      }

      ;

      if (v instanceof Data_Either.Left) {
        return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
      }

      ;
      throw new Error("Failed pattern match at Effect.Aff (line 400, column 21 - line 402, column 31): " + [v.constructor.name]);
    };

    var unsafeFromLeft = function unsafeFromLeft(v) {
      if (v instanceof Data_Either.Left) {
        return v.value0;
      }

      ;

      if (v instanceof Data_Either.Right) {
        return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
      }

      ;
      throw new Error("Failed pattern match at Effect.Aff (line 395, column 20 - line 399, column 3): " + [v.constructor.name]);
    };

    var isLeft = function isLeft(v) {
      if (v instanceof Data_Either.Left) {
        return true;
      }

      ;

      if (v instanceof Data_Either.Right) {
        return false;
      }

      ;
      throw new Error("Failed pattern match at Effect.Aff (line 390, column 12 - line 392, column 20): " + [v.constructor.name]);
    };

    return {
      isLeft: isLeft,
      fromLeft: unsafeFromLeft,
      fromRight: unsafeFromRight,
      left: Data_Either.Left.create,
      right: Data_Either.Right.create
    };
  }();

  var makeFiber = function makeFiber(aff) {
    return $foreign["_makeFiber"](ffiUtil, aff);
  };

  var launchAff = function launchAff(aff) {
    return function __do() {
      var v = makeFiber(aff)();
      v.run();
      return v;
    };
  };

  var applyParAff = new Control_Apply.Apply(function () {
    return functorParAff;
  }, $foreign["_parAffApply"]);
  var monadAff = new Control_Monad.Monad(function () {
    return applicativeAff;
  }, function () {
    return bindAff;
  });
  var bindAff = new Control_Bind.Bind(function () {
    return applyAff;
  }, $foreign["_bind"]);
  var applyAff = new Control_Apply.Apply(function () {
    return functorAff;
  }, Control_Monad.ap(monadAff));
  var applicativeAff = new Control_Applicative.Applicative(function () {
    return applyAff;
  }, $foreign["_pure"]);
  var monadEffectAff = new Effect_Class.MonadEffect(function () {
    return monadAff;
  }, $foreign["_liftEffect"]);
  var monadThrowAff = new Control_Monad_Error_Class.MonadThrow(function () {
    return monadAff;
  }, $foreign["_throwError"]);
  var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
    return monadThrowAff;
  }, $foreign["_catchError"]);

  var runAff = function runAff(k) {
    return function (aff) {
      return launchAff(Control_Bind.bindFlipped(bindAff)(function ($52) {
        return Effect_Class.liftEffect(monadEffectAff)(k($52));
      })(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
    };
  };

  var runAff_ = function runAff_(k) {
    return function (aff) {
      return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
    };
  };

  var parallelAff = new Control_Parallel_Class.Parallel(function () {
    return applicativeParAff;
  }, function () {
    return monadAff;
  }, Unsafe_Coerce.unsafeCoerce, $foreign["_sequential"]);
  var applicativeParAff = new Control_Applicative.Applicative(function () {
    return applyParAff;
  }, function ($54) {
    return Control_Parallel_Class.parallel(parallelAff)(Control_Applicative.pure(applicativeAff)($54));
  });
  var semigroupCanceler = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return function (err) {
        return Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray)([v(err), v1(err)]);
      };
    };
  });
  var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
  var monoidCanceler = new Data_Monoid.Monoid(function () {
    return semigroupCanceler;
  }, nonCanceler);
  exports["launchAff"] = launchAff;
  exports["runAff"] = runAff;
  exports["runAff_"] = runAff_;
  exports["nonCanceler"] = nonCanceler;
  exports["functorAff"] = functorAff;
  exports["applyAff"] = applyAff;
  exports["applicativeAff"] = applicativeAff;
  exports["bindAff"] = bindAff;
  exports["monadAff"] = monadAff;
  exports["monadThrowAff"] = monadThrowAff;
  exports["monadErrorAff"] = monadErrorAff;
  exports["monadEffectAff"] = monadEffectAff;
  exports["functorParAff"] = functorParAff;
  exports["applyParAff"] = applyParAff;
  exports["applicativeParAff"] = applicativeParAff;
  exports["parallelAff"] = parallelAff;
  exports["semigroupCanceler"] = semigroupCanceler;
  exports["monoidCanceler"] = monoidCanceler;
  exports["makeAff"] = $foreign.makeAff;
})(PS);

(function (exports) {
  "use strict";

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Exception"] = $PS["Effect.Exception"] || {};
  var exports = $PS["Effect.Exception"];
  var $foreign = $PS["Effect.Exception"];

  var $$throw = function $$throw($1) {
    return $foreign.throwException($foreign.error($1));
  };

  exports["throw"] = $$throw;
  exports["error"] = $foreign.error;
  exports["throwException"] = $foreign.throwException;
})(PS);

(function (exports) {
  "use strict";

  exports.unsafeToForeign = function (value) {
    return value;
  };

  exports.unsafeFromForeign = function (value) {
    return value;
  };

  exports.tagOf = function (value) {
    return Object.prototype.toString.call(value).slice(8, -1);
  };
})(PS["Foreign"] = PS["Foreign"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.List.NonEmpty"] = $PS["Data.List.NonEmpty"] || {};
  var exports = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];

  var singleton = function singleton($165) {
    return Data_List_Types.NonEmptyList(Data_NonEmpty.singleton(Data_List_Types.plusList)($165));
  };

  exports["singleton"] = singleton;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Foreign"] = $PS["Foreign"] || {};
  var exports = $PS["Foreign"];
  var $foreign = $PS["Foreign"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_Show = $PS["Data.Show"];

  var ForeignError = function () {
    function ForeignError(value0) {
      this.value0 = value0;
    }

    ;

    ForeignError.create = function (value0) {
      return new ForeignError(value0);
    };

    return ForeignError;
  }();

  var TypeMismatch = function () {
    function TypeMismatch(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    TypeMismatch.create = function (value0) {
      return function (value1) {
        return new TypeMismatch(value0, value1);
      };
    };

    return TypeMismatch;
  }();

  var ErrorAtIndex = function () {
    function ErrorAtIndex(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    ErrorAtIndex.create = function (value0) {
      return function (value1) {
        return new ErrorAtIndex(value0, value1);
      };
    };

    return ErrorAtIndex;
  }();

  var ErrorAtProperty = function () {
    function ErrorAtProperty(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    ErrorAtProperty.create = function (value0) {
      return function (value1) {
        return new ErrorAtProperty(value0, value1);
      };
    };

    return ErrorAtProperty;
  }();

  var showForeignError = new Data_Show.Show(function (v) {
    if (v instanceof ForeignError) {
      return "(ForeignError " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
    }

    ;

    if (v instanceof ErrorAtIndex) {
      return "(ErrorAtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
    }

    ;

    if (v instanceof ErrorAtProperty) {
      return "(ErrorAtProperty " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
    }

    ;

    if (v instanceof TypeMismatch) {
      return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Data_Show.showString)(v.value1) + ")")));
    }

    ;
    throw new Error("Failed pattern match at Foreign (line 63, column 1 - line 63, column 47): " + [v.constructor.name]);
  });

  var fail = function fail($107) {
    return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(Data_List_NonEmpty.singleton($107));
  };

  var unsafeReadTagged = function unsafeReadTagged(tag) {
    return function (value) {
      if ($foreign.tagOf(value) === tag) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))($foreign.unsafeFromForeign(value));
      }

      ;

      if (Data_Boolean.otherwise) {
        return fail(new TypeMismatch(tag, $foreign.tagOf(value)));
      }

      ;
      throw new Error("Failed pattern match at Foreign (line 106, column 1 - line 106, column 55): " + [tag.constructor.name, value.constructor.name]);
    };
  };

  var readString = unsafeReadTagged("String");
  exports["ForeignError"] = ForeignError;
  exports["TypeMismatch"] = TypeMismatch;
  exports["ErrorAtIndex"] = ErrorAtIndex;
  exports["ErrorAtProperty"] = ErrorAtProperty;
  exports["unsafeReadTagged"] = unsafeReadTagged;
  exports["readString"] = readString;
  exports["fail"] = fail;
  exports["showForeignError"] = showForeignError;
  exports["unsafeToForeign"] = $foreign.unsafeToForeign;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Control.Promise"] = $PS["Control.Promise"] || {};
  var exports = $PS["Control.Promise"];
  var $foreign = $PS["Control.Promise"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Foreign = $PS["Foreign"];

  var toAff$prime = function toAff$prime(customCoerce) {
    return function (p) {
      return Effect_Aff.makeAff(function (cb) {
        return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(Effect_Aff.monoidCanceler))($foreign.thenImpl(p)(function ($1) {
          return cb(Data_Either.Left.create(customCoerce($1)))();
        })(function ($2) {
          return cb(Data_Either.Right.create($2))();
        }));
      });
    };
  };

  var coerce = function coerce(fn) {
    return Data_Either.either(function (v) {
      return Effect_Exception.error("Promise failed, couldn't extract JS Error or String");
    })(Control_Category.identity(Control_Category.categoryFn))(Control_Monad_Except.runExcept(Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Foreign.unsafeReadTagged("Error")(fn))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Effect_Exception.error)(Foreign.readString(fn)))));
  };

  var toAff = toAff$prime(coerce);

  var toAffE = function toAffE(f) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(toAff);
  };

  exports["toAff"] = toAff;
  exports["toAffE"] = toAffE;
})(PS);

(function (exports) {
  "use strict";

  function id(x) {
    return x;
  }

  exports.fromNumber = id;
  exports.fromString = id;
  exports.fromObject = id;

  exports.stringify = function (j) {
    return JSON.stringify(j);
  };

  var objToString = Object.prototype.toString;

  function isArray(a) {
    return objToString.call(a) === "[object Array]";
  }

  exports._caseJson = function (isNull, isBool, isNum, isStr, isArr, isObj, j) {
    if (j == null) return isNull();else if (typeof j === "boolean") return isBool(j);else if (typeof j === "number") return isNum(j);else if (typeof j === "string") return isStr(j);else if (objToString.call(j) === "[object Array]") return isArr(j);else return isObj(j);
  };
})(PS["Data.Argonaut.Core"] = PS["Data.Argonaut.Core"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Argonaut.Core"] = $PS["Data.Argonaut.Core"] || {};
  var exports = $PS["Data.Argonaut.Core"];
  var $foreign = $PS["Data.Argonaut.Core"];
  var Data_Function = $PS["Data.Function"];

  var verbJsonType = function verbJsonType(def) {
    return function (f) {
      return function (g) {
        return g(def)(f);
      };
    };
  };

  var isJsonType = verbJsonType(false)(Data_Function["const"](true));

  var caseJsonNull = function caseJsonNull(d) {
    return function (f) {
      return function (j) {
        return $foreign["_caseJson"](f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
      };
    };
  };

  var isNull = isJsonType(caseJsonNull);

  var caseJson = function caseJson(a) {
    return function (b) {
      return function (c) {
        return function (d) {
          return function (e) {
            return function (f) {
              return function (json) {
                return $foreign["_caseJson"](a, b, c, d, e, f, json);
              };
            };
          };
        };
      };
    };
  };

  exports["caseJson"] = caseJson;
  exports["caseJsonNull"] = caseJsonNull;
  exports["isNull"] = isNull;
  exports["fromNumber"] = $foreign.fromNumber;
  exports["fromString"] = $foreign.fromString;
  exports["fromObject"] = $foreign.fromObject;
  exports["stringify"] = $foreign.stringify;
})(PS);

(function (exports) {
  "use strict"; //------------------------------------------------------------------------------
  // Array creation --------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.range = function (start) {
    return function (end) {
      var step = start > end ? -1 : 1;
      var result = new Array(step * (end - start) + 1);
      var i = start,
          n = 0;

      while (i !== end) {
        result[n++] = i;
        i += step;
      }

      result[n] = i;
      return result;
    };
  }; //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------


  exports.length = function (xs) {
    return xs.length;
  };

  exports.snoc = function (l) {
    return function (e) {
      var l1 = l.slice();
      l1.push(e);
      return l1;
    };
  }; //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------


  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing : just(xs[i]);
        };
      };
    };
  };

  exports.findIndexImpl = function (just) {
    return function (nothing) {
      return function (f) {
        return function (xs) {
          for (var i = 0, l = xs.length; i < l; i++) {
            if (f(xs[i])) return just(i);
          }

          return nothing;
        };
      };
    };
  };

  exports._deleteAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (l) {
          if (i < 0 || i >= l.length) return nothing;
          var l1 = l.slice();
          l1.splice(i, 1);
          return just(l1);
        };
      };
    };
  };

  exports._updateAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (a) {
          return function (l) {
            if (i < 0 || i >= l.length) return nothing;
            var l1 = l.slice();
            l1[i] = a;
            return just(l1);
          };
        };
      };
    };
  }; //------------------------------------------------------------------------------
  // Subarrays -------------------------------------------------------------------
  //------------------------------------------------------------------------------


  exports.slice = function (s) {
    return function (e) {
      return function (l) {
        return l.slice(s, e);
      };
    };
  };

  exports.take = function (n) {
    return function (l) {
      return n < 1 ? [] : l.slice(0, n);
    };
  }; //------------------------------------------------------------------------------
  // Zipping ---------------------------------------------------------------------
  //------------------------------------------------------------------------------


  exports.zipWith = function (f) {
    return function (xs) {
      return function (ys) {
        var l = xs.length < ys.length ? xs.length : ys.length;
        var result = new Array(l);

        for (var i = 0; i < l; i++) {
          result[i] = f(xs[i])(ys[i]);
        }

        return result;
      };
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Array"] = $PS["Data.Array"] || {};
  var exports = $PS["Data.Array"];
  var $foreign = $PS["Data.Array"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var updateAt = $foreign["_updateAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

  var singleton = function singleton(a) {
    return [a];
  };

  var $$null = function $$null(xs) {
    return $foreign.length(xs) === 0;
  };

  var mapWithIndex = function mapWithIndex(f) {
    return function (xs) {
      return $foreign.zipWith(f)($foreign.range(0)($foreign.length(xs) - 1 | 0))(xs);
    };
  };

  var init = function init(xs) {
    if ($$null(xs)) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (Data_Boolean.otherwise) {
      return new Data_Maybe.Just($foreign.slice(0)($foreign.length(xs) - 1 | 0)(xs));
    }

    ;
    throw new Error("Failed pattern match at Data.Array (line 323, column 1 - line 323, column 45): " + [xs.constructor.name]);
  };

  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

  var last = function last(xs) {
    return index(xs)($foreign.length(xs) - 1 | 0);
  };

  var unsnoc = function unsnoc(xs) {
    return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
      return function (v1) {
        return {
          init: v,
          last: v1
        };
      };
    })(init(xs)))(last(xs));
  };

  var findIndex = $foreign.findIndexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var deleteAt = $foreign["_deleteAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  exports["singleton"] = singleton;
  exports["last"] = last;
  exports["init"] = init;
  exports["unsnoc"] = unsnoc;
  exports["index"] = index;
  exports["findIndex"] = findIndex;
  exports["deleteAt"] = deleteAt;
  exports["updateAt"] = updateAt;
  exports["mapWithIndex"] = mapWithIndex;
  exports["range"] = $foreign.range;
  exports["length"] = $foreign.length;
  exports["snoc"] = $foreign.snoc;
  exports["take"] = $foreign.take;
})(PS);

(function (exports) {
  "use strict";

  exports.topInt = 2147483647;
  exports.bottomInt = -2147483648;
  exports.topChar = String.fromCharCode(65535);
  exports.bottomChar = String.fromCharCode(0);
})(PS["Data.Bounded"] = PS["Data.Bounded"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Bounded"] = $PS["Data.Bounded"] || {};
  var exports = $PS["Data.Bounded"];
  var $foreign = $PS["Data.Bounded"];
  var Data_Ord = $PS["Data.Ord"];

  var Bounded = function Bounded(Ord0, bottom, top) {
    this.Ord0 = Ord0;
    this.bottom = bottom;
    this.top = top;
  };

  var top = function top(dict) {
    return dict.top;
  };

  var boundedInt = new Bounded(function () {
    return Data_Ord.ordInt;
  }, $foreign.bottomInt, $foreign.topInt);
  var boundedChar = new Bounded(function () {
    return Data_Ord.ordChar;
  }, $foreign.bottomChar, $foreign.topChar);

  var bottom = function bottom(dict) {
    return dict.bottom;
  };

  exports["Bounded"] = Bounded;
  exports["bottom"] = bottom;
  exports["top"] = top;
  exports["boundedInt"] = boundedInt;
  exports["boundedChar"] = boundedChar;
})(PS);

(function (exports) {
  "use strict";

  exports.toCharCode = function (c) {
    return c.charCodeAt(0);
  };

  exports.fromCharCode = function (c) {
    return String.fromCharCode(c);
  };
})(PS["Data.Enum"] = PS["Data.Enum"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Enum"] = $PS["Data.Enum"] || {};
  var exports = $PS["Data.Enum"];
  var $foreign = $PS["Data.Enum"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];

  var Enum = function Enum(Ord0, pred, succ) {
    this.Ord0 = Ord0;
    this.pred = pred;
    this.succ = succ;
  };

  var BoundedEnum = function BoundedEnum(Bounded0, Enum1, cardinality, fromEnum, toEnum) {
    this.Bounded0 = Bounded0;
    this.Enum1 = Enum1;
    this.cardinality = cardinality;
    this.fromEnum = fromEnum;
    this.toEnum = toEnum;
  };

  var toEnum = function toEnum(dict) {
    return dict.toEnum;
  };

  var succ = function succ(dict) {
    return dict.succ;
  };

  var pred = function pred(dict) {
    return dict.pred;
  };

  var fromEnum = function fromEnum(dict) {
    return dict.fromEnum;
  };

  var toEnumWithDefaults = function toEnumWithDefaults(dictBoundedEnum) {
    return function (low) {
      return function (high) {
        return function (x) {
          var v = toEnum(dictBoundedEnum)(x);

          if (v instanceof Data_Maybe.Just) {
            return v.value0;
          }

          ;

          if (v instanceof Data_Maybe.Nothing) {
            var $54 = x < fromEnum(dictBoundedEnum)(Data_Bounded.bottom(dictBoundedEnum.Bounded0()));

            if ($54) {
              return low;
            }

            ;
            return high;
          }

          ;
          throw new Error("Failed pattern match at Data.Enum (line 158, column 33 - line 160, column 62): " + [v.constructor.name]);
        };
      };
    };
  };

  var defaultSucc = function defaultSucc(toEnum$prime) {
    return function (fromEnum$prime) {
      return function (a) {
        return toEnum$prime(fromEnum$prime(a) + 1 | 0);
      };
    };
  };

  var defaultPred = function defaultPred(toEnum$prime) {
    return function (fromEnum$prime) {
      return function (a) {
        return toEnum$prime(fromEnum$prime(a) - 1 | 0);
      };
    };
  };

  var charToEnum = function charToEnum(v) {
    if (v >= Data_Bounded.bottom(Data_Bounded.boundedInt) && v <= Data_Bounded.top(Data_Bounded.boundedInt)) {
      return new Data_Maybe.Just($foreign.fromCharCode(v));
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var enumChar = new Enum(function () {
    return Data_Ord.ordChar;
  }, defaultPred(charToEnum)($foreign.toCharCode), defaultSucc(charToEnum)($foreign.toCharCode));

  var cardinality = function cardinality(dict) {
    return dict.cardinality;
  };

  var boundedEnumChar = new BoundedEnum(function () {
    return Data_Bounded.boundedChar;
  }, function () {
    return enumChar;
  }, $foreign.toCharCode(Data_Bounded.top(Data_Bounded.boundedChar)) - $foreign.toCharCode(Data_Bounded.bottom(Data_Bounded.boundedChar)) | 0, $foreign.toCharCode, charToEnum);
  exports["Enum"] = Enum;
  exports["succ"] = succ;
  exports["pred"] = pred;
  exports["BoundedEnum"] = BoundedEnum;
  exports["cardinality"] = cardinality;
  exports["toEnum"] = toEnum;
  exports["fromEnum"] = fromEnum;
  exports["toEnumWithDefaults"] = toEnumWithDefaults;
  exports["defaultSucc"] = defaultSucc;
  exports["defaultPred"] = defaultPred;
  exports["enumChar"] = enumChar;
  exports["boundedEnumChar"] = boundedEnumChar;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Char"] = $PS["Data.Char"] || {};
  var exports = $PS["Data.Char"];
  var Data_Enum = $PS["Data.Enum"];
  var toCharCode = Data_Enum.fromEnum(Data_Enum.boundedEnumChar);
  var fromCharCode = Data_Enum.toEnum(Data_Enum.boundedEnumChar);
  exports["toCharCode"] = toCharCode;
  exports["fromCharCode"] = fromCharCode;
})(PS);

(function (exports) {
  "use strict";

  var createDate = function createDate(y, m, d) {
    var date = new Date(Date.UTC(y, m, d));

    if (y >= 0 && y < 100) {
      date.setUTCFullYear(y);
    }

    return date;
  };

  exports.canonicalDateImpl = function (ctor, y, m, d) {
    var date = createDate(y, m - 1, d);
    return ctor(date.getUTCFullYear())(date.getUTCMonth() + 1)(date.getUTCDate());
  };
})(PS["Data.Date"] = PS["Data.Date"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Date.Component"] = $PS["Data.Date.Component"] || {};
  var exports = $PS["Data.Date.Component"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];

  var January = function () {
    function January() {}

    ;
    January.value = new January();
    return January;
  }();

  var February = function () {
    function February() {}

    ;
    February.value = new February();
    return February;
  }();

  var March = function () {
    function March() {}

    ;
    March.value = new March();
    return March;
  }();

  var April = function () {
    function April() {}

    ;
    April.value = new April();
    return April;
  }();

  var May = function () {
    function May() {}

    ;
    May.value = new May();
    return May;
  }();

  var June = function () {
    function June() {}

    ;
    June.value = new June();
    return June;
  }();

  var July = function () {
    function July() {}

    ;
    July.value = new July();
    return July;
  }();

  var August = function () {
    function August() {}

    ;
    August.value = new August();
    return August;
  }();

  var September = function () {
    function September() {}

    ;
    September.value = new September();
    return September;
  }();

  var October = function () {
    function October() {}

    ;
    October.value = new October();
    return October;
  }();

  var November = function () {
    function November() {}

    ;
    November.value = new November();
    return November;
  }();

  var December = function () {
    function December() {}

    ;
    December.value = new December();
    return December;
  }();

  var ordYear = Data_Ord.ordInt;
  var ordDay = Data_Ord.ordInt;
  var eqMonth = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof January && y instanceof January) {
        return true;
      }

      ;

      if (x instanceof February && y instanceof February) {
        return true;
      }

      ;

      if (x instanceof March && y instanceof March) {
        return true;
      }

      ;

      if (x instanceof April && y instanceof April) {
        return true;
      }

      ;

      if (x instanceof May && y instanceof May) {
        return true;
      }

      ;

      if (x instanceof June && y instanceof June) {
        return true;
      }

      ;

      if (x instanceof July && y instanceof July) {
        return true;
      }

      ;

      if (x instanceof August && y instanceof August) {
        return true;
      }

      ;

      if (x instanceof September && y instanceof September) {
        return true;
      }

      ;

      if (x instanceof October && y instanceof October) {
        return true;
      }

      ;

      if (x instanceof November && y instanceof November) {
        return true;
      }

      ;

      if (x instanceof December && y instanceof December) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordMonth = new Data_Ord.Ord(function () {
    return eqMonth;
  }, function (x) {
    return function (y) {
      if (x instanceof January && y instanceof January) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof January) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof January) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof February && y instanceof February) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof February) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof February) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof March && y instanceof March) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof March) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof March) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof April && y instanceof April) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof April) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof April) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof May && y instanceof May) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof May) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof May) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof June && y instanceof June) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof June) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof June) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof July && y instanceof July) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof July) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof July) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof August && y instanceof August) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof August) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof August) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof September && y instanceof September) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof September) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof September) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof October && y instanceof October) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof October) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof October) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof November && y instanceof November) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof November) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof November) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof December && y instanceof December) {
        return Data_Ordering.EQ.value;
      }

      ;
      throw new Error("Failed pattern match at Data.Date.Component (line 61, column 8 - line 61, column 38): " + [x.constructor.name, y.constructor.name]);
    };
  });
  var boundedYear = new Data_Bounded.Bounded(function () {
    return ordYear;
  }, -271820 | 0, 275759);
  var boundedMonth = new Data_Bounded.Bounded(function () {
    return ordMonth;
  }, January.value, December.value);
  var boundedEnumYear = new Data_Enum.BoundedEnum(function () {
    return boundedYear;
  }, function () {
    return enumYear;
  }, 547580, function (v) {
    return v;
  }, function (n) {
    if (n >= (-271820 | 0) && n <= 275759) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 35, column 1 - line 35, column 45): " + [n.constructor.name]);
  });
  var enumYear = new Data_Enum.Enum(function () {
    return ordYear;
  }, function ($46) {
    return Data_Enum.toEnum(boundedEnumYear)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumYear)($46)));
  }, function ($47) {
    return Data_Enum.toEnum(boundedEnumYear)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumYear)($47)));
  });
  var boundedEnumMonth = new Data_Enum.BoundedEnum(function () {
    return boundedMonth;
  }, function () {
    return enumMonth;
  }, 12, function (v) {
    if (v instanceof January) {
      return 1;
    }

    ;

    if (v instanceof February) {
      return 2;
    }

    ;

    if (v instanceof March) {
      return 3;
    }

    ;

    if (v instanceof April) {
      return 4;
    }

    ;

    if (v instanceof May) {
      return 5;
    }

    ;

    if (v instanceof June) {
      return 6;
    }

    ;

    if (v instanceof July) {
      return 7;
    }

    ;

    if (v instanceof August) {
      return 8;
    }

    ;

    if (v instanceof September) {
      return 9;
    }

    ;

    if (v instanceof October) {
      return 10;
    }

    ;

    if (v instanceof November) {
      return 11;
    }

    ;

    if (v instanceof December) {
      return 12;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 87, column 14 - line 101, column 1): " + [v.constructor.name]);
  }, function (v) {
    if (v === 1) {
      return new Data_Maybe.Just(January.value);
    }

    ;

    if (v === 2) {
      return new Data_Maybe.Just(February.value);
    }

    ;

    if (v === 3) {
      return new Data_Maybe.Just(March.value);
    }

    ;

    if (v === 4) {
      return new Data_Maybe.Just(April.value);
    }

    ;

    if (v === 5) {
      return new Data_Maybe.Just(May.value);
    }

    ;

    if (v === 6) {
      return new Data_Maybe.Just(June.value);
    }

    ;

    if (v === 7) {
      return new Data_Maybe.Just(July.value);
    }

    ;

    if (v === 8) {
      return new Data_Maybe.Just(August.value);
    }

    ;

    if (v === 9) {
      return new Data_Maybe.Just(September.value);
    }

    ;

    if (v === 10) {
      return new Data_Maybe.Just(October.value);
    }

    ;

    if (v === 11) {
      return new Data_Maybe.Just(November.value);
    }

    ;

    if (v === 12) {
      return new Data_Maybe.Just(December.value);
    }

    ;
    return Data_Maybe.Nothing.value;
  });
  var enumMonth = new Data_Enum.Enum(function () {
    return ordMonth;
  }, function ($50) {
    return Data_Enum.toEnum(boundedEnumMonth)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMonth)($50)));
  }, function ($51) {
    return Data_Enum.toEnum(boundedEnumMonth)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMonth)($51)));
  });
  var boundedDay = new Data_Bounded.Bounded(function () {
    return ordDay;
  }, 1, 31);
  var boundedEnumDay = new Data_Enum.BoundedEnum(function () {
    return boundedDay;
  }, function () {
    return enumDay;
  }, 31, function (v) {
    return v;
  }, function (n) {
    if (n >= 1 && n <= 31) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Date.Component (line 133, column 1 - line 133, column 43): " + [n.constructor.name]);
  });
  var enumDay = new Data_Enum.Enum(function () {
    return ordDay;
  }, function ($52) {
    return Data_Enum.toEnum(boundedEnumDay)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumDay)($52)));
  }, function ($53) {
    return Data_Enum.toEnum(boundedEnumDay)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumDay)($53)));
  });
  exports["January"] = January;
  exports["February"] = February;
  exports["March"] = March;
  exports["April"] = April;
  exports["May"] = May;
  exports["June"] = June;
  exports["July"] = July;
  exports["August"] = August;
  exports["September"] = September;
  exports["October"] = October;
  exports["November"] = November;
  exports["December"] = December;
  exports["ordYear"] = ordYear;
  exports["boundedYear"] = boundedYear;
  exports["enumYear"] = enumYear;
  exports["boundedEnumYear"] = boundedEnumYear;
  exports["eqMonth"] = eqMonth;
  exports["ordMonth"] = ordMonth;
  exports["boundedMonth"] = boundedMonth;
  exports["enumMonth"] = enumMonth;
  exports["boundedEnumMonth"] = boundedEnumMonth;
  exports["ordDay"] = ordDay;
  exports["boundedDay"] = boundedDay;
  exports["enumDay"] = enumDay;
  exports["boundedEnumDay"] = boundedEnumDay;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Date"] = $PS["Data.Date"] || {};
  var exports = $PS["Data.Date"];
  var $foreign = $PS["Data.Date"];
  var Data_Date_Component = $PS["Data.Date.Component"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_Maybe = $PS["Data.Maybe"];

  var $$Date = function () {
    function $$Date(value0, value1, value2) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
    }

    ;

    $$Date.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return new $$Date(value0, value1, value2);
        };
      };
    };

    return $$Date;
  }();

  var year = function year(v) {
    return v.value0;
  };

  var month = function month(v) {
    return v.value1;
  };

  var day = function day(v) {
    return v.value2;
  };

  var canonicalDate = function canonicalDate(y) {
    return function (m) {
      return function (d) {
        var mkDate = function mkDate(y$prime) {
          return function (m$prime) {
            return function (d$prime) {
              return new $$Date(y$prime, Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth)(m$prime)), d$prime);
            };
          };
        };

        return $foreign.canonicalDateImpl(mkDate, y, Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(m), d);
      };
    };
  };

  exports["canonicalDate"] = canonicalDate;
  exports["year"] = year;
  exports["month"] = month;
  exports["day"] = day;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.DateTime"] = $PS["Data.DateTime"] || {};
  var exports = $PS["Data.DateTime"];

  var DateTime = function () {
    function DateTime(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    DateTime.create = function (value0) {
      return function (value1) {
        return new DateTime(value0, value1);
      };
    };

    return DateTime;
  }();

  var time = function time(v) {
    return v.value1;
  };

  var date = function date(v) {
    return v.value0;
  };

  exports["DateTime"] = DateTime;
  exports["date"] = date;
  exports["time"] = time;
})(PS);

(function (exports) {
  "use strict";

  exports.toDateTimeImpl = function (ctor) {
    return function (instant) {
      var dt = new Date(instant);
      return ctor(dt.getUTCFullYear())(dt.getUTCMonth() + 1)(dt.getUTCDate())(dt.getUTCHours())(dt.getUTCMinutes())(dt.getUTCSeconds())(dt.getUTCMilliseconds());
    };
  };
})(PS["Data.DateTime.Instant"] = PS["Data.DateTime.Instant"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Time"] = $PS["Data.Time"] || {};
  var exports = $PS["Data.Time"];

  var Time = function () {
    function Time(value0, value1, value2, value3) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
      this.value3 = value3;
    }

    ;

    Time.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return function (value3) {
            return new Time(value0, value1, value2, value3);
          };
        };
      };
    };

    return Time;
  }();

  var second = function second(v) {
    return v.value2;
  };

  var minute = function minute(v) {
    return v.value1;
  };

  var millisecond = function millisecond(v) {
    return v.value3;
  };

  var hour = function hour(v) {
    return v.value0;
  };

  exports["Time"] = Time;
  exports["hour"] = hour;
  exports["minute"] = minute;
  exports["second"] = second;
  exports["millisecond"] = millisecond;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.DateTime.Instant"] = $PS["Data.DateTime.Instant"] || {};
  var exports = $PS["Data.DateTime.Instant"];
  var $foreign = $PS["Data.DateTime.Instant"];
  var Data_Date = $PS["Data.Date"];
  var Data_Date_Component = $PS["Data.Date.Component"];
  var Data_DateTime = $PS["Data.DateTime"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Time = $PS["Data.Time"];

  var toDateTime = function () {
    var mkDateTime = function mkDateTime(y) {
      return function (mo) {
        return function (d) {
          return function (h) {
            return function (mi) {
              return function (s) {
                return function (ms) {
                  return new Data_DateTime.DateTime(Data_Date.canonicalDate(y)(Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth)(mo)))(d), new Data_Time.Time(h, mi, s, ms));
                };
              };
            };
          };
        };
      };
    };

    return $foreign.toDateTimeImpl(mkDateTime);
  }();

  exports["toDateTime"] = toDateTime;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Digit"] = $PS["Data.Digit"] || {};
  var exports = $PS["Data.Digit"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Char = $PS["Data.Char"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];

  var Digit = function Digit(x) {
    return x;
  };

  var toInt = function toInt(v) {
    return v;
  };

  var toChar = function toChar(v) {
    return Data_Maybe.fromJust()(Data_Char.fromCharCode(v + 48 | 0));
  };

  var fromInt = function fromInt(c) {
    if (0 <= c && c <= 9) {
      return new Data_Maybe.Just(c);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Digit (line 27, column 1 - line 27, column 30): " + [c.constructor.name]);
  };

  var fromChar = function fromChar(c) {
    var code = Data_Char.toCharCode(c);
    var $16 = code >= 48 && code <= 57;

    if ($16) {
      return new Data_Maybe.Just(Digit(code - 48 | 0));
    }

    ;
    return Data_Maybe.Nothing.value;
  };

  var eqDigit = new Data_Eq.Eq(function (v) {
    return function (v1) {
      return v === v1;
    };
  });
  var ordDigit = new Data_Ord.Ord(function () {
    return eqDigit;
  }, function (v) {
    return function (v1) {
      return Data_Ord.compare(Data_Ord.ordInt)(v)(v1);
    };
  });
  var _zero = 0;
  var _one = 1;
  exports["fromInt"] = fromInt;
  exports["toInt"] = toInt;
  exports["fromChar"] = fromChar;
  exports["toChar"] = toChar;
  exports["_zero"] = _zero;
  exports["_one"] = _one;
  exports["eqDigit"] = eqDigit;
  exports["ordDigit"] = ordDigit;
})(PS);

(function (exports) {
  "use strict";

  exports.runFn2 = function (fn) {
    return function (a) {
      return function (b) {
        return fn(a, b);
      };
    };
  };

  exports.runFn4 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return fn(a, b, c, d);
          };
        };
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Function.Uncurried"] = $PS["Data.Function.Uncurried"] || {};
  var exports = $PS["Data.Function.Uncurried"];
  var $foreign = $PS["Data.Function.Uncurried"];
  exports["runFn2"] = $foreign.runFn2;
  exports["runFn4"] = $foreign.runFn4;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Generic.Rep"] = $PS["Data.Generic.Rep"] || {};
  var exports = $PS["Data.Generic.Rep"];

  var Inl = function () {
    function Inl(value0) {
      this.value0 = value0;
    }

    ;

    Inl.create = function (value0) {
      return new Inl(value0);
    };

    return Inl;
  }();

  var Inr = function () {
    function Inr(value0) {
      this.value0 = value0;
    }

    ;

    Inr.create = function (value0) {
      return new Inr(value0);
    };

    return Inr;
  }();

  var NoArguments = function () {
    function NoArguments() {}

    ;
    NoArguments.value = new NoArguments();
    return NoArguments;
  }();

  var Constructor = function Constructor(x) {
    return x;
  };

  var Argument = function Argument(x) {
    return x;
  };

  var Generic = function Generic(from, to) {
    this.from = from;
    this.to = to;
  };

  var to = function to(dict) {
    return dict.to;
  };

  var from = function from(dict) {
    return dict.from;
  };

  exports["Generic"] = Generic;
  exports["to"] = to;
  exports["from"] = from;
  exports["NoArguments"] = NoArguments;
  exports["Inl"] = Inl;
  exports["Inr"] = Inr;
  exports["Constructor"] = Constructor;
  exports["Argument"] = Argument;
})(PS);

(function (exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };

  exports.fromStringAsImpl = function (just) {
    return function (nothing) {
      return function (radix) {
        var digits;

        if (radix < 11) {
          digits = "[0-" + (radix - 1).toString() + "]";
        } else if (radix === 11) {
          digits = "[0-9a]";
        } else {
          digits = "[0-9a-" + String.fromCharCode(86 + radix) + "]";
        }

        var pattern = new RegExp("^[\\+\\-]?" + digits + "+$", "i");
        return function (s) {
          /* jshint bitwise: false */
          if (pattern.test(s)) {
            var i = parseInt(s, radix);
            return (i | 0) === i ? just(i) : nothing;
          } else {
            return nothing;
          }
        };
      };
    };
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});

(function (exports) {
  /* globals exports */
  "use strict";

  exports.infinity = Infinity;
  exports.readFloat = parseFloat;
})(PS["Global"] = PS["Global"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Global"] = $PS["Global"] || {};
  var exports = $PS["Global"];
  var $foreign = $PS["Global"];
  exports["infinity"] = $foreign.infinity;
  exports["readFloat"] = $foreign.readFloat;
})(PS);

(function (exports) {
  "use strict";

  exports.round = Math.round;
})(PS["Math"] = PS["Math"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Math"] = $PS["Math"] || {};
  var exports = $PS["Math"];
  var $foreign = $PS["Math"];
  exports["round"] = $foreign.round;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Int"] = $PS["Data.Int"] || {};
  var exports = $PS["Data.Int"];
  var $foreign = $PS["Data.Int"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Global = $PS["Global"];
  var $$Math = $PS["Math"];
  var fromStringAs = $foreign.fromStringAsImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var fromString = fromStringAs(10);
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

  var unsafeClamp = function unsafeClamp(x) {
    if (x === Global.infinity) {
      return 0;
    }

    ;

    if (x === -Global.infinity) {
      return 0;
    }

    ;

    if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
      return Data_Bounded.top(Data_Bounded.boundedInt);
    }

    ;

    if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
      return Data_Bounded.bottom(Data_Bounded.boundedInt);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.fromMaybe(0)(fromNumber(x));
    }

    ;
    throw new Error("Failed pattern match at Data.Int (line 66, column 1 - line 66, column 29): " + [x.constructor.name]);
  };

  var round = function round($23) {
    return unsafeClamp($$Math.round($23));
  };

  exports["fromNumber"] = fromNumber;
  exports["round"] = round;
  exports["fromString"] = fromString;
  exports["fromStringAs"] = fromStringAs;
  exports["toNumber"] = $foreign.toNumber;
})(PS);

(function (exports) {
  "use strict";

  exports.toCharArray = function (s) {
    return s.split("");
  };

  exports.singleton = function (c) {
    return c;
  };

  exports.length = function (s) {
    return s.length;
  };

  exports._indexOf = function (just) {
    return function (nothing) {
      return function (x) {
        return function (s) {
          var i = s.indexOf(x);
          return i === -1 ? nothing : just(i);
        };
      };
    };
  };

  exports.drop = function (n) {
    return function (s) {
      return s.substring(n);
    };
  };
})(PS["Data.String.CodeUnits"] = PS["Data.String.CodeUnits"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.String.CodeUnits"] = $PS["Data.String.CodeUnits"] || {};
  var exports = $PS["Data.String.CodeUnits"];
  var $foreign = $PS["Data.String.CodeUnits"];
  var Data_Maybe = $PS["Data.Maybe"];
  var indexOf = $foreign["_indexOf"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);

  var contains = function contains(pat) {
    return function ($16) {
      return Data_Maybe.isJust(indexOf(pat)($16));
    };
  };

  exports["contains"] = contains;
  exports["indexOf"] = indexOf;
  exports["singleton"] = $foreign.singleton;
  exports["toCharArray"] = $foreign.toCharArray;
  exports["length"] = $foreign.length;
  exports["drop"] = $foreign.drop;
})(PS);

(function (exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Unsafe"] = $PS["Effect.Unsafe"] || {};
  var exports = $PS["Effect.Unsafe"];
  var $foreign = $PS["Effect.Unsafe"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Exception.Unsafe"] = $PS["Effect.Exception.Unsafe"] || {};
  var exports = $PS["Effect.Exception.Unsafe"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Unsafe = $PS["Effect.Unsafe"];

  var unsafeThrowException = function unsafeThrowException($0) {
    return Effect_Unsafe.unsafePerformEffect(Effect_Exception.throwException($0));
  };

  var unsafeThrow = function unsafeThrow($1) {
    return unsafeThrowException(Effect_Exception.error($1));
  };

  exports["unsafeThrowException"] = unsafeThrowException;
  exports["unsafeThrow"] = unsafeThrow;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.HugeNum"] = $PS["Data.HugeNum"] || {};
  var exports = $PS["Data.HugeNum"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Digit = $PS["Data.Digit"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Int = $PS["Data.Int"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Ring = $PS["Data.Ring"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Effect_Exception_Unsafe = $PS["Effect.Exception.Unsafe"];
  var Global = $PS["Global"];

  var Plus = function () {
    function Plus() {}

    ;
    Plus.value = new Plus();
    return Plus;
  }();

  var Minus = function () {
    function Minus() {}

    ;
    Minus.value = new Minus();
    return Minus;
  }();

  var Float = function () {
    function Float() {}

    ;
    Float.value = new Float();
    return Float;
  }();

  var Integral = function () {
    function Integral() {}

    ;
    Integral.value = new Integral();
    return Integral;
  }();

  var Scientific = function () {
    function Scientific() {}

    ;
    Scientific.value = new Scientific();
    return Scientific;
  }();

  var zeroHugeNum = {
    digits: new Data_List_Types.Cons(Data_Digit["_zero"], new Data_List_Types.Cons(Data_Digit["_zero"], Data_List_Types.Nil.value)),
    decimal: 1,
    sign: Plus.value
  };
  var unsafeRemoveFrontZeroes = Data_List.dropWhile(function (v) {
    return Data_Eq.eq(Data_Digit.eqDigit)(v)(Data_Digit["_zero"]);
  });

  var trivialFraction = function trivialFraction(v) {
    var decimals = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
    var meaty = Data_List.dropWhile(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(decimals);
    return Data_List["null"](meaty);
  };

  var toString = function toString(v) {
    var sign = function () {
      if (v.sign instanceof Plus) {
        return Data_Monoid.mempty(Data_List_Types.monoidList);
      }

      ;

      if (v.sign instanceof Minus) {
        return Control_Applicative.pure(Data_List_Types.applicativeList)("-");
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 182, column 14 - line 184, column 49): " + [v.sign.constructor.name]);
    }();

    var charray = Data_Functor.map(Data_List_Types.functorList)(Data_Digit.toChar)(v.digits);
    var numray = Data_Maybe.fromJust()(Data_List.insertAt(v.decimal)(".")(charray));
    return Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(Data_String_CodeUnits.singleton)(Data_Semigroup.append(Data_List_Types.semigroupList)(sign)(numray));
  };

  var toNumber = function toNumber($215) {
    return Global.readFloat(toString($215));
  };

  var timesSign = function timesSign(v) {
    return function (v1) {
      if (v instanceof Plus && v1 instanceof Plus) {
        return Plus.value;
      }

      ;

      if (v instanceof Minus && v1 instanceof Minus) {
        return Plus.value;
      }

      ;
      return Minus.value;
    };
  };

  var takeMeatyParts = function takeMeatyParts(arr) {
    return Data_List.reverse(Data_List.dropWhile(function (v) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v)(Data_Digit["_zero"]);
    })(Data_List.reverse(arr)));
  };

  var timesPowOfTen = function timesPowOfTen(n) {
    return function (v) {
      var newDecimal = v.decimal + n | 0;

      var newZeroesLength = function () {
        var $99 = newDecimal >= Data_List.length(takeMeatyParts(v.digits));

        if ($99) {
          return newDecimal - Data_List.length(takeMeatyParts(v.digits)) | 0;
        }

        ;
        return 0;
      }();

      var newZeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(newZeroesLength)(Data_Digit["_zero"]);
      var z = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(v.digits)(newZeroes),
        sign: v.sign,
        decimal: newDecimal
      };
      return z;
    };
  };

  var smallEnough = function smallEnough(v) {
    if (v.decimal === 1 && Data_List.length(v.digits) === 2) {
      return true;
    }

    ;
    return false;
  };

  var rec = function rec(v) {
    return v;
  };

  var parseScientific = function parseScientific(n) {
    var split = Data_List.span(function (v) {
      return v !== "e";
    })(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n))));
    var signSplit = Data_Maybe.fromJust()(Data_List.uncons(Data_List.drop(1)(split.rest)));

    var sign = function () {
      var $104 = n < 0;

      if ($104) {
        return Minus.value;
      }

      ;
      return Plus.value;
    }();

    var exponent = Data_Int.round(Global.readFloat(Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(Data_String_CodeUnits.singleton)(signSplit.tail)));

    var expSign = function () {
      if (signSplit.head === "+") {
        return Plus.value;
      }

      ;
      return Minus.value;
    }();

    var base = Data_List.filter(function (v) {
      return v !== ".";
    })(split.init);
    var z = {
      exponent: exponent,
      expSign: expSign,
      base: base,
      sign: sign
    };
    return z;
  };

  var parsePlusPlus = function parsePlusPlus(exp) {
    return function (base) {
      var zeroCardinality = (exp - Data_List.length(base) | 0) + 2 | 0;
      var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(zeroCardinality)(Data_Digit["_zero"]);
      var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.mapMaybe(Data_Digit.fromChar)(base))(zeroes);
      var decimal = 1 + exp | 0;
      var r = {
        digits: digits,
        decimal: decimal,
        sign: Plus.value
      };
      return r;
    };
  };

  var parsePlusMinus = function parsePlusMinus(exp) {
    return function (base) {
      var r = parsePlusPlus(exp)(base);
      return {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(r.digits)(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
        decimal: r.decimal,
        sign: Minus.value
      };
    };
  };

  var parseNumber = function parseNumber(n) {
    if (Data_String_CodeUnits.contains("e")(Data_Show.show(Data_Show.showNumber)(n))) {
      return Scientific.value;
    }

    ;

    if (Data_String_CodeUnits.contains(".")(Data_Show.show(Data_Show.showNumber)(n))) {
      return Float.value;
    }

    ;

    if (Data_Boolean.otherwise) {
      return Integral.value;
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 212, column 1 - line 212, column 37): " + [n.constructor.name]);
  };

  var parseMinusPlus = function parseMinusPlus(exp) {
    return function (base) {
      var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(exp)(Data_Digit["_zero"]);
      var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(zeroes)(Data_List.mapMaybe(Data_Digit.fromChar)(base));
      var r = {
        digits: digits,
        decimal: 1,
        sign: Plus.value
      };
      return r;
    };
  };

  var parseMinusMinus = function parseMinusMinus(exp) {
    return function (base) {
      var r = parseMinusPlus(exp)(base);
      return {
        digits: r.digits,
        decimal: r.decimal,
        sign: Minus.value
      };
    };
  };

  var scientificToHugeNum = function scientificToHugeNum(n) {
    var parsed = parseScientific(n);

    var r = function () {
      if (parsed.expSign instanceof Plus) {
        if (parsed.sign instanceof Plus) {
          return parsePlusPlus(parsed.exponent)(parsed.base);
        }

        ;
        return parsePlusMinus(parsed.exponent)(parsed.base);
      }

      ;

      if (parsed.sign instanceof Plus) {
        return parseMinusPlus(parsed.exponent)(parsed.base);
      }

      ;
      return parseMinusMinus(parsed.exponent)(parsed.base);
    }();

    return r;
  };

  var oneHugeNum = {
    digits: new Data_List_Types.Cons(Data_Digit["_one"], new Data_List_Types.Cons(Data_Digit["_zero"], Data_List_Types.Nil.value)),
    decimal: 1,
    sign: Plus.value
  };

  var neg = function neg(v) {
    if (v.sign instanceof Minus) {
      return {
        digits: v.digits,
        decimal: v.decimal,
        sign: Plus.value
      };
    }

    ;
    return {
      digits: v.digits,
      decimal: v.decimal,
      sign: Minus.value
    };
  };

  var meatyDecimals = function meatyDecimals(v) {
    var decimals = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
    var meaty = Data_List.dropWhile(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(decimals);
    return Data_List.length(meaty);
  };

  var makeHugeInteger$prime = function makeHugeInteger$prime(v) {
    var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(unsafeRemoveFrontZeroes(v.digits))(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]));
    var decimal = Data_List.length(digits) - 1 | 0;
    var z = {
      digits: digits,
      decimal: decimal,
      sign: v.sign
    };
    return z;
  };

  var lessPrecise = function lessPrecise(v) {
    return function (v1) {
      return Data_List.length(Data_List.drop(v.decimal)(v.digits)) < Data_List.length(Data_List.drop(v1.decimal)(v1.digits));
    };
  };

  var isZero = function isZero(v) {
    return Data_Foldable.all(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(v.digits);
  };

  var isPositive = function isPositive(v) {
    if (v.sign instanceof Plus) {
      return true;
    }

    ;
    return false;
  };

  var isNegative = function isNegative(v) {
    if (v.sign instanceof Minus) {
      return true;
    }

    ;
    return false;
  };

  var isHugeInteger = function isHugeInteger(v) {
    return Data_Foldable.all(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (v1) {
      return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
    })(Data_List.drop(v.decimal)(v.digits));
  };

  var makeHugeInteger = function makeHugeInteger(r) {
    var $124 = isHugeInteger(r);

    if ($124) {
      return r;
    }

    ;
    return makeHugeInteger$prime(r);
  };

  var integralToHugeNum = function integralToHugeNum(n) {
    var sign = function () {
      var $125 = n < 0;

      if ($125) {
        return Minus.value;
      }

      ;
      return Plus.value;
    }();

    var integral = Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n)));
    var fractional = Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);

    var decimal = function () {
      if (sign instanceof Minus) {
        return Data_List.length(integral) - 1 | 0;
      }

      ;
      return Data_List.length(integral);
    }();

    return {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.mapMaybe(Data_Digit.fromChar)(integral))(fractional),
      decimal: decimal,
      sign: sign
    };
  };

  var integerPart = function integerPart(v) {
    return {
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.take(v.decimal)(v.digits))(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
      decimal: v.decimal,
      sign: v.sign
    };
  };

  var fromString = function fromString(s) {
    var charlist = Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(s));
    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.head(charlist))(function (v) {
      var signDigits = function () {
        if (v === "-") {
          return {
            sign: Minus.value,
            digits: Data_List.drop(1)(charlist)
          };
        }

        ;
        return {
          sign: Plus.value,
          digits: charlist
        };
      }();

      return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.elemIndex(Data_Eq.eqChar)(".")(signDigits.digits))(function (v1) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_List.deleteAt(v1)(signDigits.digits))(function (v2) {
          return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Traversable.sequence(Data_List_Types.traversableList)(Data_Maybe.applicativeMaybe)(Data_Functor.map(Data_List_Types.functorList)(Data_Digit.fromChar)(v2)))(function (v3) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
              sign: signDigits.sign,
              decimal: v1,
              digits: v3
            });
          });
        });
      });
    });
  };

  var floatToHugeNum = function floatToHugeNum(n) {
    var sign = function () {
      var $140 = n < 0;

      if ($140) {
        return Minus.value;
      }

      ;
      return Plus.value;
    }();

    var pos = n >= 0;

    var split = function () {
      if (pos) {
        return Data_List.span(function (v) {
          return v !== ".";
        })(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n))));
      }

      ;
      return Data_List.span(function (v) {
        return v !== ".";
      })(Data_List.drop(1)(Data_List.fromFoldable(Data_Foldable.foldableArray)(Data_String_CodeUnits.toCharArray(Data_Show.show(Data_Show.showNumber)(n)))));
    }();

    var small = Data_List.drop(1)(split.rest);
    var decimal = Data_List.length(split.init);
    var digits = Data_List.mapMaybe(Data_Digit.fromChar)(Data_Semigroup.append(Data_List_Types.semigroupList)(split.init)(small));
    var r = {
      digits: digits,
      decimal: decimal,
      sign: sign
    };
    return r;
  };

  var fromNumber = function fromNumber(n) {
    var v = parseNumber(n);

    if (v instanceof Float) {
      return floatToHugeNum(n);
    }

    ;

    if (v instanceof Scientific) {
      return scientificToHugeNum(n);
    }

    ;

    if (v instanceof Integral) {
      return integralToHugeNum(n);
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 291, column 16 - line 294, column 52): " + [v.constructor.name]);
  };

  var eqSign = new Data_Eq.Eq(function (v) {
    return function (v1) {
      if (v instanceof Plus && v1 instanceof Plus) {
        return true;
      }

      ;

      if (v instanceof Minus && v1 instanceof Minus) {
        return true;
      }

      ;
      return false;
    };
  });
  var ordSign = new Data_Ord.Ord(function () {
    return eqSign;
  }, function (v) {
    return function (v1) {
      if (v instanceof Plus && v1 instanceof Minus) {
        return Data_Ordering.GT.value;
      }

      ;

      if (v instanceof Minus && v1 instanceof Plus) {
        return Data_Ordering.LT.value;
      }

      ;
      return Data_Ordering.EQ.value;
    };
  });

  var strictlyEqual = function strictlyEqual(v) {
    return function (v1) {
      return v.decimal === v1.decimal && Data_Eq.eq(Data_List_Types.eqList(Data_Digit.eqDigit))(v.digits)(v1.digits) && Data_Eq.eq(eqSign)(v.sign)(v1.sign);
    };
  };

  var dropZeroes = function () {
    var dropIntegralZeroes = function dropIntegralZeroes(v) {
      var integralPart = Data_List.take(v.decimal)(v.digits);
      var zeroes = Data_List.takeWhile(function (v1) {
        return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
      })(integralPart);

      var digits = function () {
        var $150 = Data_List.length(integralPart) === Data_List.length(zeroes);

        if ($150) {
          return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
        }

        ;
        return Data_List.drop(Data_List.length(zeroes))(integralPart);
      }();

      var decimal = Data_List.length(digits);
      var z = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(digits)(Data_List.drop(v.decimal)(v.digits)),
        decimal: decimal,
        sign: v.sign
      };
      return z;
    };

    var dropFractionalZeroes = function dropFractionalZeroes(v) {
      var fractionalDigits = Data_List.reverse(Data_List.drop(v.decimal)(v.digits));
      var meatyFraction = Data_List.dropWhile(function (v1) {
        return Data_Eq.eq(Data_Digit.eqDigit)(v1)(Data_Digit["_zero"]);
      })(fractionalDigits);
      var digits = Data_List.reverse(meatyFraction);
      var z = {
        digits: Data_Semigroup.append(Data_List_Types.semigroupList)(Data_List.take(v.decimal)(v.digits))(function () {
          var $152 = Data_List["null"](digits);

          if ($152) {
            return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
          }

          ;
          return digits;
        }()),
        decimal: v.decimal,
        sign: v.sign
      };
      return z;
    };

    return function ($217) {
      return dropIntegralZeroes(dropFractionalZeroes($217));
    };
  }();

  var eqHugeNum = new Data_Eq.Eq(function (x) {
    return function (y) {
      if (isZero(x) && isZero(y)) {
        return true;
      }

      ;

      if (Data_Boolean.otherwise) {
        return strictlyEqual(dropZeroes(x))(dropZeroes(y));
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 83, column 1 - line 83, column 33): " + [x.constructor.name, y.constructor.name]);
    };
  });

  var digitwiseSubtract = function digitwiseSubtract(v) {
    return function (v1) {
      var tint = Data_Digit.toInt(v1.value0) - Data_Digit.toInt(v.value1) | 0;
      var bint = Data_Digit.toInt(v1.value1);
      var diff$prime = tint - bint | 0;
      var diff = Data_Maybe.fromJust()(Data_Digit.fromInt(function () {
        var $157 = diff$prime < 0;

        if ($157) {
          return diff$prime + 10 | 0;
        }

        ;
        return diff$prime;
      }()));

      var spill = function () {
        var $158 = diff$prime < 0;

        if ($158) {
          return Data_Digit["_one"];
        }

        ;
        return Data_Digit["_zero"];
      }();

      return new Data_Tuple.Tuple(new Data_List_Types.Cons(diff, v.value0), spill);
    };
  };

  var digitwiseAdd = function digitwiseAdd(v) {
    return function (v1) {
      var tint = Data_Digit.toInt(v1.value0) + Data_Digit.toInt(v.value1) | 0;
      var bint = Data_Digit.toInt(v1.value1);
      var summ$prime = tint + bint | 0;

      var spill = function () {
        var $165 = summ$prime > 9;

        if ($165) {
          return Data_Digit["_one"];
        }

        ;
        return Data_Digit["_zero"];
      }();

      var summ = Data_Maybe.fromJust()(Data_Digit.fromInt(function () {
        var $166 = summ$prime > 9;

        if ($166) {
          return summ$prime - 10 | 0;
        }

        ;
        return summ$prime;
      }()));
      return new Data_Tuple.Tuple(new Data_List_Types.Cons(summ, v.value0), spill);
    };
  };

  var equivalize = function () {
    var integralize = function integralize(v) {
      if (Data_List.length(Data_List.take(v.fst.decimal)(v.fst.digits)) === Data_List.length(Data_List.take(v.snd.decimal)(v.snd.digits))) {
        return {
          fst: v.fst,
          snd: v.snd
        };
      }

      ;

      if (Data_Boolean.otherwise) {
        var lesser = rec(Data_Ord.min(ordHugeNum)(v.fst)(v.snd));
        var greater = rec(Data_Ord.max(ordHugeNum)(v.fst)(v.snd));
        var zeroesLength = Data_List.length(Data_List.take(greater.decimal)(greater.digits)) - Data_List.length(Data_List.take(lesser.decimal)(lesser.digits)) | 0;
        var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(zeroesLength)(Data_Digit["_zero"]);
        var lesser$prime = {
          digits: Data_Semigroup.append(Data_List_Types.semigroupList)(zeroes)(lesser.digits),
          decimal: greater.decimal,
          sign: lesser.sign
        };
        var z = {
          fst: lesser$prime,
          snd: greater
        };
        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 158, column 3 - line 166, column 57): " + [v.constructor.name]);
    };

    var fractionalize = function fractionalize(v) {
      if (Data_List.length(Data_List.drop(v.fst.decimal)(v.fst.digits)) === Data_List.length(Data_List.drop(v.snd.decimal)(v.snd.digits))) {
        return {
          fst: v.fst,
          snd: v.snd
        };
      }

      ;

      if (Data_Boolean.otherwise) {
        var test = lessPrecise(v.fst)(v.snd);

        var lesser = function () {
          if (test) {
            return v.fst;
          }

          ;
          return v.snd;
        }();

        var greater = function () {
          if (test) {
            return v.snd;
          }

          ;
          return v.fst;
        }();

        var lesserDecimal = Data_List.length(Data_List.drop(greater.decimal)(greater.digits)) - Data_List.length(Data_List.drop(lesser.decimal)(lesser.digits)) | 0;
        var zeroes = Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(lesserDecimal)(Data_Digit["_zero"]);
        var lesser$prime = {
          digits: Data_Semigroup.append(Data_List_Types.semigroupList)(lesser.digits)(zeroes),
          decimal: lesser.decimal,
          sign: lesser.sign
        };
        var z = {
          fst: lesser$prime,
          snd: greater
        };
        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 148, column 3 - line 157, column 57): " + [v.constructor.name]);
    };

    return function ($218) {
      return integralize(fractionalize($218));
    };
  }();

  var compareHugeNum = function compareHugeNum(v) {
    return function (v1) {
      if (Data_Ord.lessThan(ordSign)(v.sign)(v1.sign)) {
        return Data_Ordering.LT.value;
      }

      ;

      if (Data_Ord.greaterThan(ordSign)(v.sign)(v1.sign)) {
        return Data_Ordering.GT.value;
      }

      ;

      if (v.decimal > v1.decimal) {
        return Data_Ordering.GT.value;
      }

      ;

      if (v.decimal < v1.decimal) {
        return Data_Ordering.LT.value;
      }

      ;

      if (Data_Eq.eq(eqHugeNum)(v)(v1)) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (Data_Boolean.otherwise) {
        var r = equivalize({
          fst: v,
          snd: v1
        });
        var s = rec(r.fst);
        var m = rec(r.snd);

        var x$prime = function () {
          var $181 = Data_Eq.eq(eqHugeNum)(v)(r.fst);

          if ($181) {
            return s;
          }

          ;
          return m;
        }();

        var y$prime = function () {
          var $182 = Data_Eq.eq(eqHugeNum)(v1)(r.fst);

          if ($182) {
            return s;
          }

          ;
          return m;
        }();

        var z = function () {
          var v2 = Data_Ord.compare(Data_List_Types.ordList(Data_Digit.ordDigit))(Data_List.take(v.decimal)(v.digits))(Data_List.take(v.decimal)(v1.digits));

          if (v2 instanceof Data_Ordering.EQ) {
            return Data_Ord.compare(Data_List_Types.ordList(Data_Digit.ordDigit))(Data_List.drop(v.decimal)(x$prime.digits))(Data_List.drop(v.decimal)(y$prime.digits));
          }

          ;
          return v2;
        }();

        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 109, column 1 - line 109, column 49): " + [v.constructor.name, v1.constructor.name]);
    };
  };

  var ordHugeNum = new Data_Ord.Ord(function () {
    return eqHugeNum;
  }, compareHugeNum);

  var arrayToHugeNum = function arrayToHugeNum(xs) {
    return {
      sign: Plus.value,
      digits: Data_Semigroup.append(Data_List_Types.semigroupList)(xs)(Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"])),
      decimal: Data_List.length(xs)
    };
  };

  var adjustDigitsForDecimal = function adjustDigitsForDecimal(decimal) {
    return function (digits) {
      var go = function go($copy_n) {
        return function ($copy_ds) {
          var $tco_var_n = $copy_n;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(n, ds) {
            if (n <= 0) {
              $tco_done = true;
              return ds;
            }

            ;

            if (Data_Boolean.otherwise) {
              $tco_var_n = n - 1 | 0;
              $copy_ds = new Data_List_Types.Cons(Data_Digit["_zero"], ds);
              return;
            }

            ;
            throw new Error("Failed pattern match at Data.HugeNum (line 388, column 3 - line 390, column 42): " + [n.constructor.name, ds.constructor.name]);
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_n, $copy_ds);
          }

          ;
          return $tco_result;
        };
      };

      return go((decimal - Data_List.length(digits) | 0) + 1 | 0)(digits);
    };
  };

  var adjustDecimalForTriviality = function adjustDecimalForTriviality(h1) {
    return function (h2) {
      return function (v) {
        var digitsLength = Data_List.length(v.digits) - 1 | 0;
        var digits$prime = Data_List.take(digitsLength)(v.digits);
        var decimalMod = meatyDecimals(h1) + meatyDecimals(h2) | 0;
        var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(Data_Unfoldable.replicate(Data_List_Types.unfoldableList)((decimalMod - digitsLength | 0) + 1 | 0)(Data_Digit["_zero"]))(digits$prime);
        var decimal = Data_List.length(Data_List.drop(decimalMod)(Data_List.reverse(digits)));
        var r = {
          digits: digits,
          decimal: decimal,
          sign: Plus.value
        };
        return dropZeroes(r);
      };
    };
  };

  var adjustDecimalForFrontZeroes = function adjustDecimalForFrontZeroes(xs) {
    return function (oldDec) {
      var newDigits$prime = unsafeRemoveFrontZeroes(Data_List.take(oldDec)(xs));

      var newDigits = function () {
        var $191 = Data_List["null"](newDigits$prime);

        if ($191) {
          return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
        }

        ;
        return newDigits$prime;
      }();

      return Data_List.length(newDigits);
    };
  };

  var addPlusPlus = function addPlusPlus(x) {
    return function (y) {
      var eqv = equivalize({
        fst: x,
        snd: y
      });
      var r1 = rec(eqv.fst);
      var r2 = rec(eqv.snd);
      var r = Data_List.zip(Data_List.reverse(r1.digits))(Data_List.reverse(r2.digits));
      var digits$prime$prime = Data_Foldable.foldl(Data_List_Types.foldableList)(digitwiseAdd)(new Data_Tuple.Tuple(Data_Monoid.mempty(Data_List_Types.monoidList), Data_Digit["_zero"]))(r);
      var spill = Data_Tuple.snd(digits$prime$prime);
      var digits$prime = Data_Tuple.fst(digits$prime$prime);
      var digits = unsafeRemoveFrontZeroes(new Data_List_Types.Cons(spill, digits$prime));
      var decimal = adjustDecimalForFrontZeroes(new Data_List_Types.Cons(spill, digits$prime))(r1.decimal + 1 | 0);
      var z = {
        digits: adjustDigitsForDecimal(decimal)(digits),
        decimal: decimal,
        sign: Plus.value
      };
      return dropZeroes(z);
    };
  };

  var addPlusMinus = function addPlusMinus(x) {
    return function (y) {
      var eqv = equivalize({
        fst: x,
        snd: y
      });
      var r1 = rec(Data_Ord.min(ordHugeNum)(eqv.fst)(eqv.snd));
      var r2 = rec(Data_Ord.max(ordHugeNum)(eqv.fst)(eqv.snd));
      var r = Data_List.zip(Data_List.reverse(r2.digits))(Data_List.reverse(r1.digits));
      var digits$prime$prime = Data_Foldable.foldl(Data_List_Types.foldableList)(digitwiseSubtract)(new Data_Tuple.Tuple(Data_Monoid.mempty(Data_List_Types.monoidList), Data_Digit["_zero"]))(r);
      var fractionalDigits = Data_List.drop(r1.decimal)(Data_Tuple.fst(digits$prime$prime));
      var integralDigits$prime$prime = Data_List.take(r1.decimal)(Data_Tuple.fst(digits$prime$prime));
      var integralDigits$prime = unsafeRemoveFrontZeroes(integralDigits$prime$prime);

      var integralDigits = function () {
        var $192 = Data_List["null"](integralDigits$prime);

        if ($192) {
          return Control_Applicative.pure(Data_List_Types.applicativeList)(Data_Digit["_zero"]);
        }

        ;
        return integralDigits$prime;
      }();

      var digits = Data_Semigroup.append(Data_List_Types.semigroupList)(integralDigits)(fractionalDigits);
      var decimal = adjustDecimalForFrontZeroes(Data_Tuple.fst(digits$prime$prime))(r1.decimal);
      var z = {
        digits: digits,
        decimal: decimal,
        sign: Plus.value
      };
      return z;
    };
  };

  var addMinusMinus = function addMinusMinus(x) {
    return function (y) {
      var z = rec(addPlusPlus(x)(y));
      return {
        digits: z.digits,
        decimal: z.decimal,
        sign: Minus.value
      };
    };
  };

  var abs = function abs(v) {
    return {
      digits: v.digits,
      decimal: v.decimal,
      sign: Plus.value
    };
  };

  var getPowForKRep = function getPowForKRep(x) {
    return function (y) {
      return function (v) {
        return v - 1 | 0;
      }(function (v) {
        return v.decimal;
      }(rec(Data_Ord.min(ordHugeNum)(abs(x))(abs(y)))));
    };
  };

  var plus = function plus(x) {
    return function (y) {
      if (isZero(x)) {
        return y;
      }

      ;

      if (isZero(y)) {
        return x;
      }

      ;

      if (isPositive(x) && isPositive(y)) {
        return addPlusPlus(x)(y);
      }

      ;

      if (isNegative(x) && isNegative(y)) {
        return addMinusMinus(x)(y);
      }

      ;

      if (Data_Boolean.otherwise) {
        var lesserMag = Data_Ord.min(ordHugeNum)(abs(x))(abs(y));
        var lesser = Data_Ord.min(ordHugeNum)(x)(y);
        var greaterMag = Data_Ord.max(ordHugeNum)(abs(x))(abs(y));
        var greater = Data_Ord.max(ordHugeNum)(x)(y);

        var z = function () {
          var $196 = Data_Eq.eq(eqHugeNum)(greater)(greaterMag);

          if ($196) {
            return addPlusMinus(greater)(lesser);
          }

          ;
          return neg(addPlusMinus(greaterMag)(lesserMag));
        }();

        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 440, column 1 - line 440, column 38): " + [x.constructor.name, y.constructor.name]);
    };
  };

  var scale = function () {
    var go = function go($copy_x) {
      return function ($copy_v) {
        return function ($copy_v1) {
          var $tco_var_x = $copy_x;
          var $tco_var_v = $copy_v;
          var $tco_done = false;
          var $tco_result;

          function $tco_loop(x, v, v1) {
            if (v === 0) {
              $tco_done = true;
              return x;
            }

            ;
            $tco_var_x = plus(x)(v1);
            $tco_var_v = v - 1 | 0;
            $copy_v1 = v1;
            return;
          }

          ;

          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_x, $tco_var_v, $copy_v1);
          }

          ;
          return $tco_result;
        };
      };
    };

    return go(zeroHugeNum);
  }();

  var multSmallNum = function multSmallNum(v) {
    return function (r2) {
      var v1 = Data_List.uncons(v.digits);

      if (v1 instanceof Data_Maybe.Just) {
        var $203 = Data_Eq.eq(Data_Digit.eqDigit)(v1.value0.head)(Data_Digit["_zero"]);

        if ($203) {
          return zeroHugeNum;
        }

        ;
        return scale(Data_Digit.toInt(v1.value0.head))(r2);
      }

      ;
      return Effect_Exception_Unsafe.unsafeThrow("Error: The impossible happened");
    };
  };

  var toKRep = function toKRep(exp) {
    return function (v) {
      var coeff = Data_List.take(v.decimal - exp | 0)(v.digits);
      var bm = new Data_List_Types.Cons(Data_Digit["_one"], Data_Unfoldable.replicate(Data_List_Types.unfoldableList)(exp)(Data_Digit["_zero"]));
      var prod = Data_Semigroup.append(Data_List_Types.semigroupList)(coeff)(Data_List.drop(1)(bm));
      var leftSummand = arrayToHugeNum(prod);
      var k = plus(v)(neg(leftSummand));
      var z = {
        exp: exp,
        coeff: coeff,
        "const": k
      };
      return z;
    };
  };

  var times = function times(r1) {
    return function (r2) {
      if (Data_Eq.eq(eqSign)(timesSign(function (v) {
        return v.sign;
      }(rec(r1)))(function (v) {
        return v.sign;
      }(rec(r2))))(Minus.value)) {
        return neg(times(abs(r1))(abs(r2)));
      }

      ;

      if (!trivialFraction(r1) || !trivialFraction(r2)) {
        return adjustDecimalForTriviality(r1)(r2)(times(makeHugeInteger(r1))(makeHugeInteger(r2)));
      }

      ;

      if (smallEnough(r1)) {
        return multSmallNum(r1)(r2);
      }

      ;

      if (smallEnough(r2)) {
        return multSmallNum(r2)(r1);
      }

      ;

      if (Data_Boolean.otherwise) {
        var exp = getPowForKRep(r1)(r2);
        var k1 = toKRep(exp)(r1);
        var k2 = toKRep(exp)(r2);
        var z0 = times(k1["const"])(k2["const"]);
        var z2 = times(arrayToHugeNum(k1.coeff))(arrayToHugeNum(k2.coeff));

        var z1 = function () {
          var rightFactor = plus(arrayToHugeNum(k2.coeff))(k2["const"]);
          var leftFactor = plus(arrayToHugeNum(k1.coeff))(k1["const"]);
          var prod = times(leftFactor)(rightFactor);
          return plus(plus(prod)(neg(z2)))(neg(z0));
        }();

        var z1Bm = timesPowOfTen(exp)(z1);
        var z2B2m = timesPowOfTen(2 * exp | 0)(z2);
        var z = plus(plus(z2B2m)(z1Bm))(z0);
        return z;
      }

      ;
      throw new Error("Failed pattern match at Data.HugeNum (line 512, column 1 - line 512, column 39): " + [r1.constructor.name, r2.constructor.name]);
    };
  };

  var semiringHugeNum = new Data_Semiring.Semiring(plus, times, oneHugeNum, zeroHugeNum);

  var ceil = function ceil(h) {
    if (isNegative(h)) {
      return integerPart(h);
    }

    ;

    if (isZero(h)) {
      return Data_Semiring.zero(semiringHugeNum);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Semiring.add(semiringHugeNum)(integerPart(h))(Data_Semiring.one(semiringHugeNum));
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 324, column 1 - line 324, column 27): " + [h.constructor.name]);
  };

  var ringHugeNum = new Data_Ring.Ring(function () {
    return semiringHugeNum;
  }, function (r1) {
    return function (r2) {
      return Data_Semiring.add(semiringHugeNum)(r1)(neg(r2));
    };
  });

  var floor = function floor(h) {
    if (isPositive(h)) {
      return integerPart(h);
    }

    ;

    if (isZero(h)) {
      return Data_Semiring.zero(semiringHugeNum);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Ring.sub(ringHugeNum)(integerPart(h))(Data_Semiring.one(semiringHugeNum));
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 318, column 1 - line 318, column 28): " + [h.constructor.name]);
  };

  var round = function round(h) {
    if (Data_Ord.lessThan(ordHugeNum)(abs(Data_Ring.sub(ringHugeNum)(h)(floor(h))))(abs(Data_Ring.sub(ringHugeNum)(ceil(h))(h)))) {
      return floor(h);
    }

    ;

    if (Data_Boolean.otherwise) {
      return ceil(h);
    }

    ;
    throw new Error("Failed pattern match at Data.HugeNum (line 330, column 1 - line 330, column 28): " + [h.constructor.name]);
  };

  exports["fromString"] = fromString;
  exports["fromNumber"] = fromNumber;
  exports["toNumber"] = toNumber;
  exports["toString"] = toString;
  exports["integerPart"] = integerPart;
  exports["abs"] = abs;
  exports["neg"] = neg;
  exports["isNegative"] = isNegative;
  exports["isPositive"] = isPositive;
  exports["isZero"] = isZero;
  exports["floor"] = floor;
  exports["ceil"] = ceil;
  exports["round"] = round;
  exports["eqHugeNum"] = eqHugeNum;
  exports["ordHugeNum"] = ordHugeNum;
  exports["semiringHugeNum"] = semiringHugeNum;
  exports["ringHugeNum"] = ringHugeNum;
})(PS);

(function (exports) {
  "use strict";

  exports["null"] = null;

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };

  exports.notNull = function (x) {
    return x;
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Nullable"] = $PS["Data.Nullable"] || {};
  var exports = $PS["Data.Nullable"];
  var $foreign = $PS["Data.Nullable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var toNullable = Data_Maybe.maybe($foreign["null"])($foreign.notNull);

  var toMaybe = function toMaybe(n) {
    return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };

  exports["toMaybe"] = toMaybe;
  exports["toNullable"] = toNullable;
})(PS);

(function (exports) {
  function wrap(method) {
    return function (d) {
      return function (num) {
        return method.apply(num, [d]);
      };
    };
  }

  exports.toPrecisionNative = wrap(Number.prototype.toPrecision);
  exports.toFixedNative = wrap(Number.prototype.toFixed);
  exports.toExponentialNative = wrap(Number.prototype.toExponential);
})(PS["Data.Number.Format"] = PS["Data.Number.Format"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Number.Format"] = $PS["Data.Number.Format"] || {};
  var exports = $PS["Data.Number.Format"];
  var $foreign = $PS["Data.Number.Format"];
  var Data_Ord = $PS["Data.Ord"];

  var Precision = function () {
    function Precision(value0) {
      this.value0 = value0;
    }

    ;

    Precision.create = function (value0) {
      return new Precision(value0);
    };

    return Precision;
  }();

  var Fixed = function () {
    function Fixed(value0) {
      this.value0 = value0;
    }

    ;

    Fixed.create = function (value0) {
      return new Fixed(value0);
    };

    return Fixed;
  }();

  var Exponential = function () {
    function Exponential(value0) {
      this.value0 = value0;
    }

    ;

    Exponential.create = function (value0) {
      return new Exponential(value0);
    };

    return Exponential;
  }();

  var toStringWith = function toStringWith(v) {
    if (v instanceof Precision) {
      return $foreign.toPrecisionNative(v.value0);
    }

    ;

    if (v instanceof Fixed) {
      return $foreign.toFixedNative(v.value0);
    }

    ;

    if (v instanceof Exponential) {
      return $foreign.toExponentialNative(v.value0);
    }

    ;
    throw new Error("Failed pattern match at Data.Number.Format (line 59, column 1 - line 59, column 40): " + [v.constructor.name]);
  };

  var fixed = function fixed($6) {
    return Fixed.create(Data_Ord.clamp(Data_Ord.ordInt)(0)(20)($6));
  };

  exports["fixed"] = fixed;
  exports["toStringWith"] = toStringWith;
})(PS);

(function (exports) {
  "use strict";
  /* global Symbol */

  var hasArrayFrom = typeof Array.from === "function";
  var hasStringIterator = typeof Symbol !== "undefined" && Symbol != null && typeof Symbol.iterator !== "undefined" && typeof String.prototype[Symbol.iterator] === "function";
  var hasFromCodePoint = typeof String.prototype.fromCodePoint === "function";
  var hasCodePointAt = typeof String.prototype.codePointAt === "function";

  exports._unsafeCodePointAt0 = function (fallback) {
    return hasCodePointAt ? function (str) {
      return str.codePointAt(0);
    } : fallback;
  };

  exports._singleton = function (fallback) {
    return hasFromCodePoint ? String.fromCodePoint : fallback;
  };

  exports._take = function (fallback) {
    return function (n) {
      if (hasStringIterator) {
        return function (str) {
          var accum = "";
          var iter = str[Symbol.iterator]();

          for (var i = 0; i < n; ++i) {
            var o = iter.next();
            if (o.done) return accum;
            accum += o.value;
          }

          return accum;
        };
      }

      return fallback(n);
    };
  };

  exports._toCodePointArray = function (fallback) {
    return function (unsafeCodePointAt0) {
      if (hasArrayFrom) {
        return function (str) {
          return Array.from(str, unsafeCodePointAt0);
        };
      }

      return fallback;
    };
  };
})(PS["Data.String.CodePoints"] = PS["Data.String.CodePoints"] || {});

(function (exports) {
  "use strict";

  exports.charAt = function (i) {
    return function (s) {
      if (i >= 0 && i < s.length) return s.charAt(i);
      throw new Error("Data.String.Unsafe.charAt: Invalid index.");
    };
  };
})(PS["Data.String.Unsafe"] = PS["Data.String.Unsafe"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.String.Unsafe"] = $PS["Data.String.Unsafe"] || {};
  var exports = $PS["Data.String.Unsafe"];
  var $foreign = $PS["Data.String.Unsafe"];
  exports["charAt"] = $foreign.charAt;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.String.CodePoints"] = $PS["Data.String.CodePoints"] || {};
  var exports = $PS["Data.String.CodePoints"];
  var $foreign = $PS["Data.String.CodePoints"];
  var Data_Array = $PS["Data.Array"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Unsafe = $PS["Data.String.Unsafe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];

  var unsurrogate = function unsurrogate(lead) {
    return function (trail) {
      return (((lead - 55296 | 0) * 1024 | 0) + (trail - 56320 | 0) | 0) + 65536 | 0;
    };
  };

  var isTrail = function isTrail(cu) {
    return 56320 <= cu && cu <= 57343;
  };

  var isLead = function isLead(cu) {
    return 55296 <= cu && cu <= 56319;
  };

  var uncons = function uncons(s) {
    var v = Data_String_CodeUnits.length(s);

    if (v === 0) {
      return Data_Maybe.Nothing.value;
    }

    ;

    if (v === 1) {
      return new Data_Maybe.Just({
        head: Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s)),
        tail: ""
      });
    }

    ;
    var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
    var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
    var $21 = isLead(cu0) && isTrail(cu1);

    if ($21) {
      return new Data_Maybe.Just({
        head: unsurrogate(cu0)(cu1),
        tail: Data_String_CodeUnits.drop(2)(s)
      });
    }

    ;
    return new Data_Maybe.Just({
      head: cu0,
      tail: Data_String_CodeUnits.drop(1)(s)
    });
  };

  var unconsButWithTuple = function unconsButWithTuple(s) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
      return new Data_Tuple.Tuple(v.head, v.tail);
    })(uncons(s));
  };

  var toCodePointArrayFallback = function toCodePointArrayFallback(s) {
    return Data_Unfoldable.unfoldr(Data_Unfoldable.unfoldableArray)(unconsButWithTuple)(s);
  };

  var unsafeCodePointAt0Fallback = function unsafeCodePointAt0Fallback(s) {
    var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
    var $25 = isLead(cu0) && Data_String_CodeUnits.length(s) > 1;

    if ($25) {
      var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
      var $26 = isTrail(cu1);

      if ($26) {
        return unsurrogate(cu0)(cu1);
      }

      ;
      return cu0;
    }

    ;
    return cu0;
  };

  var unsafeCodePointAt0 = $foreign["_unsafeCodePointAt0"](unsafeCodePointAt0Fallback);
  var toCodePointArray = $foreign["_toCodePointArray"](toCodePointArrayFallback)(unsafeCodePointAt0);

  var length = function length($52) {
    return Data_Array.length(toCodePointArray($52));
  };

  var fromCharCode = function fromCharCode($53) {
    return Data_String_CodeUnits.singleton(Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar))($53));
  };

  var singletonFallback = function singletonFallback(v) {
    if (v <= 65535) {
      return fromCharCode(v);
    }

    ;
    var lead = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 55296 | 0;
    var trail = Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 56320 | 0;
    return fromCharCode(lead) + fromCharCode(trail);
  };

  var singleton = $foreign["_singleton"](singletonFallback);

  var takeFallback = function takeFallback(n) {
    return function (v) {
      if (n < 1) {
        return "";
      }

      ;
      var v1 = uncons(v);

      if (v1 instanceof Data_Maybe.Just) {
        return singleton(v1.value0.head) + takeFallback(n - 1 | 0)(v1.value0.tail);
      }

      ;
      return v;
    };
  };

  var take = $foreign["_take"](takeFallback);

  var splitAt = function splitAt(i) {
    return function (s) {
      var before = take(i)(s);
      return {
        before: before,
        after: Data_String_CodeUnits.drop(Data_String_CodeUnits.length(before))(s)
      };
    };
  };

  var drop = function drop(n) {
    return function (s) {
      return Data_String_CodeUnits.drop(Data_String_CodeUnits.length(take(n)(s)))(s);
    };
  };

  exports["singleton"] = singleton;
  exports["toCodePointArray"] = toCodePointArray;
  exports["uncons"] = uncons;
  exports["length"] = length;
  exports["take"] = take;
  exports["drop"] = drop;
  exports["splitAt"] = splitAt;
})(PS);

(function (exports) {
  "use strict";

  exports.split = function (sep) {
    return function (s) {
      return s.split(sep);
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.String.Common"] = $PS["Data.String.Common"] || {};
  var exports = $PS["Data.String.Common"];
  var $foreign = $PS["Data.String.Common"];
  exports["split"] = $foreign.split;
})(PS);

(function (exports) {
  "use strict";

  exports["regex'"] = function (left) {
    return function (right) {
      return function (s1) {
        return function (s2) {
          try {
            return right(new RegExp(s1, s2));
          } catch (e) {
            return left(e.message);
          }
        };
      };
    };
  };

  exports.test = function (r) {
    return function (s) {
      var lastIndex = r.lastIndex;
      var result = r.test(s);
      r.lastIndex = lastIndex;
      return result;
    };
  };
})(PS["Data.String.Regex"] = PS["Data.String.Regex"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.String.Regex"] = $PS["Data.String.Regex"] || {};
  var exports = $PS["Data.String.Regex"];
  var $foreign = $PS["Data.String.Regex"];
  var Data_Either = $PS["Data.Either"];

  var renderFlags = function renderFlags(v) {
    return function () {
      if (v.value0.global) {
        return "g";
      }

      ;
      return "";
    }() + (function () {
      if (v.value0.ignoreCase) {
        return "i";
      }

      ;
      return "";
    }() + (function () {
      if (v.value0.multiline) {
        return "m";
      }

      ;
      return "";
    }() + (function () {
      if (v.value0.sticky) {
        return "y";
      }

      ;
      return "";
    }() + function () {
      if (v.value0.unicode) {
        return "u";
      }

      ;
      return "";
    }())));
  };

  var regex = function regex(s) {
    return function (f) {
      return $foreign["regex'"](Data_Either.Left.create)(Data_Either.Right.create)(s)(renderFlags(f));
    };
  };

  exports["regex"] = regex;
  exports["renderFlags"] = renderFlags;
  exports["test"] = $foreign.test;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.String.Regex.Flags"] = $PS["Data.String.Regex.Flags"] || {};
  var exports = $PS["Data.String.Regex.Flags"];

  var RegexFlags = function () {
    function RegexFlags(value0) {
      this.value0 = value0;
    }

    ;

    RegexFlags.create = function (value0) {
      return new RegexFlags(value0);
    };

    return RegexFlags;
  }();

  var global = new RegexFlags({
    global: true,
    ignoreCase: false,
    multiline: false,
    sticky: false,
    unicode: false
  });
  exports["RegexFlags"] = RegexFlags;
  exports["global"] = global;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.String.Regex.Unsafe"] = $PS["Data.String.Regex.Unsafe"] || {};
  var exports = $PS["Data.String.Regex.Unsafe"];
  var Data_Either = $PS["Data.Either"];
  var Data_String_Regex = $PS["Data.String.Regex"];

  var unsafeRegex = function unsafeRegex(s) {
    return function (f) {
      return Data_Either.fromRight()(Data_String_Regex.regex(s)(f));
    };
  };

  exports["unsafeRegex"] = unsafeRegex;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Symbol"] = $PS["Data.Symbol"] || {};
  var exports = $PS["Data.Symbol"];

  var SProxy = function () {
    function SProxy() {}

    ;
    SProxy.value = new SProxy();
    return SProxy;
  }();

  var IsSymbol = function IsSymbol(reflectSymbol) {
    this.reflectSymbol = reflectSymbol;
  };

  var reflectSymbol = function reflectSymbol(dict) {
    return dict.reflectSymbol;
  };

  exports["IsSymbol"] = IsSymbol;
  exports["reflectSymbol"] = reflectSymbol;
  exports["SProxy"] = SProxy;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Data.Time.Component"] = $PS["Data.Time.Component"] || {};
  var exports = $PS["Data.Time.Component"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var ordSecond = Data_Ord.ordInt;
  var ordMinute = Data_Ord.ordInt;
  var ordMillisecond = Data_Ord.ordInt;
  var ordHour = Data_Ord.ordInt;
  var boundedSecond = new Data_Bounded.Bounded(function () {
    return ordSecond;
  }, 0, 59);
  var boundedMinute = new Data_Bounded.Bounded(function () {
    return ordMinute;
  }, 0, 59);
  var boundedMillisecond = new Data_Bounded.Bounded(function () {
    return ordMillisecond;
  }, 0, 999);
  var boundedHour = new Data_Bounded.Bounded(function () {
    return ordHour;
  }, 0, 23);
  var boundedEnumSecond = new Data_Enum.BoundedEnum(function () {
    return boundedSecond;
  }, function () {
    return enumSecond;
  }, 60, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 59) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 90, column 1 - line 90, column 49): " + [n.constructor.name]);
  });
  var enumSecond = new Data_Enum.Enum(function () {
    return ordSecond;
  }, function ($28) {
    return Data_Enum.toEnum(boundedEnumSecond)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumSecond)($28)));
  }, function ($29) {
    return Data_Enum.toEnum(boundedEnumSecond)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumSecond)($29)));
  });
  var boundedEnumMinute = new Data_Enum.BoundedEnum(function () {
    return boundedMinute;
  }, function () {
    return enumMinute;
  }, 60, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 59) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 61, column 1 - line 61, column 49): " + [n.constructor.name]);
  });
  var enumMinute = new Data_Enum.Enum(function () {
    return ordMinute;
  }, function ($30) {
    return Data_Enum.toEnum(boundedEnumMinute)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMinute)($30)));
  }, function ($31) {
    return Data_Enum.toEnum(boundedEnumMinute)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMinute)($31)));
  });
  var boundedEnumMillisecond = new Data_Enum.BoundedEnum(function () {
    return boundedMillisecond;
  }, function () {
    return enumMillisecond;
  }, 1000, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 999) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 120, column 1 - line 120, column 59): " + [n.constructor.name]);
  });
  var enumMillisecond = new Data_Enum.Enum(function () {
    return ordMillisecond;
  }, function ($32) {
    return Data_Enum.toEnum(boundedEnumMillisecond)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMillisecond)($32)));
  }, function ($33) {
    return Data_Enum.toEnum(boundedEnumMillisecond)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumMillisecond)($33)));
  });
  var boundedEnumHour = new Data_Enum.BoundedEnum(function () {
    return boundedHour;
  }, function () {
    return enumHour;
  }, 24, function (v) {
    return v;
  }, function (n) {
    if (n >= 0 && n <= 23) {
      return new Data_Maybe.Just(n);
    }

    ;

    if (Data_Boolean.otherwise) {
      return Data_Maybe.Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Time.Component (line 32, column 1 - line 32, column 45): " + [n.constructor.name]);
  });
  var enumHour = new Data_Enum.Enum(function () {
    return ordHour;
  }, function ($34) {
    return Data_Enum.toEnum(boundedEnumHour)(function (v) {
      return v - 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumHour)($34)));
  }, function ($35) {
    return Data_Enum.toEnum(boundedEnumHour)(function (v) {
      return v + 1 | 0;
    }(Data_Enum.fromEnum(boundedEnumHour)($35)));
  });
  exports["ordHour"] = ordHour;
  exports["boundedHour"] = boundedHour;
  exports["enumHour"] = enumHour;
  exports["boundedEnumHour"] = boundedEnumHour;
  exports["ordMinute"] = ordMinute;
  exports["boundedMinute"] = boundedMinute;
  exports["enumMinute"] = enumMinute;
  exports["boundedEnumMinute"] = boundedEnumMinute;
  exports["ordSecond"] = ordSecond;
  exports["boundedSecond"] = boundedSecond;
  exports["enumSecond"] = enumSecond;
  exports["boundedEnumSecond"] = boundedEnumSecond;
  exports["ordMillisecond"] = ordMillisecond;
  exports["boundedMillisecond"] = boundedMillisecond;
  exports["enumMillisecond"] = enumMillisecond;
  exports["boundedEnumMillisecond"] = boundedEnumMillisecond;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Aff.Class"] = $PS["Effect.Aff.Class"] || {};
  var exports = $PS["Effect.Aff.Class"];

  var MonadAff = function MonadAff(MonadEffect0, liftAff) {
    this.MonadEffect0 = MonadEffect0;
    this.liftAff = liftAff;
  };

  var liftAff = function liftAff(dict) {
    return dict.liftAff;
  };

  exports["liftAff"] = liftAff;
  exports["MonadAff"] = MonadAff;
})(PS);

(function (exports) {
  "use strict";

  exports.log = function (s) {
    return function () {
      console.log(s);
      return {};
    };
  };

  exports.warn = function (s) {
    return function () {
      console.warn(s);
      return {};
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Console"] = $PS["Effect.Console"] || {};
  var exports = $PS["Effect.Console"];
  var $foreign = $PS["Effect.Console"];
  exports["log"] = $foreign.log;
  exports["warn"] = $foreign.warn;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Class.Console"] = $PS["Effect.Class.Console"] || {};
  var exports = $PS["Effect.Class.Console"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];

  var warn = function warn(dictMonadEffect) {
    return function ($15) {
      return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.warn($15));
    };
  };

  var log = function log(dictMonadEffect) {
    return function ($19) {
      return Effect_Class.liftEffect(dictMonadEffect)(Effect_Console.log($19));
    };
  };

  exports["log"] = log;
  exports["warn"] = warn;
})(PS);

(function (exports) {
  "use strict";

  exports.now = function () {
    return Date.now();
  };
})(PS["Effect.Now"] = PS["Effect.Now"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Now"] = $PS["Effect.Now"] || {};
  var exports = $PS["Effect.Now"];
  var $foreign = $PS["Effect.Now"];
  var Data_DateTime_Instant = $PS["Data.DateTime.Instant"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];
  var nowDateTime = Data_Functor.map(Effect.functorEffect)(Data_DateTime_Instant.toDateTime)($foreign.now);
  exports["nowDateTime"] = nowDateTime;
})(PS);

(function (exports) {
  "use strict";

  exports.mkEffectFn1 = function mkEffectFn1(fn) {
    return function (x) {
      return fn(x)();
    };
  };

  exports.runEffectFn3 = function runEffectFn3(fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function () {
            return fn(a, b, c);
          };
        };
      };
    };
  };
})(PS["Effect.Uncurried"] = PS["Effect.Uncurried"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Effect.Uncurried"] = $PS["Effect.Uncurried"] || {};
  var exports = $PS["Effect.Uncurried"];
  var $foreign = $PS["Effect.Uncurried"];
  exports["mkEffectFn1"] = $foreign.mkEffectFn1;
  exports["runEffectFn3"] = $foreign.runEffectFn3;
})(PS);

(function (exports) {
  "use strict";

  exports._copyST = function (m) {
    return function () {
      var r = {};

      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r[k] = m[k];
        }
      }

      return r;
    };
  };

  exports.empty = {};

  exports.runST = function (f) {
    return f();
  };

  exports._lookup = function (no, yes, k, m) {
    return k in m ? yes(m[k]) : no;
  };

  function toArrayWithKey(f) {
    return function (m) {
      var r = [];

      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r.push(f(k)(m[k]));
        }
      }

      return r;
    };
  }
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});

(function (exports) {
  "use strict";

  exports["new"] = function () {
    return {};
  };

  exports.poke = function (k) {
    return function (v) {
      return function (m) {
        return function () {
          m[k] = v;
          return m;
        };
      };
    };
  };
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Foreign.Object.ST"] = $PS["Foreign.Object.ST"] || {};
  var exports = $PS["Foreign.Object.ST"];
  var $foreign = $PS["Foreign.Object.ST"];
  exports["new"] = $foreign["new"];
  exports["poke"] = $foreign.poke;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Foreign.Object"] = $PS["Foreign.Object"] || {};
  var exports = $PS["Foreign.Object"];
  var $foreign = $PS["Foreign.Object"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_ST_Internal = $PS["Control.Monad.ST.Internal"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];
  var thawST = $foreign["_copyST"];

  var singleton = function singleton(k) {
    return function (v) {
      return $foreign.runST(Control_Bind.bindFlipped(Control_Monad_ST_Internal.bindST)(Foreign_Object_ST.poke(k)(v))(Foreign_Object_ST["new"]));
    };
  };

  var mutate = function mutate(f) {
    return function (m) {
      return $foreign.runST(function __do() {
        var v = thawST(m)();
        var v1 = f(v)();
        return v;
      });
    };
  };

  var lookup = Data_Function_Uncurried.runFn4($foreign["_lookup"])(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);

  var insert = function insert(k) {
    return function (v) {
      return mutate(Foreign_Object_ST.poke(k)(v));
    };
  };

  exports["singleton"] = singleton;
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["thawST"] = thawST;
  exports["empty"] = $foreign.empty;
})(PS);

(function (exports) {
  exports._fetch = function (fetchImpl) {
    return function (url) {
      return function (options) {
        return function () {
          return fetchImpl(url, options).catch(function (e) {
            // we have to wrap node-fetch's non-Error error :(
            throw new Error(e);
          });
        };
      };
    };
  };

  exports.jsonImpl = function (response) {
    return function () {
      return response.json().catch(function (e) {
        throw new Error(e);
      });
    };
  };

  exports.fromRecordImpl = function (r) {
    return r;
  };
})(PS["Milkis"] = PS["Milkis"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Milkis"] = $PS["Milkis"] || {};
  var exports = $PS["Milkis"];
  var $foreign = $PS["Milkis"];
  var Control_Promise = $PS["Control.Promise"];

  var URL = function URL(x) {
    return x;
  };

  var postMethod = "POST";

  var json = function json(res) {
    return Control_Promise.toAffE($foreign.jsonImpl(res));
  };

  var fromRecord = function fromRecord(dictHomogeneous) {
    return $foreign.fromRecordImpl;
  };

  var makeHeaders = function makeHeaders(dictHomogeneous) {
    return fromRecord(dictHomogeneous);
  };

  var fetch = function fetch(impl) {
    return function (dictUnion) {
      return function (url) {
        return function (opts) {
          return Control_Promise.toAffE($foreign["_fetch"](impl)(url)(opts));
        };
      };
    };
  };

  exports["URL"] = URL;
  exports["postMethod"] = postMethod;
  exports["fetch"] = fetch;
  exports["json"] = json;
  exports["makeHeaders"] = makeHeaders;
})(PS);

(function (exports) {
  exports.windowFetch = window.fetch;
})(PS["Milkis.Impl.Window"] = PS["Milkis.Impl.Window"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Milkis.Impl.Window"] = $PS["Milkis.Impl.Window"] || {};
  var exports = $PS["Milkis.Impl.Window"];
  var $foreign = $PS["Milkis.Impl.Window"];
  exports["windowFetch"] = $foreign.windowFetch;
})(PS);

(function (exports) {
  "use strict";

  var React = require("react");

  var Fragment = React.Fragment || "div";

  exports.createComponent = function () {
    // Begin component prototype functions
    // (`this`-dependent, defined outside `createComponent`
    // for a slight performance boost)
    function toSelf() {
      var instance = this;

      var setStateThen = function setStateThen(update) {
        return function (effects) {
          return function () {
            instance.setState(function (state) {
              return {
                $$state: update(state.$$state)
              };
            }, effects);
          };
        };
      };

      var self = {
        props: instance.props.$$props,
        state: instance.state === null ? null : instance.state.$$state,
        setState: function setState(update) {
          return setStateThen(update)(undefined);
        },
        setStateThen: setStateThen,
        instance_: instance
      };
      return self;
    }

    function componentDidMount() {
      var didMount = this.$$spec.didMount;

      if (didMount !== undefined) {
        didMount(this.toSelf())();
      }
    }

    function shouldComponentUpdate(nextProps, nextState) {
      var shouldUpdate = this.$$spec.shouldUpdate;
      return shouldUpdate === undefined ? true : shouldUpdate(this.toSelf())({
        nextProps: nextProps.$$props,
        nextState: nextState === null ? null : nextState.$$state
      });
    }

    function componentDidUpdate(prevProps, prevState) {
      var didUpdate = this.$$spec.didUpdate;

      if (didUpdate !== undefined) {
        didUpdate(this.toSelf())({
          prevProps: prevProps.$$props,
          prevState: prevState === null ? null : prevState.$$state
        })();
      }
    }

    function componentWillUnmount() {
      this.$$mounted = false;
      var willUnmount = this.$$spec.willUnmount;

      if (willUnmount !== undefined) {
        willUnmount(this.toSelf())();
      }
    }

    function render() {
      return this.$$spec.render(this.toSelf());
    } // End component prototype functions


    return function (displayName) {
      var Component = function constructor(props) {
        this.$$mounted = true;
        this.$$spec = props.$$spec;
        this.state = // React may optimize components with no state,
        // so we leave state null if it was left as
        // the default value.
        this.$$spec.initialState === undefined ? null : {
          $$state: this.$$spec.initialState
        };
        return this;
      };

      Component.displayName = displayName;
      Component.prototype = Object.create(React.Component.prototype);
      Component.prototype.constructor = Component;
      Component.prototype.toSelf = toSelf;
      Component.prototype.shouldComponentUpdate = shouldComponentUpdate;
      Component.prototype.componentDidMount = componentDidMount;
      Component.prototype.componentDidUpdate = componentDidUpdate;
      Component.prototype.componentWillUnmount = componentWillUnmount;
      Component.prototype.render = render;
      return Component;
    };
  }();

  exports.make = function (_unionDict) {
    return function ($$type) {
      return function ($$spec) {
        var $$specPadded = {
          initialState: $$spec.initialState,
          render: $$spec.render,
          didMount: $$spec.didMount,
          shouldUpdate: $$spec.shouldUpdate,
          didUpdate: $$spec.didUpdate,
          willUnmount: $$spec.willUnmount
        };
        return function ($$props) {
          var props = {
            $$props: $$props,
            $$spec: $$specPadded
          };
          return React.createElement($$type, props);
        };
      };
    };
  };

  exports.empty = null;

  exports.element_ = function (component, props) {
    return React.createElement.apply(null, [component, props].concat(props && props.children || null));
  };

  exports.fragment = function (children) {
    return React.createElement.apply(null, [Fragment, {}].concat(children));
  };

  exports.toReactComponent = function (_unionDict) {
    return function (fromJSProps) {
      return function ($$type) {
        return function ($$spec) {
          var $$specPadded = {
            initialState: $$spec.initialState,
            render: $$spec.render,
            didMount: $$spec.didMount,
            shouldUpdate: $$spec.shouldUpdate,
            didUpdate: $$spec.didUpdate,
            willUnmount: $$spec.willUnmount
          };

          var Component = function constructor() {
            return this;
          };

          Component.prototype = Object.create(React.Component.prototype);
          Component.displayName = $$type.displayName + " (Wrapper)";

          Component.prototype.render = function () {
            var props = {
              $$props: fromJSProps(this.props),
              $$spec: $$specPadded
            };
            return React.createElement($$type, props);
          };

          return Component;
        };
      };
    };
  };
})(PS["React.Basic"] = PS["React.Basic"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic"] = $PS["React.Basic"] || {};
  var exports = $PS["React.Basic"];
  var $foreign = $PS["React.Basic"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];
  var semigroupJSX = new Data_Semigroup.Semigroup(function (a) {
    return function (b) {
      return $foreign.fragment([a, b]);
    };
  });
  var monoidJSX = new Data_Monoid.Monoid(function () {
    return semigroupJSX;
  }, $foreign.empty);

  var makeStateless = function makeStateless(component) {
    return function (_render) {
      return $foreign.make()(component)({
        initialState: Data_Unit.unit,
        render: function render(self) {
          return _render(self.props);
        }
      });
    };
  };

  var element = Data_Function_Uncurried.runFn2($foreign.element_);
  exports["makeStateless"] = makeStateless;
  exports["element"] = element;
  exports["semigroupJSX"] = semigroupJSX;
  exports["monoidJSX"] = monoidJSX;
  exports["createComponent"] = $foreign.createComponent;
  exports["make"] = $foreign.make;
  exports["toReactComponent"] = $foreign.toReactComponent;
})(PS);

(function (exports) {
  "use strict";

  var ReactDOM = require("react-dom");

  exports.render_ = function (jsx, node, callback) {
    ReactDOM.render(jsx, node, callback);
  };
})(PS["React.Basic.DOM"] = PS["React.Basic.DOM"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.DOM"] = $PS["React.Basic.DOM"] || {};
  var exports = $PS["React.Basic.DOM"];
  var $foreign = $PS["React.Basic.DOM"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Uncurried = $PS["Effect.Uncurried"];
  var render$prime = Effect_Uncurried.runEffectFn3($foreign.render_);

  var render = function render(jsx) {
    return function (node) {
      return render$prime(jsx)(node)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
  };

  exports["render"] = render;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Events"] = $PS["React.Basic.Events"] || {};
  var exports = $PS["React.Basic.Events"];
  var Data_Function = $PS["Data.Function"];
  var Effect_Uncurried = $PS["Effect.Uncurried"];

  var handler_ = function handler_($21) {
    return Effect_Uncurried.mkEffectFn1(Data_Function["const"]($21));
  };

  exports["handler_"] = handler_;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native"] = $PS["React.Basic.Native"] || {};
  var exports = $PS["React.Basic.Native"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var string = Unsafe_Coerce.unsafeCoerce;
  exports["string"] = string;
})(PS);

(function (exports) {
  var React = require("react");

  var RN = require("react-native");

  exports.unsafeCreateNativeElement = function (name) {
    return function (props) {
      var children = props.children ? props.children : [];
      return React.createElement.apply(React, [RN[name], props].concat(children));
    };
  };
})(PS["React.Basic.Native.Internal"] = PS["React.Basic.Native.Internal"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native.Internal"] = $PS["React.Basic.Native.Internal"] || {};
  var exports = $PS["React.Basic.Native.Internal"];
  var $foreign = $PS["React.Basic.Native.Internal"];
  exports["unsafeCreateNativeElement"] = $foreign.unsafeCreateNativeElement;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native.Generated"] = $PS["React.Basic.Native.Generated"] || {};
  var exports = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_Internal = $PS["React.Basic.Native.Internal"];

  var view = function view(dictUnion) {
    return function (props) {
      return React_Basic_Native_Internal.unsafeCreateNativeElement("View")(props);
    };
  };

  var view_ = function view_(children) {
    return view()({
      children: children
    });
  };

  var touchableOpacity = function touchableOpacity(dictUnion) {
    return function (props) {
      return React_Basic_Native_Internal.unsafeCreateNativeElement("TouchableOpacity")(props);
    };
  };

  var textInput = function textInput(dictUnion) {
    return function (props) {
      return React_Basic_Native_Internal.unsafeCreateNativeElement("TextInput")(props);
    };
  };

  var text = function text(dictUnion) {
    return function (props) {
      return React_Basic_Native_Internal.unsafeCreateNativeElement("Text")(props);
    };
  };

  var text_ = function text_(children) {
    return text()({
      children: children
    });
  };

  var image = function image(dictUnion) {
    return function (props) {
      return React_Basic_Native_Internal.unsafeCreateNativeElement("Image")(props);
    };
  };

  var button = function button(dictUnion) {
    return function (props) {
      return React_Basic_Native_Internal.unsafeCreateNativeElement("Button")(props);
    };
  };

  var activityIndicator = function activityIndicator(dictUnion) {
    return function (props) {
      return React_Basic_Native_Internal.unsafeCreateNativeElement("ActivityIndicator")(props);
    };
  };

  exports["activityIndicator"] = activityIndicator;
  exports["button"] = button;
  exports["image"] = image;
  exports["textInput"] = textInput;
  exports["text"] = text;
  exports["text_"] = text_;
  exports["touchableOpacity"] = touchableOpacity;
  exports["view"] = view;
  exports["view_"] = view_;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native.PropTypes"] = $PS["React.Basic.Native.PropTypes"] || {};
  var exports = $PS["React.Basic.Native.PropTypes"];

  var CenterEnum = function CenterEnum(center) {
    this.center = center;
  };

  var center = function center(dict) {
    return dict.center;
  };

  exports["center"] = center;
  exports["CenterEnum"] = CenterEnum;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native.PropTypes.Color"] = $PS["React.Basic.Native.PropTypes.Color"] || {};
  var exports = $PS["React.Basic.Native.PropTypes.Color"];
  var Data_Show = $PS["Data.Show"];

  var Color = function Color(x) {
    return x;
  };

  var white = "white";
  var transparent = "transparent";

  var rgba = function rgba(r) {
    return function (g) {
      return function (b) {
        return function (a) {
          return Color("rgba(" + (Data_Show.show(Data_Show.showInt)(r) + (", " + (Data_Show.show(Data_Show.showInt)(g) + (", " + (Data_Show.show(Data_Show.showInt)(b) + (", " + (Data_Show.show(Data_Show.showNumber)(a) + ")"))))))));
        };
      };
    };
  };

  var rgb = function rgb(r) {
    return function (g) {
      return function (b) {
        return Color("rgb(" + (Data_Show.show(Data_Show.showInt)(r) + (", " + (Data_Show.show(Data_Show.showInt)(g) + (", " + (Data_Show.show(Data_Show.showInt)(b) + ")"))))));
      };
    };
  };

  var black = "black";
  exports["rgb"] = rgb;
  exports["rgba"] = rgba;
  exports["transparent"] = transparent;
  exports["black"] = black;
  exports["white"] = white;
})(PS);

(function (exports) {
  'use strict';

  var RN = require('react-native');

  function styles(sarr) {
    var result = {};

    for (var i = 0, len = sarr.length; i < len; i++) {
      var style = sarr[i];

      for (var key in style) {
        if (style.hasOwnProperty(key)) {
          result[key] = style[key];
        }
      }
    }

    return result;
  }

  exports.styles = styles;

  exports.staticStyles = function (sarr) {
    return RN.StyleSheet.create({
      s: styles(sarr)
    }).s;
  };

  exports.unsafeStyleProp = function (s) {
    return function (v) {
      var o = {};
      o[s] = v;
      return o;
    };
  };
})(PS["React.Basic.Native.Styles"] = PS["React.Basic.Native.Styles"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native.Styles"] = $PS["React.Basic.Native.Styles"] || {};
  var exports = $PS["React.Basic.Native.Styles"];
  var $foreign = $PS["React.Basic.Native.Styles"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var zIndex = $foreign.unsafeStyleProp("zIndex");
  var width = $foreign.unsafeStyleProp("width");
  var top = $foreign.unsafeStyleProp("top");
  var styles$prime = Unsafe_Coerce.unsafeCoerce;
  var solid = "solid";
  var shadowRadius = $foreign.unsafeStyleProp("shadowRadius");
  var shadowOpacity = $foreign.unsafeStyleProp("shadowOpacity");
  var shadowOffset = $foreign.unsafeStyleProp("shadowOffset");
  var shadowColor = $foreign.unsafeStyleProp("shadowColor");
  var right = $foreign.unsafeStyleProp("right");
  var resizeMode = $foreign.unsafeStyleProp("resizeMode");
  var position = $foreign.unsafeStyleProp("position");
  var paddingVertical = $foreign.unsafeStyleProp("paddingVertical");
  var paddingHorizontal = $foreign.unsafeStyleProp("paddingHorizontal");
  var padding = $foreign.unsafeStyleProp("padding");
  var opacity = $foreign.unsafeStyleProp("opacity");
  var maxWidth = $foreign.unsafeStyleProp("maxWidth");
  var marginVertical = $foreign.unsafeStyleProp("marginVertical");
  var marginTop = $foreign.unsafeStyleProp("marginTop");
  var marginRight = $foreign.unsafeStyleProp("marginRight");
  var marginHorizontal = $foreign.unsafeStyleProp("marginHorizontal");
  var marginBottom = $foreign.unsafeStyleProp("marginBottom");
  var left = $foreign.unsafeStyleProp("left");
  var height = $foreign.unsafeStyleProp("height");
  var flex = $foreign.unsafeStyleProp("flex");
  var elevation = $foreign.unsafeStyleProp("elevation");
  var contain = "contain";
  var bottom = $foreign.unsafeStyleProp("bottom");
  var borderWidth = $foreign.unsafeStyleProp("borderWidth");
  var borderTopWidth = $foreign.unsafeStyleProp("borderTopWidth");
  var borderTopColor = $foreign.unsafeStyleProp("borderTopColor");
  var borderStyle = $foreign.unsafeStyleProp("borderStyle");
  var borderRightWidth = $foreign.unsafeStyleProp("borderRightWidth");
  var borderRadius = $foreign.unsafeStyleProp("borderRadius");
  var borderLeftWidth = $foreign.unsafeStyleProp("borderLeftWidth");
  var borderColor = $foreign.unsafeStyleProp("borderColor");
  var borderBottomWidth = $foreign.unsafeStyleProp("borderBottomWidth");
  var backgroundColor = $foreign.unsafeStyleProp("backgroundColor");
  var absolute = "absolute";
  exports["styles'"] = styles$prime;
  exports["absolute"] = absolute;
  exports["position"] = position;
  exports["solid"] = solid;
  exports["borderStyle"] = borderStyle;
  exports["backgroundColor"] = backgroundColor;
  exports["flex"] = flex;
  exports["left"] = left;
  exports["right"] = right;
  exports["top"] = top;
  exports["bottom"] = bottom;
  exports["marginBottom"] = marginBottom;
  exports["marginHorizontal"] = marginHorizontal;
  exports["marginRight"] = marginRight;
  exports["marginTop"] = marginTop;
  exports["marginVertical"] = marginVertical;
  exports["width"] = width;
  exports["height"] = height;
  exports["maxWidth"] = maxWidth;
  exports["padding"] = padding;
  exports["paddingHorizontal"] = paddingHorizontal;
  exports["paddingVertical"] = paddingVertical;
  exports["borderBottomWidth"] = borderBottomWidth;
  exports["borderLeftWidth"] = borderLeftWidth;
  exports["borderRightWidth"] = borderRightWidth;
  exports["borderTopWidth"] = borderTopWidth;
  exports["borderWidth"] = borderWidth;
  exports["borderColor"] = borderColor;
  exports["borderRadius"] = borderRadius;
  exports["borderTopColor"] = borderTopColor;
  exports["opacity"] = opacity;
  exports["elevation"] = elevation;
  exports["zIndex"] = zIndex;
  exports["contain"] = contain;
  exports["resizeMode"] = resizeMode;
  exports["shadowColor"] = shadowColor;
  exports["shadowOffset"] = shadowOffset;
  exports["shadowOpacity"] = shadowOpacity;
  exports["shadowRadius"] = shadowRadius;
  exports["styles"] = $foreign.styles;
  exports["staticStyles"] = $foreign.staticStyles;
  exports["unsafeStyleProp"] = $foreign.unsafeStyleProp;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native.Styles.Flex"] = $PS["React.Basic.Native.Styles.Flex"] || {};
  var exports = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];

  var FlexStartEnd = function FlexStartEnd(flexEnd, flexStart) {
    this.flexEnd = flexEnd;
    this.flexStart = flexStart;
  };

  var stretch = "stretch";
  var spaceBetween = "space-between";
  var row = "row";
  var justifyContentFlexSE = new FlexStartEnd("flex-end", "flex-start");
  var justifyContent = React_Basic_Native_Styles.unsafeStyleProp("justifyContent");
  var jcCenter = new React_Basic_Native_PropTypes.CenterEnum("center");

  var flexStart = function flexStart(dict) {
    return dict.flexStart;
  };

  var flexEnd = function flexEnd(dict) {
    return dict.flexEnd;
  };

  var flexDirection = React_Basic_Native_Styles.unsafeStyleProp("flexDirection");
  var flexAlignmentCenter = new React_Basic_Native_PropTypes.CenterEnum("center");
  var alignmentStartEnd = new FlexStartEnd("flex-end", "flex-start");
  var alignSelf = React_Basic_Native_Styles.unsafeStyleProp("alignSelf");
  var alignItems = React_Basic_Native_Styles.unsafeStyleProp("alignItems");
  exports["flexEnd"] = flexEnd;
  exports["flexStart"] = flexStart;
  exports["FlexStartEnd"] = FlexStartEnd;
  exports["stretch"] = stretch;
  exports["row"] = row;
  exports["spaceBetween"] = spaceBetween;
  exports["alignItems"] = alignItems;
  exports["alignSelf"] = alignSelf;
  exports["flexDirection"] = flexDirection;
  exports["justifyContent"] = justifyContent;
  exports["alignmentStartEnd"] = alignmentStartEnd;
  exports["flexAlignmentCenter"] = flexAlignmentCenter;
  exports["justifyContentFlexSE"] = justifyContentFlexSE;
  exports["jcCenter"] = jcCenter;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["React.Basic.Native.Styles.Text"] = $PS["React.Basic.Native.Styles.Text"] || {};
  var exports = $PS["React.Basic.Native.Styles.Text"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var textAlign = React_Basic_Native_Styles.unsafeStyleProp("textAlign");
  var lineHeight = React_Basic_Native_Styles.unsafeStyleProp("lineHeight");
  var iosLetterSpacing = React_Basic_Native_Styles.unsafeStyleProp("letterSpacing");
  var fontSize = React_Basic_Native_Styles.unsafeStyleProp("fontSize");
  var fontFamily = React_Basic_Native_Styles.unsafeStyleProp("fontFamily");
  var color = React_Basic_Native_Styles.unsafeStyleProp("color");
  var centerTextAlign = new React_Basic_Native_PropTypes.CenterEnum("center");
  exports["color"] = color;
  exports["fontFamily"] = fontFamily;
  exports["fontSize"] = fontSize;
  exports["lineHeight"] = lineHeight;
  exports["textAlign"] = textAlign;
  exports["iosLetterSpacing"] = iosLetterSpacing;
  exports["centerTextAlign"] = centerTextAlign;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ReactNative.ActivityIndicator"] = $PS["ReactNative.ActivityIndicator"] || {};
  var exports = $PS["ReactNative.ActivityIndicator"];
  var large = "large";
  exports["large"] = large;
})(PS);

(function (exports) {
  var Dimensions = require('react-native').Dimensions;

  exports.get = function (dim) {
    return Dimensions.get(dim);
  };
})(PS["ReactNative.Dimensions"] = PS["ReactNative.Dimensions"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ReactNative.Dimensions"] = $PS["ReactNative.Dimensions"] || {};
  var exports = $PS["ReactNative.Dimensions"];
  var $foreign = $PS["ReactNative.Dimensions"];
  exports["get"] = $foreign.get;
})(PS);

(function (exports) {
  var Linking = require('react-native').Linking;

  exports.openURL = function (url) {
    return function () {
      Linking.openURL(url);
    };
  };

  exports._canOpenURL = Linking.canOpenURL;
})(PS["ReactNative.Linking"] = PS["ReactNative.Linking"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ReactNative.Linking"] = $PS["ReactNative.Linking"] || {};
  var exports = $PS["ReactNative.Linking"];
  var $foreign = $PS["ReactNative.Linking"];
  var Control_Promise = $PS["Control.Promise"];

  var canOpenURL = function canOpenURL(url) {
    return Control_Promise.toAff($foreign["_canOpenURL"](url));
  };

  exports["canOpenURL"] = canOpenURL;
  exports["openURL"] = $foreign.openURL;
})(PS);

(function (exports) {
  var Platform = require('react-native').Platform;

  exports._os = Platform.OS;
})(PS["ReactNative.Platform"] = PS["ReactNative.Platform"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ReactNative.Platform"] = $PS["ReactNative.Platform"] || {};
  var exports = $PS["ReactNative.Platform"];
  var $foreign = $PS["ReactNative.Platform"];

  var IOS = function () {
    function IOS() {}

    ;
    IOS.value = new IOS();
    return IOS;
  }();

  var Android = function () {
    function Android() {}

    ;
    Android.value = new Android();
    return Android;
  }();

  var Web = function () {
    function Web() {}

    ;
    Web.value = new Web();
    return Web;
  }();

  var OSOther = function () {
    function OSOther(value0) {
      this.value0 = value0;
    }

    ;

    OSOther.create = function (value0) {
      return new OSOther(value0);
    };

    return OSOther;
  }();

  var os = function () {
    if ($foreign["_os"] === "ios") {
      return IOS.value;
    }

    ;

    if ($foreign["_os"] === "android") {
      return Android.value;
    }

    ;

    if ($foreign["_os"] === "web") {
      return Web.value;
    }

    ;
    return new OSOther($foreign["_os"]);
  }();

  exports["IOS"] = IOS;
  exports["Android"] = Android;
  exports["Web"] = Web;
  exports["OSOther"] = OSOther;
  exports["os"] = os;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ReactNative.TextInput"] = $PS["ReactNative.TextInput"] || {};
  var exports = $PS["ReactNative.TextInput"];
  var phonePad = "phone-pad";
  var none = "none";
  var $$default = "default";
  exports["default"] = $$default;
  exports["phonePad"] = phonePad;
  exports["none"] = none;
})(PS);

(function (exports) {
  "use strict";

  exports.unsafeGet = function (label) {
    return function (rec) {
      return rec[label];
    };
  };

  exports.unsafeSet = function (label) {
    return function (value) {
      return function (rec) {
        var copy = {};

        for (var key in rec) {
          if ({}.hasOwnProperty.call(rec, key)) {
            copy[key] = rec[key];
          }
        }

        copy[label] = value;
        return copy;
      };
    };
  };
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Record.Unsafe"] = $PS["Record.Unsafe"] || {};
  var exports = $PS["Record.Unsafe"];
  var $foreign = $PS["Record.Unsafe"];
  exports["unsafeGet"] = $foreign.unsafeGet;
  exports["unsafeSet"] = $foreign.unsafeSet;
})(PS);

(function (exports) {
  "use strict";

  exports.unsafeUnionFn = function (r1, r2) {
    var copy = {};

    for (var k1 in r2) {
      if ({}.hasOwnProperty.call(r2, k1)) {
        copy[k1] = r2[k1];
      }
    }

    for (var k2 in r1) {
      if ({}.hasOwnProperty.call(r1, k2)) {
        copy[k2] = r1[k2];
      }
    }

    return copy;
  };
})(PS["Record.Unsafe.Union"] = PS["Record.Unsafe.Union"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Record.Unsafe.Union"] = $PS["Record.Unsafe.Union"] || {};
  var exports = $PS["Record.Unsafe.Union"];
  var $foreign = $PS["Record.Unsafe.Union"];
  exports["unsafeUnionFn"] = $foreign.unsafeUnionFn;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Record"] = $PS["Record"] || {};
  var exports = $PS["Record"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Record_Unsafe_Union = $PS["Record.Unsafe.Union"];

  var set = function set(dictIsSymbol) {
    return function (dictCons) {
      return function (dictCons1) {
        return function (l) {
          return function (b) {
            return function (r) {
              return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(b)(r);
            };
          };
        };
      };
    };
  };

  var merge = function merge(dictUnion) {
    return function (dictNub) {
      return function (l) {
        return function (r) {
          return Record_Unsafe_Union.unsafeUnionFn(l, r);
        };
      };
    };
  };

  var insert = function insert(dictIsSymbol) {
    return function (dictLacks) {
      return function (dictCons) {
        return function (l) {
          return function (a) {
            return function (r) {
              return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r);
            };
          };
        };
      };
    };
  };

  var get = function get(dictIsSymbol) {
    return function (dictCons) {
      return function (l) {
        return function (r) {
          return Record_Unsafe.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
        };
      };
    };
  };

  exports["get"] = get;
  exports["set"] = set;
  exports["insert"] = insert;
  exports["merge"] = merge;
})(PS);

(function (exports) {
  "use strict";

  exports.copyRecord = function (rec) {
    var copy = {};

    for (var key in rec) {
      if ({}.hasOwnProperty.call(rec, key)) {
        copy[key] = rec[key];
      }
    }

    return copy;
  };

  exports.unsafeInsert = function (l) {
    return function (a) {
      return function (rec) {
        rec[l] = a;
        return rec;
      };
    };
  };
})(PS["Record.Builder"] = PS["Record.Builder"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Record.Builder"] = $PS["Record.Builder"] || {};
  var exports = $PS["Record.Builder"];
  var $foreign = $PS["Record.Builder"];
  var Control_Category = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Symbol = $PS["Data.Symbol"];
  var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;

  var insert = function insert(dictCons) {
    return function (dictLacks) {
      return function (dictIsSymbol) {
        return function (l) {
          return function (a) {
            return function (r1) {
              return $foreign.unsafeInsert(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r1);
            };
          };
        };
      };
    };
  };

  var categoryBuilder = Control_Category.categoryFn;

  var build = function build(v) {
    return function (r1) {
      return v($foreign.copyRecord(r1));
    };
  };

  exports["build"] = build;
  exports["insert"] = insert;
  exports["semigroupoidBuilder"] = semigroupoidBuilder;
  exports["categoryBuilder"] = categoryBuilder;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Type.Data.RowList"] = $PS["Type.Data.RowList"] || {};
  var exports = $PS["Type.Data.RowList"];

  var RLProxy = function () {
    function RLProxy() {}

    ;
    RLProxy.value = new RLProxy();
    return RLProxy;
  }();

  exports["RLProxy"] = RLProxy;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Type.Equality"] = $PS["Type.Equality"] || {};
  var exports = $PS["Type.Equality"];

  var TypeEquals = function TypeEquals(from, to) {
    this.from = from;
    this.to = to;
  };

  var to = function to(dict) {
    return dict.to;
  };

  var refl = new TypeEquals(function (a) {
    return a;
  }, function (a) {
    return a;
  });

  var from = function from(dict) {
    return dict.from;
  };

  exports["TypeEquals"] = TypeEquals;
  exports["to"] = to;
  exports["from"] = from;
  exports["refl"] = refl;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Type.Proxy"] = $PS["Type.Proxy"] || {};
  var exports = $PS["Type.Proxy"];

  var $$Proxy = function () {
    function $$Proxy() {}

    ;
    $$Proxy.value = new $$Proxy();
    return $$Proxy;
  }();

  exports["Proxy"] = $$Proxy;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Type.Row.Homogeneous"] = $PS["Type.Row.Homogeneous"] || {};
  var exports = $PS["Type.Row.Homogeneous"];
  var HomogeneousRowList = {};
  var Homogeneous = {};
  var homogeneousRowListNil = HomogeneousRowList;

  var homogeneousRowListCons = function homogeneousRowListCons(dictHomogeneousRowList) {
    return function (dictTypeEquals) {
      return HomogeneousRowList;
    };
  };

  var homogeneous = function homogeneous(dictRowToList) {
    return function (dictHomogeneousRowList) {
      return Homogeneous;
    };
  };

  exports["Homogeneous"] = Homogeneous;
  exports["HomogeneousRowList"] = HomogeneousRowList;
  exports["homogeneous"] = homogeneous;
  exports["homogeneousRowListCons"] = homogeneousRowListCons;
  exports["homogeneousRowListNil"] = homogeneousRowListNil;
})(PS);

(function (exports) {
  "use strict";

  exports._getElementById = function (id) {
    return function (node) {
      return function () {
        return node.getElementById(id);
      };
    };
  };
})(PS["Web.DOM.NonElementParentNode"] = PS["Web.DOM.NonElementParentNode"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Web.DOM.NonElementParentNode"] = $PS["Web.DOM.NonElementParentNode"] || {};
  var exports = $PS["Web.DOM.NonElementParentNode"];
  var $foreign = $PS["Web.DOM.NonElementParentNode"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];

  var getElementById = function getElementById(eid) {
    return function ($0) {
      return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_getElementById"](eid)($0));
    };
  };

  exports["getElementById"] = getElementById;
})(PS);

(function (exports) {
  /* global window */
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Web.HTML"] = $PS["Web.HTML"] || {};
  var exports = $PS["Web.HTML"];
  var $foreign = $PS["Web.HTML"];
  exports["window"] = $foreign.window;
})(PS);

(function (exports) {
  "use strict";

  exports.title = function (doc) {
    return function () {
      return doc.title;
    };
  };

  exports.setTitle = function (title) {
    return function (doc) {
      return function () {
        doc.title = title;
        return {};
      };
    };
  };
})(PS["Web.HTML.HTMLDocument"] = PS["Web.HTML.HTMLDocument"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Web.HTML.HTMLDocument"] = $PS["Web.HTML.HTMLDocument"] || {};
  var exports = $PS["Web.HTML.HTMLDocument"];
  var $foreign = $PS["Web.HTML.HTMLDocument"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var toNonElementParentNode = Unsafe_Coerce.unsafeCoerce;
  exports["toNonElementParentNode"] = toNonElementParentNode;
  exports["setTitle"] = $foreign.setTitle;
})(PS);

(function (exports) {
  "use strict"; // ----------------------------------------------------------------------------

  exports.hostname = function (location) {
    return function () {
      return location.hostname;
    };
  };
})(PS["Web.HTML.Location"] = PS["Web.HTML.Location"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Web.HTML.Location"] = $PS["Web.HTML.Location"] || {};
  var exports = $PS["Web.HTML.Location"];
  var $foreign = $PS["Web.HTML.Location"];
  exports["hostname"] = $foreign.hostname;
})(PS);

(function (exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };

  exports.location = function (window) {
    return function () {
      return window.location;
    };
  };

  exports.alert = function (str) {
    return function (window) {
      return function () {
        window.alert(str);
        return {};
      };
    };
  };

  exports._open = function (url) {
    return function (name) {
      return function (features) {
        return function (window) {
          return function () {
            return window.open(url, name, features);
          };
        };
      };
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["Web.HTML.Window"] = $PS["Web.HTML.Window"] || {};
  var exports = $PS["Web.HTML.Window"];
  var $foreign = $PS["Web.HTML.Window"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];

  var open = function open(url$prime) {
    return function (name) {
      return function (features) {
        return function (window) {
          return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_open"](url$prime)(name)(features)(window));
        };
      };
    };
  };

  exports["open"] = open;
  exports["document"] = $foreign.document;
  exports["location"] = $foreign.location;
  exports["alert"] = $foreign.alert;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZRPC.ConsPrefix"] = $PS["ZRPC.ConsPrefix"] || {};
  var exports = $PS["ZRPC.ConsPrefix"];

  var ConsPrefix = function ConsPrefix(consPrefix) {
    this.consPrefix = consPrefix;
  };

  var consPrefix = function consPrefix(dict) {
    return dict.consPrefix;
  };

  exports["ConsPrefix"] = ConsPrefix;
  exports["consPrefix"] = consPrefix;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZRPC.ZRPCRead"] = $PS["ZRPC.ZRPCRead"] || {};
  var exports = $PS["ZRPC.ZRPCRead"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_HugeNum = $PS["Data.HugeNum"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Int = $PS["Data.Int"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Foreign = $PS["Foreign"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record_Builder = $PS["Record.Builder"];
  var Type_Data_RowList = $PS["Type.Data.RowList"];
  var Type_Proxy = $PS["Type.Proxy"];
  var ZRPC_ConsPrefix = $PS["ZRPC.ConsPrefix"];

  var ValF = function ValF(x) {
    return x;
  };

  var Null = function () {
    function Null() {}

    ;
    Null.value = new Null();
    return Null;
  }();

  var ZRPCReadRep = function ZRPCReadRep(zrpcReadRep) {
    this.zrpcReadRep = zrpcReadRep;
  };

  var ZRPCReadFields = function ZRPCReadFields(zrpcReadFields) {
    this.zrpcReadFields = zrpcReadFields;
  };

  var ZRPCReadField = function ZRPCReadField(zrpcReadField) {
    this.zrpcReadField = zrpcReadField;
  };

  var ZRPCRead = function ZRPCRead(zrpcRead) {
    this.zrpcRead = zrpcRead;
  };

  var Tag = function Tag(tag) {
    this.tag = tag;
  };

  var zrpcReadRep = function zrpcReadRep(dict) {
    return dict.zrpcReadRep;
  };

  var zrpcReadFieldsNil = new ZRPCReadFields(function (v) {
    return function (v1) {
      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.identity(Record_Builder.categoryBuilder));
    };
  });

  var zrpcReadFields = function zrpcReadFields(dict) {
    return dict.zrpcReadFields;
  };

  var zrpcReadField = function zrpcReadField(dict) {
    return dict.zrpcReadField;
  };

  var zrpcRead = function zrpcRead(dict) {
    return dict.zrpcRead;
  };

  var unValF = function unValF(v) {
    return v;
  };

  var typeExpE = function typeExpE(exp) {
    return function (act) {
      return Foreign.fail(new Foreign.TypeMismatch(exp, act));
    };
  };

  var tagString = new Tag(function (v) {
    return "String";
  });
  var tagObject = new Tag(function (v) {
    return "Object";
  });
  var tagNumber = new Tag(function (v) {
    return "Number";
  });
  var tagNull = new Tag(function (v) {
    return "Null";
  });
  var tagInt = new Tag(function (v) {
    return "Int";
  });
  var tagHugeNum = new Tag(function (v) {
    return "BigDecimal";
  });
  var tagBoolean = new Tag(function (v) {
    return "Boolean";
  });
  var tagArray = new Tag(function (v) {
    return "Array";
  });

  var tag = function tag(dict) {
    return dict.tag;
  };

  var readRepSum = function readRepSum(dictZRPCReadRep) {
    return function (dictZRPCReadRep1) {
      return new ZRPCReadRep(function (f) {
        return function (name) {
          return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inl.create)(zrpcReadRep(dictZRPCReadRep)(f)(name)))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inr.create)(zrpcReadRep(dictZRPCReadRep1)(f)(name)));
        };
      });
    };
  };

  var readPropWith$prime = function readPropWith$prime(name) {
    return function (r) {
      return function (o) {
        var v = Foreign_Object.lookup(name)(o);
        return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtProperty.create(name)))(r(v));
      };
    };
  };

  var nullAsMaybe = function nullAsMaybe(j) {
    var $89 = Data_Argonaut_Core.isNull(j);

    if ($89) {
      return Data_Maybe.Nothing.value;
    }

    ;
    return new Data_Maybe.Just(j);
  };

  var zrpcReadField$prime = function zrpcReadField$prime(dictZRPCReadField) {
    return function ($115) {
      return zrpcReadField(dictZRPCReadField)(nullAsMaybe($115));
    };
  };

  var missingError = new Foreign.ForeignError("missing");

  var readPropWith = function readPropWith(name) {
    return function (r) {
      return readPropWith$prime(name)(function (x) {
        if (x instanceof Data_Maybe.Just) {
          return r(x.value0);
        }

        ;

        if (x instanceof Data_Maybe.Nothing) {
          return Foreign.fail(new Foreign.ErrorAtProperty(name, missingError));
        }

        ;
        throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 377, column 28 - line 380, column 47): " + [x.constructor.name]);
      });
    };
  };

  var zrpcReadFieldId = function zrpcReadFieldId(dictZRPCRead) {
    return new ZRPCReadField(function (v) {
      if (v instanceof Data_Maybe.Just) {
        return zrpcRead(dictZRPCRead)(v.value0);
      }

      ;

      if (v instanceof Data_Maybe.Nothing) {
        return Foreign.fail(missingError);
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 222, column 6 - line 222, column 65): " + [v.constructor.name]);
    });
  };

  var functorValF = Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity);

  var failUnknownType = function failUnknownType(s) {
    return Foreign.fail(Foreign.ForeignError.create("unknown type: " + s));
  };

  var readRepNoArgs = function readRepNoArgs(dictIsSymbol) {
    return new ZRPCReadRep(function (v) {
      return function (name) {
        if (name === Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value)) {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Generic_Rep.NoArguments.value);
        }

        ;

        if (Data_Boolean.otherwise) {
          return failUnknownType(name);
        }

        ;
        throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 270, column 1 - line 270, column 86): " + [v.constructor.name, name.constructor.name]);
      };
    });
  };

  var readRepRecArg = function readRepRecArg(dictIsSymbol) {
    return function (dictZRPCRead) {
      return new ZRPCReadRep(function (f) {
        return function (name) {
          if (name === Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value)) {
            return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(function ($116) {
              return Data_Generic_Rep.Constructor(Data_Generic_Rep.Argument($116));
            })(zrpcRead(dictZRPCRead)(f));
          }

          ;

          if (Data_Boolean.otherwise) {
            return failUnknownType(name);
          }

          ;
          throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 275, column 1 - line 275, column 105): " + [f.constructor.name, name.constructor.name]);
        };
      });
    };
  };

  var failInvalidFormat = function failInvalidFormat(s) {
    return Foreign.fail(Foreign.ForeignError.create("not a valid " + (s + " format")));
  };

  var noteFormat = function noteFormat(dictTag) {
    return function (m) {
      if (m instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(m.value0);
      }

      ;

      if (m instanceof Data_Maybe.Nothing) {
        return failInvalidFormat(tag(dictTag)(Type_Proxy["Proxy"].value));
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 349, column 16 - line 351, column 39): " + [m.constructor.name]);
    };
  };

  var readFormat = function readFormat(dictZRPCRead) {
    return function (dictTag) {
      return function (r) {
        return Control_Bind.composeKleisli(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(zrpcRead(dictZRPCRead))(function ($117) {
          return noteFormat(dictTag)(r($117));
        });
      };
    };
  };

  var caseJson$prime = function caseJson$prime(h) {
    return Data_Argonaut_Core.caseJson(h.jreadNull)(h.jreadBoolean)(h.jreadNumber)(h.jreadString)(h.jreadArray)(h.jreadObject);
  };

  var bh = function bh(dictTag) {
    var exp = tag(dictTag)(Type_Proxy["Proxy"].value);

    var e = function e(dictTag1) {
      return function (v) {
        return typeExpE(exp)(tag(dictTag1)(Type_Proxy["Proxy"].value));
      };
    };

    return {
      jreadNull: function jreadNull(v) {
        return e(tagNull)(Null.value);
      },
      jreadBoolean: e(tagBoolean),
      jreadNumber: e(tagNumber),
      jreadString: e(tagString),
      jreadArray: e(tagArray),
      jreadObject: e(tagObject)
    };
  };

  var readArray = caseJson$prime({
    jreadNull: bh(tagArray).jreadNull,
    jreadBoolean: bh(tagArray).jreadBoolean,
    jreadNumber: bh(tagArray).jreadNumber,
    jreadString: bh(tagArray).jreadString,
    jreadArray: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    jreadObject: bh(tagArray).jreadObject
  });

  var zrpcReadArray = function zrpcReadArray(dictZRPCReadField) {
    return new ZRPCRead(function (f) {
      return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readArray(f))(Data_Traversable.traverse(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(zrpcReadField$prime(dictZRPCReadField)));
    });
  };

  var readNull = caseJson$prime({
    jreadNull: function jreadNull(v1) {
      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Null.value);
    },
    jreadBoolean: bh(tagNull).jreadBoolean,
    jreadNumber: bh(tagNull).jreadNumber,
    jreadString: bh(tagNull).jreadString,
    jreadArray: bh(tagNull).jreadArray,
    jreadObject: bh(tagNull).jreadObject
  });

  var zrpcReadFieldMaybe = function zrpcReadFieldMaybe(dictZRPCRead) {
    return new ZRPCReadField(function (v) {
      if (v instanceof Data_Maybe.Just) {
        return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(zrpcRead(dictZRPCRead)(v.value0)))(Data_Functor.voidLeft(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(readNull(v.value0))(Data_Maybe.Nothing.value));
      }

      ;

      if (v instanceof Data_Maybe.Nothing) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 219, column 1 - line 219, column 71): " + [v.constructor.name]);
    });
  };

  var readObject = caseJson$prime({
    jreadNull: bh(tagObject).jreadNull,
    jreadBoolean: bh(tagObject).jreadBoolean,
    jreadNumber: bh(tagObject).jreadNumber,
    jreadString: bh(tagObject).jreadString,
    jreadArray: bh(tagObject).jreadArray,
    jreadObject: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))
  });

  var readRecord = function readRecord(dictRowToList) {
    return function (dictZRPCReadFields) {
      return new ZRPCRead(function (f) {
        return Data_Functor.mapFlipped(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(zrpcReadFields(dictZRPCReadFields)(Type_Data_RowList.RLProxy.value)))(Data_Function.flip(Record_Builder.build)({}));
      });
    };
  };

  var zrpcReadEither = function zrpcReadEither(dictZRPCReadField) {
    return function (dictZRPCReadField1) {
      return new ZRPCRead(function (f) {
        var readRight = function readRight(o) {
          return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Either.Right.create)(readPropWith("Right")(zrpcReadField$prime(dictZRPCReadField1))(o));
        };

        var readLeft = function readLeft(o) {
          return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Either.Left.create)(readPropWith("Left")(zrpcReadField$prime(dictZRPCReadField))(o));
        };

        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(function (o) {
          return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(readRight(o))(readLeft(o));
        });
      });
    };
  };

  var zrpcReadNumber = new ZRPCRead(caseJson$prime({
    jreadNull: bh(tagNumber).jreadNull,
    jreadBoolean: bh(tagNumber).jreadBoolean,
    jreadNumber: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    jreadString: bh(tagNumber).jreadString,
    jreadArray: bh(tagNumber).jreadArray,
    jreadObject: bh(tagNumber).jreadObject
  }));
  var zrpcReadInt = new ZRPCRead(readFormat(zrpcReadNumber)(tagInt)(Data_Int.fromNumber));
  var zrpcReadString = new ZRPCRead(caseJson$prime({
    jreadNull: bh(tagString).jreadNull,
    jreadBoolean: bh(tagString).jreadBoolean,
    jreadNumber: bh(tagString).jreadNumber,
    jreadString: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    jreadArray: bh(tagString).jreadArray,
    jreadObject: bh(tagString).jreadObject
  }));

  var genericZRPCRead = function genericZRPCRead(dictConsPrefix) {
    return function (dictGeneric) {
      return function (dictZRPCReadRep) {
        return function (f) {
          return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(function (v) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readPropWith("type")(zrpcRead(zrpcReadString))(v))(function (v1) {
              var tpName = ZRPC_ConsPrefix.consPrefix(dictConsPrefix)(Type_Proxy["Proxy"].value) + v1;
              return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.to(dictGeneric))(zrpcReadRep(dictZRPCReadRep)(f)(tpName));
            });
          });
        };
      };
    };
  };

  var zrpcReadHugeNum = new ZRPCRead(function (f) {
    return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_HugeNum.fromNumber)(zrpcRead(zrpcReadNumber)(f)))(readFormat(zrpcReadString)(tagHugeNum)(Data_HugeNum.fromString)(f));
  });
  var applyValF = new Control_Apply.Apply(function () {
    return functorValF;
  }, function (v) {
    return function (v1) {
      return ValF(Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity)(function () {
        var v2 = Control_Monad_Except.runExcept(v1);
        var v3 = Control_Monad_Except.runExcept(v);

        if (v3 instanceof Data_Either.Right && v2 instanceof Data_Either.Right) {
          return Data_Either.Right.create(v3.value0(v2.value0));
        }

        ;

        if (v3 instanceof Data_Either.Left && v2 instanceof Data_Either.Left) {
          return new Data_Either.Left(Data_Semigroup.append(Data_List_Types.semigroupNonEmptyList)(v3.value0)(v2.value0));
        }

        ;

        if (v2 instanceof Data_Either.Left) {
          return new Data_Either.Left(v2.value0);
        }

        ;

        if (v3 instanceof Data_Either.Left) {
          return new Data_Either.Left(v3.value0);
        }

        ;
        throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 406, column 50 - line 410, column 32): " + [v3.constructor.name, v2.constructor.name]);
      }()));
    };
  });

  var zrpcReadFieldsCons = function zrpcReadFieldsCons(dictIsSymbol) {
    return function (dictZRPCReadField) {
      return function (dictZRPCReadFields) {
        return function (dictLacks) {
          return function (dictCons) {
            return new ZRPCReadFields(function (v) {
              return function (o) {
                var rest = ValF(zrpcReadFields(dictZRPCReadFields)(Type_Data_RowList.RLProxy.value)(o));
                var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                var v1 = readPropWith$prime(name)(zrpcReadField(dictZRPCReadField))(o);
                var first = ValF(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Record_Builder.insert(dictCons)(dictLacks)(dictIsSymbol)(Data_Symbol.SProxy.value))(v1));
                return unValF(Control_Apply.apply(applyValF)(Data_Functor.map(functorValF)(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(first))(rest));
              };
            });
          };
        };
      };
    };
  };

  exports["ZRPCRead"] = ZRPCRead;
  exports["zrpcRead"] = zrpcRead;
  exports["genericZRPCRead"] = genericZRPCRead;
  exports["ZRPCReadFields"] = ZRPCReadFields;
  exports["zrpcReadFields"] = zrpcReadFields;
  exports["ZRPCReadField"] = ZRPCReadField;
  exports["zrpcReadField"] = zrpcReadField;
  exports["ZRPCReadRep"] = ZRPCReadRep;
  exports["zrpcReadRep"] = zrpcReadRep;
  exports["zrpcReadNumber"] = zrpcReadNumber;
  exports["zrpcReadString"] = zrpcReadString;
  exports["zrpcReadInt"] = zrpcReadInt;
  exports["zrpcReadHugeNum"] = zrpcReadHugeNum;
  exports["zrpcReadArray"] = zrpcReadArray;
  exports["zrpcReadEither"] = zrpcReadEither;
  exports["readRecord"] = readRecord;
  exports["zrpcReadFieldMaybe"] = zrpcReadFieldMaybe;
  exports["zrpcReadFieldId"] = zrpcReadFieldId;
  exports["zrpcReadFieldsNil"] = zrpcReadFieldsNil;
  exports["zrpcReadFieldsCons"] = zrpcReadFieldsCons;
  exports["readRepNoArgs"] = readRepNoArgs;
  exports["readRepRecArg"] = readRepRecArg;
  exports["readRepSum"] = readRepSum;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Api.Types"] = $PS["ZAP.Dart.Api.Types"] || {};
  var exports = $PS["ZAP.Dart.Api.Types"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var ZRPC_ConsPrefix = $PS["ZRPC.ConsPrefix"];
  var ZRPC_ZRPCRead = $PS["ZRPC.ZRPCRead"];

  var UnknownError = function () {
    function UnknownError() {}

    ;
    UnknownError.value = new UnknownError();
    return UnknownError;
  }();

  var BadRequest = function () {
    function BadRequest() {}

    ;
    BadRequest.value = new BadRequest();
    return BadRequest;
  }();

  var NotFound = function () {
    function NotFound() {}

    ;
    NotFound.value = new NotFound();
    return NotFound;
  }();

  var InternalError = function () {
    function InternalError() {}

    ;
    InternalError.value = new InternalError();
    return InternalError;
  }();

  var Points = function () {
    function Points(value0) {
      this.value0 = value0;
    }

    ;

    Points.create = function (value0) {
      return new Points(value0);
    };

    return Points;
  }();

  var Coupon = function () {
    function Coupon(value0) {
      this.value0 = value0;
    }

    ;

    Coupon.create = function (value0) {
      return new Coupon(value0);
    };

    return Coupon;
  }();

  var genericResErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UnknownError) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof BadRequest) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof NotFound) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    }

    ;

    if (x instanceof InternalError) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    }

    ;
    throw new Error("Failed pattern match at ZAP.Dart.Api.Types (line 22, column 8 - line 22, column 52): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return UnknownError.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return BadRequest.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return NotFound.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return InternalError.value;
    }

    ;
    throw new Error("Failed pattern match at ZAP.Dart.Api.Types (line 22, column 8 - line 22, column 52): " + [x.constructor.name]);
  });
  var genericClaim = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Points) {
      return new Data_Generic_Rep.Inl(x.value0);
    }

    ;

    if (x instanceof Coupon) {
      return new Data_Generic_Rep.Inr(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZAP.Dart.Api.Types (line 62, column 8 - line 62, column 50): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return new Points(x.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr) {
      return new Coupon(x.value0);
    }

    ;
    throw new Error("Failed pattern match at ZAP.Dart.Api.Types (line 62, column 8 - line 62, column 50): " + [x.constructor.name]);
  });
  var consPrefixResErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
  });
  var zrpcReadResErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixResErr)(genericResErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UnknownError";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
  })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
  })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "InternalError";
  })))))));
  var consPrefixClaim = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
  });
  var zrpcReadClaim = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixClaim)(genericClaim)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Points";
  }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyName";
  }))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
  }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Coupon";
  }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "couponId";
  }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))));
  exports["UnknownError"] = UnknownError;
  exports["BadRequest"] = BadRequest;
  exports["NotFound"] = NotFound;
  exports["InternalError"] = InternalError;
  exports["Points"] = Points;
  exports["Coupon"] = Coupon;
  exports["genericResErr"] = genericResErr;
  exports["consPrefixResErr"] = consPrefixResErr;
  exports["zrpcReadResErr"] = zrpcReadResErr;
  exports["genericClaim"] = genericClaim;
  exports["consPrefixClaim"] = consPrefixClaim;
  exports["zrpcReadClaim"] = zrpcReadClaim;
})(PS);

(function (exports) {
  exports.apiUrl = process.env.API_URL;
})(PS["ZAP.Dart.Client.Constants"] = PS["ZAP.Dart.Client.Constants"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Client.Constants"] = $PS["ZAP.Dart.Client.Constants"] || {};
  var exports = $PS["ZAP.Dart.Client.Constants"];
  var $foreign = $PS["ZAP.Dart.Client.Constants"];
  var networkDialogOpts = {
    title: "We've lost connection.",
    message: "Please make sure you're connected to the Internet.",
    primaryActionLabel: "Try Again"
  };
  var holdingId = 259;
  exports["holdingId"] = holdingId;
  exports["networkDialogOpts"] = networkDialogOpts;
  exports["apiUrl"] = $foreign.apiUrl;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZRPC.ZRPCWrite"] = $PS["ZRPC.ZRPCWrite"] || {};
  var exports = $PS["ZRPC.ZRPCWrite"];
  var Control_Category = $PS["Control.Category"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Date = $PS["Data.Date"];
  var Data_Date_Component = $PS["Data.Date.Component"];
  var Data_DateTime = $PS["Data.DateTime"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_HugeNum = $PS["Data.HugeNum"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Time = $PS["Data.Time"];
  var Data_Time_Component = $PS["Data.Time.Component"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Data_RowList = $PS["Type.Data.RowList"];
  var Type_Proxy = $PS["Type.Proxy"];
  var ZRPC_ConsPrefix = $PS["ZRPC.ConsPrefix"];

  var ZRPCWriteRep = function ZRPCWriteRep(zrpcWriteRep) {
    this.zrpcWriteRep = zrpcWriteRep;
  };

  var ZRPCWriteFields = function ZRPCWriteFields(zrpcWriteFields) {
    this.zrpcWriteFields = zrpcWriteFields;
  };

  var ZRPCWriteField = function ZRPCWriteField(zrpcWriteField) {
    this.zrpcWriteField = zrpcWriteField;
  };

  var ZRPCWrite = function ZRPCWrite(zrpcWrite) {
    this.zrpcWrite = zrpcWrite;
  };

  var zrpcWriteString = new ZRPCWrite(Data_Argonaut_Core.fromString);

  var zrpcWriteRep = function zrpcWriteRep(dict) {
    return dict.zrpcWriteRep;
  };

  var zrpcWriteInt = new ZRPCWrite(function ($56) {
    return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($56));
  });
  var zrpcWriteFieldsNil = new ZRPCWriteFields(function (v) {
    return function (v1) {
      return Control_Category.identity(Control_Category.categoryFn);
    };
  });

  var zrpcWriteFields = function zrpcWriteFields(dict) {
    return dict.zrpcWriteFields;
  };

  var zrpcWriteRec = function zrpcWriteRec(dictRowToList) {
    return function (dictZRPCWriteFields) {
      return new ZRPCWrite(function (r) {
        var o = zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(r)(Foreign_Object.empty);
        return Data_Argonaut_Core.fromObject(o);
      });
    };
  };

  var zrpcWriteField = function zrpcWriteField(dict) {
    return dict.zrpcWriteField;
  };

  var zrpcWriteFieldsCons = function zrpcWriteFieldsCons(dictIsSymbol) {
    return function (dictZRPCWriteField) {
      return function (dictZRPCWriteFields) {
        return function (dictCons) {
          return new ZRPCWriteFields(function (v) {
            return function (r) {
              return function (prev) {
                var v1 = Record.get(dictIsSymbol)(dictCons)(Data_Symbol.SProxy.value)(r);
                var k = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);

                var next = function () {
                  var v2 = zrpcWriteField(dictZRPCWriteField)(v1);

                  if (v2 instanceof Data_Maybe.Just) {
                    return Foreign_Object.insert(k)(v2.value0)(prev);
                  }

                  ;

                  if (v2 instanceof Data_Maybe.Nothing) {
                    return prev;
                  }

                  ;
                  throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 157, column 14 - line 159, column 24): " + [v2.constructor.name]);
                }();

                return zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(r)(next);
              };
            };
          });
        };
      };
    };
  };

  var zrpcWrite = function zrpcWrite(dict) {
    return dict.zrpcWrite;
  };

  var zrpcWriteFieldId = function zrpcWriteFieldId(dictZRPCWrite) {
    return new ZRPCWriteField(function ($57) {
      return Data_Maybe.Just.create(zrpcWrite(dictZRPCWrite)($57));
    });
  };

  var zrpcWriteHugeNum = new ZRPCWrite(function ($58) {
    return zrpcWrite(zrpcWriteString)(Data_HugeNum.toString($58));
  });

  var zf = function zf(l) {
    return function (s) {
      var len = Data_String_CodePoints.length(s);
      var $43 = len >= l;

      if ($43) {
        return s;
      }

      ;
      return Data_Monoid.power(Data_Monoid.monoidString)("0")(l - len | 0) + s;
    };
  };

  var writeRepSum = function writeRepSum(dictZRPCWriteRep) {
    return function (dictZRPCWriteRep1) {
      return new ZRPCWriteRep(function (prefix) {
        return function (v) {
          if (v instanceof Data_Generic_Rep.Inl) {
            return zrpcWriteRep(dictZRPCWriteRep)(prefix)(v.value0);
          }

          ;

          if (v instanceof Data_Generic_Rep.Inr) {
            return zrpcWriteRep(dictZRPCWriteRep1)(prefix)(v.value0);
          }

          ;
          throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 184, column 1 - line 184, column 83): " + [prefix.constructor.name, v.constructor.name]);
        };
      });
    };
  };

  var unionTypeName = function unionTypeName(dictIsSymbol) {
    return function (prefix) {
      return function (sp) {
        var prefixLen = Data_String_CodePoints.length(prefix);
        var consName = Data_Symbol.reflectSymbol(dictIsSymbol)(sp);
        var p = Data_String_CodePoints.splitAt(prefixLen)(consName);
        var $48 = p.before === prefix;

        if ($48) {
          return p.after;
        }

        ;
        return consName;
      };
    };
  };

  var unionTypeObject = function unionTypeObject(dictIsSymbol) {
    return function (prefix) {
      return function (v) {
        return Foreign_Object.singleton("type")(Data_Argonaut_Core.fromString(unionTypeName(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value)));
      };
    };
  };

  var writeRepRecArg = function writeRepRecArg(dictIsSymbol) {
    return function (dictRowToList) {
      return function (dictZRPCWriteFields) {
        return function (dictLacks) {
          return new ZRPCWriteRep(function (prefix) {
            return function (v) {
              var init = unionTypeObject(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value);
              return zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(v)(init);
            };
          });
        };
      };
    };
  };

  var genericZRPCWrite = function genericZRPCWrite(dictConsPrefix) {
    return function (dictGeneric) {
      return function (dictZRPCWriteRep) {
        return function ($61) {
          return Data_Argonaut_Core.fromObject(zrpcWriteRep(dictZRPCWriteRep)(ZRPC_ConsPrefix.consPrefix(dictConsPrefix)(Type_Proxy["Proxy"].value))(Data_Generic_Rep.from(dictGeneric)($61)));
        };
      };
    };
  };

  var formatTime = function formatTime(time) {
    var s = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumSecond)(Data_Time.second(time))));
    var ms = zf(3)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMillisecond)(Data_Time.millisecond(time))));
    var m = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute)(Data_Time.minute(time))));
    var h = Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(time)));
    return h + (":" + (m + (":" + (s + ("." + ms)))));
  };

  var formatDate = function formatDate(date) {
    var y = Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(date)));
    var m = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(Data_Date.month(date))));
    var d = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(Data_Date.day(date))));
    return y + ("-" + (m + ("-" + d)));
  };

  var formatDateTime = function formatDateTime(ldt) {
    var tm = formatTime(Data_DateTime.time(ldt));
    var dt = formatDate(Data_DateTime.date(ldt));
    return dt + ("T" + (tm + "Z"));
  };

  var zrpcWriteDateTime = new ZRPCWrite(function ($63) {
    return zrpcWrite(zrpcWriteString)(formatDateTime($63));
  });
  exports["ZRPCWrite"] = ZRPCWrite;
  exports["zrpcWrite"] = zrpcWrite;
  exports["genericZRPCWrite"] = genericZRPCWrite;
  exports["ZRPCWriteField"] = ZRPCWriteField;
  exports["zrpcWriteField"] = zrpcWriteField;
  exports["ZRPCWriteFields"] = ZRPCWriteFields;
  exports["zrpcWriteFields"] = zrpcWriteFields;
  exports["ZRPCWriteRep"] = ZRPCWriteRep;
  exports["zrpcWriteRep"] = zrpcWriteRep;
  exports["zrpcWriteInt"] = zrpcWriteInt;
  exports["zrpcWriteString"] = zrpcWriteString;
  exports["zrpcWriteHugeNum"] = zrpcWriteHugeNum;
  exports["zrpcWriteDateTime"] = zrpcWriteDateTime;
  exports["zrpcWriteRec"] = zrpcWriteRec;
  exports["zrpcWriteFieldId"] = zrpcWriteFieldId;
  exports["zrpcWriteFieldsNil"] = zrpcWriteFieldsNil;
  exports["zrpcWriteFieldsCons"] = zrpcWriteFieldsCons;
  exports["writeRepRecArg"] = writeRepRecArg;
  exports["writeRepSum"] = writeRepSum;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.ApiClient.Utilities"] = $PS["ZAP.Dart.ApiClient.Utilities"] || {};
  var exports = $PS["ZAP.Dart.ApiClient.Utilities"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Either = $PS["Data.Either"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Show = $PS["Data.Show"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Foreign = $PS["Foreign"];
  var Milkis = $PS["Milkis"];
  var Milkis_Impl_Window = $PS["Milkis.Impl.Window"];
  var Type_Equality = $PS["Type.Equality"];
  var Type_Row_Homogeneous = $PS["Type.Row.Homogeneous"];
  var ZAP_Dart_Api_Types = $PS["ZAP.Dart.Api.Types"];
  var ZAP_Dart_Client_Constants = $PS["ZAP.Dart.Client.Constants"];
  var ZRPC_ZRPCRead = $PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = $PS["ZRPC.ZRPCWrite"];
  var zjson = Milkis.json;

  var readJson = function readJson(dictZRPCRead) {
    return function (json) {
      var v = Control_Monad_Except.runExcept(ZRPC_ZRPCRead.zrpcRead(dictZRPCRead)(json));

      if (v instanceof Data_Either.Right) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
      }

      ;

      if (v instanceof Data_Either.Left) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Unable to read: " + Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v.value0)));
      }

      ;
      throw new Error("Failed pattern match at ZAP.Dart.ApiClient.Utilities (line 58, column 17 - line 60, column 63): " + [v.constructor.name]);
    };
  };

  var handleRes = function handleRes(v) {
    if (v instanceof Data_Either.Right) {
      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
    }

    ;

    if (v instanceof Data_Either.Left) {
      if (v.value0 instanceof ZAP_Dart_Api_Types.UnknownError) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("An unknown error has occured."));
      }

      ;

      if (v.value0 instanceof ZAP_Dart_Api_Types.NotFound) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Not Found."));
      }

      ;

      if (v.value0 instanceof ZAP_Dart_Api_Types.InternalError) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Server error."));
      }

      ;

      if (v.value0 instanceof ZAP_Dart_Api_Types.BadRequest) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("BadRequest"));
      }

      ;
      throw new Error("Failed pattern match at ZAP.Dart.ApiClient.Utilities (line 48, column 24 - line 54, column 1): " + [v.value0.constructor.name]);
    }

    ;
    throw new Error("Failed pattern match at ZAP.Dart.ApiClient.Utilities (line 46, column 1 - line 46, column 48): " + [v.constructor.name]);
  };

  var fetch = function fetch(dictUnion) {
    return Milkis.fetch(Milkis_Impl_Window.windowFetch)(dictUnion);
  };

  var apiUrl = function apiUrl(path) {
    return Milkis.URL(ZAP_Dart_Client_Constants.apiUrl + path);
  };

  var post = function post(path) {
    return function (json) {
      return fetch()(apiUrl(path))({
        method: Milkis.postMethod,
        headers: Milkis.makeHeaders(Type_Row_Homogeneous.homogeneous()(Type_Row_Homogeneous.homogeneousRowListCons(Type_Row_Homogeneous.homogeneousRowListNil)(Type_Equality.refl)))({
          "Content-Type": "application/json"
        }),
        body: Data_Argonaut_Core.stringify(json)
      });
    };
  };

  var postApi = function postApi(dictZRPCWrite) {
    return function (dictZRPCRead) {
      return function (path) {
        return function (payload) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(post(path)(ZRPC_ZRPCWrite.zrpcWrite(dictZRPCWrite)(payload)))(zjson))(readJson(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(ZAP_Dart_Api_Types.zrpcReadResErr))(ZRPC_ZRPCRead.zrpcReadFieldId(dictZRPCRead)))))(handleRes);
        };
      };
    };
  };

  exports["postApi"] = postApi;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZRPC.Clients.Broker"] = $PS["ZRPC.Clients.Broker"] || {};
  var exports = $PS["ZRPC.Clients.Broker"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var ZRPC_ConsPrefix = $PS["ZRPC.ConsPrefix"];
  var ZRPC_ZRPCRead = $PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = $PS["ZRPC.ZRPCWrite"];

  var ClaimQrMethodQrData = function () {
    function ClaimQrMethodQrData(value0) {
      this.value0 = value0;
    }

    ;

    ClaimQrMethodQrData.create = function (value0) {
      return new ClaimQrMethodQrData(value0);
    };

    return ClaimQrMethodQrData;
  }();

  var ClaimQrMethodShortCode = function () {
    function ClaimQrMethodShortCode(value0) {
      this.value0 = value0;
    }

    ;

    ClaimQrMethodShortCode.create = function (value0) {
      return new ClaimQrMethodShortCode(value0);
    };

    return ClaimQrMethodShortCode;
  }();

  var ClaimQrMethodManualInput = function () {
    function ClaimQrMethodManualInput(value0) {
      this.value0 = value0;
    }

    ;

    ClaimQrMethodManualInput.create = function (value0) {
      return new ClaimQrMethodManualInput(value0);
    };

    return ClaimQrMethodManualInput;
  }();

  var ClaimErrorInvalidAuthCode = function () {
    function ClaimErrorInvalidAuthCode() {}

    ;
    ClaimErrorInvalidAuthCode.value = new ClaimErrorInvalidAuthCode();
    return ClaimErrorInvalidAuthCode;
  }();

  var ClaimErrorInvalidQrData = function () {
    function ClaimErrorInvalidQrData() {}

    ;
    ClaimErrorInvalidQrData.value = new ClaimErrorInvalidQrData();
    return ClaimErrorInvalidQrData;
  }();

  var ClaimErrorInvalidManualData = function () {
    function ClaimErrorInvalidManualData() {}

    ;
    ClaimErrorInvalidManualData.value = new ClaimErrorInvalidManualData();
    return ClaimErrorInvalidManualData;
  }();

  var ClaimErrorQrCodeNotFound = function () {
    function ClaimErrorQrCodeNotFound() {}

    ;
    ClaimErrorQrCodeNotFound.value = new ClaimErrorQrCodeNotFound();
    return ClaimErrorQrCodeNotFound;
  }();

  var ClaimErrorQrCodeMaxClaimsReached = function () {
    function ClaimErrorQrCodeMaxClaimsReached() {}

    ;
    ClaimErrorQrCodeMaxClaimsReached.value = new ClaimErrorQrCodeMaxClaimsReached();
    return ClaimErrorQrCodeMaxClaimsReached;
  }();

  var ClaimErrorQrCodeAlreadyClaimed = function () {
    function ClaimErrorQrCodeAlreadyClaimed() {}

    ;
    ClaimErrorQrCodeAlreadyClaimed.value = new ClaimErrorQrCodeAlreadyClaimed();
    return ClaimErrorQrCodeAlreadyClaimed;
  }();

  var ClaimErrorClaimOutsideValidityPeriod = function () {
    function ClaimErrorClaimOutsideValidityPeriod() {}

    ;
    ClaimErrorClaimOutsideValidityPeriod.value = new ClaimErrorClaimOutsideValidityPeriod();
    return ClaimErrorClaimOutsideValidityPeriod;
  }();

  var ClaimErrorUserExceededMaxClaimsForMerchant = function () {
    function ClaimErrorUserExceededMaxClaimsForMerchant() {}

    ;
    ClaimErrorUserExceededMaxClaimsForMerchant.value = new ClaimErrorUserExceededMaxClaimsForMerchant();
    return ClaimErrorUserExceededMaxClaimsForMerchant;
  }();

  var ClaimErrorUpdateQrCodeError = function () {
    function ClaimErrorUpdateQrCodeError() {}

    ;
    ClaimErrorUpdateQrCodeError.value = new ClaimErrorUpdateQrCodeError();
    return ClaimErrorUpdateQrCodeError;
  }();

  var ClaimErrorInvalidHolding = function () {
    function ClaimErrorInvalidHolding() {}

    ;
    ClaimErrorInvalidHolding.value = new ClaimErrorInvalidHolding();
    return ClaimErrorInvalidHolding;
  }();

  var ClaimErrorRegistrationError = function () {
    function ClaimErrorRegistrationError() {}

    ;
    ClaimErrorRegistrationError.value = new ClaimErrorRegistrationError();
    return ClaimErrorRegistrationError;
  }();

  var ClaimErrorUserNotFound = function () {
    function ClaimErrorUserNotFound() {}

    ;
    ClaimErrorUserNotFound.value = new ClaimErrorUserNotFound();
    return ClaimErrorUserNotFound;
  }();

  var genericClaimQrMethod = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ClaimQrMethodQrData) {
      return new Data_Generic_Rep.Inl(x.value0);
    }

    ;

    if (x instanceof ClaimQrMethodShortCode) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    }

    ;

    if (x instanceof ClaimQrMethodManualInput) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Broker (line 64, column 8 - line 64, column 64): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return new ClaimQrMethodQrData(x.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return new ClaimQrMethodShortCode(x.value0.value0);
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
      return new ClaimQrMethodManualInput(x.value0.value0);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Broker (line 64, column 8 - line 64, column 64): " + [x.constructor.name]);
  });
  var genericClaimError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ClaimErrorInvalidAuthCode) {
      return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    }

    ;

    if (x instanceof ClaimErrorInvalidQrData) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    }

    ;

    if (x instanceof ClaimErrorInvalidManualData) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    }

    ;

    if (x instanceof ClaimErrorQrCodeNotFound) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    }

    ;

    if (x instanceof ClaimErrorQrCodeMaxClaimsReached) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    }

    ;

    if (x instanceof ClaimErrorQrCodeAlreadyClaimed) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
    }

    ;

    if (x instanceof ClaimErrorClaimOutsideValidityPeriod) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    }

    ;

    if (x instanceof ClaimErrorUserExceededMaxClaimsForMerchant) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
    }

    ;

    if (x instanceof ClaimErrorUpdateQrCodeError) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))));
    }

    ;

    if (x instanceof ClaimErrorInvalidHolding) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))))));
    }

    ;

    if (x instanceof ClaimErrorRegistrationError) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))))));
    }

    ;

    if (x instanceof ClaimErrorUserNotFound) {
      return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))))))));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Broker (line 191, column 8 - line 191, column 58): " + [x.constructor.name]);
  }, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorInvalidAuthCode.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorInvalidQrData.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorInvalidManualData.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorQrCodeNotFound.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorQrCodeMaxClaimsReached.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorQrCodeAlreadyClaimed.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorClaimOutsideValidityPeriod.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorUserExceededMaxClaimsForMerchant.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorUpdateQrCodeError.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorInvalidHolding.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
      return ClaimErrorRegistrationError.value;
    }

    ;

    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr) {
      return ClaimErrorUserNotFound.value;
    }

    ;
    throw new Error("Failed pattern match at ZRPC.Clients.Broker (line 191, column 8 - line 191, column 58): " + [x.constructor.name]);
  });
  var consPrefixClaimQrMethod = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ClaimQrMethod";
  });
  var zrpcWriteClaimQrMethod = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixClaimQrMethod)(genericClaimQrMethod)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
      return "ClaimQrMethodQrData";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "value";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
      return "ClaimQrMethodShortCode";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "value";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
      return "ClaimQrMethodManualInput";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "amount";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "branchId";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "saleAt";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "shortCode";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())))(x);
  });
  var consPrefixClaimError = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ClaimError";
  });
  var zrpcReadClaimError = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixClaimError)(genericClaimError)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorInvalidAuthCode";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorInvalidQrData";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorInvalidManualData";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorQrCodeNotFound";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorQrCodeMaxClaimsReached";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorQrCodeAlreadyClaimed";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorClaimOutsideValidityPeriod";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorUserExceededMaxClaimsForMerchant";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorUpdateQrCodeError";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorInvalidHolding";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorRegistrationError";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
      return "ClaimErrorUserNotFound";
    }))))))))))))))(x);
  });
  exports["ClaimQrMethodQrData"] = ClaimQrMethodQrData;
  exports["ClaimQrMethodShortCode"] = ClaimQrMethodShortCode;
  exports["ClaimQrMethodManualInput"] = ClaimQrMethodManualInput;
  exports["ClaimErrorInvalidAuthCode"] = ClaimErrorInvalidAuthCode;
  exports["ClaimErrorInvalidQrData"] = ClaimErrorInvalidQrData;
  exports["ClaimErrorInvalidManualData"] = ClaimErrorInvalidManualData;
  exports["ClaimErrorQrCodeNotFound"] = ClaimErrorQrCodeNotFound;
  exports["ClaimErrorQrCodeMaxClaimsReached"] = ClaimErrorQrCodeMaxClaimsReached;
  exports["ClaimErrorQrCodeAlreadyClaimed"] = ClaimErrorQrCodeAlreadyClaimed;
  exports["ClaimErrorClaimOutsideValidityPeriod"] = ClaimErrorClaimOutsideValidityPeriod;
  exports["ClaimErrorUserExceededMaxClaimsForMerchant"] = ClaimErrorUserExceededMaxClaimsForMerchant;
  exports["ClaimErrorUpdateQrCodeError"] = ClaimErrorUpdateQrCodeError;
  exports["ClaimErrorInvalidHolding"] = ClaimErrorInvalidHolding;
  exports["ClaimErrorRegistrationError"] = ClaimErrorRegistrationError;
  exports["ClaimErrorUserNotFound"] = ClaimErrorUserNotFound;
  exports["genericClaimQrMethod"] = genericClaimQrMethod;
  exports["consPrefixClaimQrMethod"] = consPrefixClaimQrMethod;
  exports["zrpcWriteClaimQrMethod"] = zrpcWriteClaimQrMethod;
  exports["genericClaimError"] = genericClaimError;
  exports["consPrefixClaimError"] = consPrefixClaimError;
  exports["zrpcReadClaimError"] = zrpcReadClaimError;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.ApiClient.ClaimQr"] = $PS["ZAP.Dart.ApiClient.ClaimQr"] || {};
  var exports = $PS["ZAP.Dart.ApiClient.ClaimQr"];
  var Data_Symbol = $PS["Data.Symbol"];
  var ZAP_Dart_Api_Types = $PS["ZAP.Dart.Api.Types"];
  var ZAP_Dart_ApiClient_Utilities = $PS["ZAP.Dart.ApiClient.Utilities"];
  var ZRPC_Clients_Broker = $PS["ZRPC.Clients.Broker"];
  var ZRPC_ZRPCRead = $PS["ZRPC.ZRPCRead"];
  var ZRPC_ZRPCWrite = $PS["ZRPC.ZRPCWrite"];
  var claimQr = ZAP_Dart_ApiClient_Utilities.postApi(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claimMethod";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_Clients_Broker.zrpcWriteClaimQrMethod))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claimedAt";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "holdingId";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobileNumber";
  }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_Clients_Broker.zrpcReadClaimError))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZAP_Dart_Api_Types.zrpcReadClaim)))))("/claim");
  exports["claimQr"] = claimQr;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.Catalog"] = $PS["ZAP.Dart.Screens.Catalog"] || {};
  var exports = $PS["ZAP.Dart.Screens.Catalog"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var component = React_Basic.createComponent("Catalog");

  var catalog = function () {
    var render = function render(self) {
      return React_Basic_Native_Generated.view_([React_Basic_Native_Generated.text_([React_Basic_Native.string("Catalog goes here")])]);
    };

    var initialState = {
      foo: 0
    };
    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  }();

  exports["catalog"] = catalog;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Types"] = $PS["ZAP.Dart.Types"] || {};
  var exports = $PS["ZAP.Dart.Types"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];

  var PgSubmit = function () {
    function PgSubmit(value0) {
      this.value0 = value0;
    }

    ;

    PgSubmit.create = function (value0) {
      return new PgSubmit(value0);
    };

    return PgSubmit;
  }();

  var PgCancel = function () {
    function PgCancel() {}

    ;
    PgCancel.value = new PgCancel();
    return PgCancel;
  }();

  var PgBack = function () {
    function PgBack() {}

    ;
    PgBack.value = new PgBack();
    return PgBack;
  }();

  var NavNavigate = function () {
    function NavNavigate(value0) {
      this.value0 = value0;
    }

    ;

    NavNavigate.create = function (value0) {
      return new NavNavigate(value0);
    };

    return NavNavigate;
  }();

  var NavBack = function () {
    function NavBack() {}

    ;
    NavBack.value = new NavBack();
    return NavBack;
  }();

  var NavEff = function () {
    function NavEff(value0) {
      this.value0 = value0;
    }

    ;

    NavEff.create = function (value0) {
      return new NavEff(value0);
    };

    return NavEff;
  }();

  var NavAff = function () {
    function NavAff(value0) {
      this.value0 = value0;
    }

    ;

    NavAff.create = function (value0) {
      return new NavAff(value0);
    };

    return NavAff;
  }();

  var NavCustom = function () {
    function NavCustom(value0) {
      this.value0 = value0;
    }

    ;

    NavCustom.create = function (value0) {
      return new NavCustom(value0);
    };

    return NavCustom;
  }();

  var NavAction = function NavAction(x) {
    return x;
  };

  var MobileNumber = function MobileNumber(x) {
    return x;
  };

  var EPASubmitPin = function () {
    function EPASubmitPin(value0) {
      this.value0 = value0;
    }

    ;

    EPASubmitPin.create = function (value0) {
      return new EPASubmitPin(value0);
    };

    return EPASubmitPin;
  }();

  var EPAResetPin = function () {
    function EPAResetPin() {}

    ;
    EPAResetPin.value = new EPAResetPin();
    return EPAResetPin;
  }();

  var SNavPush = function () {
    function SNavPush(value0) {
      this.value0 = value0;
    }

    ;

    SNavPush.create = function (value0) {
      return new SNavPush(value0);
    };

    return SNavPush;
  }();

  var SNavReplace = function () {
    function SNavReplace(value0) {
      this.value0 = value0;
    }

    ;

    SNavReplace.create = function (value0) {
      return new SNavReplace(value0);
    };

    return SNavReplace;
  }();

  var SNavPop = function () {
    function SNavPop() {}

    ;
    SNavPop.value = new SNavPop();
    return SNavPop;
  }();

  var SNavPopToTop = function () {
    function SNavPopToTop() {}

    ;
    SNavPopToTop.value = new SNavPopToTop();
    return SNavPopToTop;
  }();

  var SNavDialog = function () {
    function SNavDialog(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    SNavDialog.create = function (value0) {
      return function (value1) {
        return new SNavDialog(value0, value1);
      };
    };

    return SNavDialog;
  }();

  var CMScanQR = function () {
    function CMScanQR() {}

    ;
    CMScanQR.value = new CMScanQR();
    return CMScanQR;
  }();

  var CMEnterShortCode = function () {
    function CMEnterShortCode() {}

    ;
    CMEnterShortCode.value = new CMEnterShortCode();
    return CMEnterShortCode;
  }();

  var QRCode = function () {
    function QRCode(value0) {
      this.value0 = value0;
    }

    ;

    QRCode.create = function (value0) {
      return new QRCode(value0);
    };

    return QRCode;
  }();

  var ShortCode = function () {
    function ShortCode(value0) {
      this.value0 = value0;
    }

    ;

    ShortCode.create = function (value0) {
      return new ShortCode(value0);
    };

    return ShortCode;
  }();

  var SQGoToEnterShortCodeScreen = function () {
    function SQGoToEnterShortCodeScreen() {}

    ;
    SQGoToEnterShortCodeScreen.value = new SQGoToEnterShortCodeScreen();
    return SQGoToEnterShortCodeScreen;
  }();

  var SQVal = function () {
    function SQVal(value0) {
      this.value0 = value0;
    }

    ;

    SQVal.create = function (value0) {
      return new SQVal(value0);
    };

    return SQVal;
  }();

  var ScreenRenderer = function ScreenRenderer(renderScreen) {
    this.renderScreen = renderScreen;
  };

  var renderScreen = function renderScreen(dict) {
    return dict.renderScreen;
  };

  var newtypeNavAction = new Data_Newtype.Newtype(function (n) {
    return n;
  }, NavAction);
  var monadNavAction = Control_Monad_Free.freeMonad;

  var liftN = function liftN($45) {
    return NavAction(Control_Monad_Free.liftF($45));
  };

  var monadEffectNavAction = new Effect_Class.MonadEffect(function () {
    return monadNavAction;
  }, function ($46) {
    return liftN(NavEff.create($46));
  });
  var monadAffNavAction = new Effect_Aff_Class.MonadAff(function () {
    return monadEffectNavAction;
  }, function (aff) {
    return NavAction(Control_Monad_Free.liftF(NavAff.create(aff)));
  });

  var navCustom = function navCustom($47) {
    return liftN(NavCustom.create($47));
  };

  var navPopToTop = navCustom(SNavPopToTop.value);

  var navPush = function navPush($48) {
    return navCustom(SNavPush.create($48));
  };

  var snavDialog = function snavDialog(opts) {
    return navCustom(new SNavDialog(opts, Data_Unit.unit));
  };

  var initNav = function initNav(initialScreen) {
    return function (initialCb) {
      return {
        initialScreen: initialScreen,
        initialCb: initialCb
      };
    };
  };

  var functorStackCustomAction = function functorStackCustomAction(dictFunctor) {
    return new Data_Functor.Functor(function (f) {
      return function (m) {
        if (m instanceof SNavPush) {
          return new SNavPush(Data_Functor.map(dictFunctor)(f)(m.value0));
        }

        ;

        if (m instanceof SNavReplace) {
          return new SNavReplace(Data_Functor.map(dictFunctor)(f)(m.value0));
        }

        ;

        if (m instanceof SNavPop) {
          return SNavPop.value;
        }

        ;

        if (m instanceof SNavPopToTop) {
          return SNavPopToTop.value;
        }

        ;

        if (m instanceof SNavDialog) {
          return new SNavDialog(m.value0, f(m.value1));
        }

        ;
        throw new Error("Failed pattern match at ZAP.Dart.Types (line 139, column 8 - line 139, column 97): " + [m.constructor.name]);
      };
    });
  };

  var functorNavAction$prime = function functorNavAction$prime(dictFunctor) {
    return function (dictFunctor1) {
      return new Data_Functor.Functor(function (f) {
        return function (m) {
          if (m instanceof NavNavigate) {
            return new NavNavigate(Data_Functor.map(dictFunctor1)(f)(m.value0));
          }

          ;

          if (m instanceof NavBack) {
            return NavBack.value;
          }

          ;

          if (m instanceof NavEff) {
            return new NavEff(Data_Functor.map(Effect.functorEffect)(f)(m.value0));
          }

          ;

          if (m instanceof NavAff) {
            return new NavAff(Data_Functor.map(Effect_Aff.functorAff)(f)(m.value0));
          }

          ;

          if (m instanceof NavCustom) {
            return new NavCustom(Data_Functor.map(dictFunctor)(f)(m.value0));
          }

          ;
          throw new Error("Failed pattern match at ZAP.Dart.Types (line 130, column 8 - line 130, column 107): " + [m.constructor.name]);
        };
      });
    };
  };

  var contramapPageProps = function contramapPageProps(f) {
    return function (pp) {
      return {
        onAction: function onAction(b$prime) {
          return pp.onAction(function () {
            if (b$prime instanceof PgSubmit) {
              return new PgSubmit(f(b$prime.value0));
            }

            ;

            if (b$prime instanceof PgCancel) {
              return PgCancel.value;
            }

            ;

            if (b$prime instanceof PgBack) {
              return PgBack.value;
            }

            ;
            throw new Error("Failed pattern match at ZAP.Dart.Types (line 69, column 34 - line 72, column 24): " + [b$prime.constructor.name]);
          }());
        },
        addListener: pp.addListener
      };
    };
  };

  var bindNavAction = Control_Monad_Free.freeBind;
  var applicativeNavAction = Control_Monad_Free.freeApplicative;
  exports["contramapPageProps"] = contramapPageProps;
  exports["PgSubmit"] = PgSubmit;
  exports["PgCancel"] = PgCancel;
  exports["PgBack"] = PgBack;
  exports["MobileNumber"] = MobileNumber;
  exports["CMScanQR"] = CMScanQR;
  exports["CMEnterShortCode"] = CMEnterShortCode;
  exports["QRCode"] = QRCode;
  exports["ShortCode"] = ShortCode;
  exports["SQGoToEnterShortCodeScreen"] = SQGoToEnterShortCodeScreen;
  exports["SQVal"] = SQVal;
  exports["EPASubmitPin"] = EPASubmitPin;
  exports["EPAResetPin"] = EPAResetPin;
  exports["NavNavigate"] = NavNavigate;
  exports["NavBack"] = NavBack;
  exports["NavEff"] = NavEff;
  exports["NavAff"] = NavAff;
  exports["NavCustom"] = NavCustom;
  exports["SNavPush"] = SNavPush;
  exports["SNavReplace"] = SNavReplace;
  exports["SNavPop"] = SNavPop;
  exports["SNavPopToTop"] = SNavPopToTop;
  exports["SNavDialog"] = SNavDialog;
  exports["NavAction"] = NavAction;
  exports["navCustom"] = navCustom;
  exports["navPush"] = navPush;
  exports["navPopToTop"] = navPopToTop;
  exports["snavDialog"] = snavDialog;
  exports["initNav"] = initNav;
  exports["ScreenRenderer"] = ScreenRenderer;
  exports["renderScreen"] = renderScreen;
  exports["functorNavAction'"] = functorNavAction$prime;
  exports["functorStackCustomAction"] = functorStackCustomAction;
  exports["newtypeNavAction"] = newtypeNavAction;
  exports["bindNavAction"] = bindNavAction;
  exports["applicativeNavAction"] = applicativeNavAction;
  exports["monadNavAction"] = monadNavAction;
  exports["monadEffectNavAction"] = monadEffectNavAction;
  exports["monadAffNavAction"] = monadAffNavAction;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.CreatePin"] = $PS["ZAP.Dart.Screens.CreatePin"] || {};
  var exports = $PS["ZAP.Dart.Screens.CreatePin"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];

  var PinStepCreate = function () {
    function PinStepCreate() {}

    ;
    PinStepCreate.value = new PinStepCreate();
    return PinStepCreate;
  }();

  var PinStepConfirm = function () {
    function PinStepConfirm() {}

    ;
    PinStepConfirm.value = new PinStepConfirm();
    return PinStepConfirm;
  }();

  var component = React_Basic.createComponent("CreatePin");

  var promptPin = function promptPin(ps) {
    var title = function () {
      if (ps instanceof PinStepCreate) {
        return "Create Your 6-Digit Pincode";
      }

      ;

      if (ps instanceof PinStepConfirm) {
        return "Confirm Your 6-Digit Pincode";
      }

      ;
      throw new Error("Failed pattern match at ZAP.Dart.Screens.CreatePin (line 40, column 13 - line 45, column 5): " + [ps.constructor.name]);
    }();

    var render = function render(self) {
      return React_Basic_Native_Generated.view_([React_Basic_Native_Generated.text_([React_Basic_Native.string(title)]), React_Basic_Native_Generated.textInput()({
        onChangeText: function onChangeText(text) {
          return self.setState(function (v) {
            return {
              pin: text
            };
          })();
        }
      }), React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(ZAP_Dart_Types.PgSubmit.create(self.state.pin))),
        title: "Submit"
      }), React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(ZAP_Dart_Types.PgCancel.value)),
        title: "Cancel"
      })]);
    };

    var initialState = {
      pin: ""
    };
    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  };

  var confirmPin = promptPin(PinStepConfirm.value);
  var createPin = promptPin(PinStepCreate.value);
  exports["createPin"] = createPin;
  exports["confirmPin"] = confirmPin;
})(PS);

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Styles.Colors"] = $PS["ZAP.Dart.Styles.Colors"] || {};
  var exports = $PS["ZAP.Dart.Styles.Colors"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var primary = React_Basic_Native_PropTypes_Color.rgb(52)(125)(182);
  var fgMuted = React_Basic_Native_PropTypes_Color.rgb(131)(131)(131);
  var fg = React_Basic_Native_PropTypes_Color.rgb(46)(46)(46);
  var bg = React_Basic_Native_PropTypes_Color.rgb(255)(255)(255);
  exports["primary"] = primary;
  exports["fg"] = fg;
  exports["fgMuted"] = fgMuted;
  exports["bg"] = bg;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Styles.Fonts"] = $PS["ZAP.Dart.Styles.Fonts"] || {};
  var exports = $PS["ZAP.Dart.Styles.Fonts"];
  var ubuntuMono = {
    regular: "UbuntuMono-Regular",
    italic: "UbuntuMono-Italic",
    bold: "UbuntuMono-Bold"
  };
  var proximaNova = {
    regular: "ProximaNova-Regular",
    italic: "ProximaNova-Italic",
    bold: "ProximaNova-Bold",
    semiBold: "ProximaNova-Semibold"
  };
  exports["proximaNova"] = proximaNova;
  exports["ubuntuMono"] = ubuntuMono;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Components.Button"] = $PS["ZAP.Dart.Components.Button"] || {};
  var exports = $PS["ZAP.Dart.Components.Button"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];
  var sheet = {
    container: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Styles_Colors.primary), React_Basic_Native_Styles.paddingHorizontal(12), React_Basic_Native_Styles.borderRadius(10), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch)]),
    disabled: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.opacity(0.2)]),
    title: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(20), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.color(React_Basic_Native_PropTypes_Color.white), React_Basic_Native_Styles.marginVertical(8), React_Basic_Native_Styles_Text.iosLetterSpacing(4)])
  };

  var button = function button(props) {
    return React_Basic_Native_Generated.touchableOpacity()({
      style: React_Basic_Native_Styles["styles'"]([sheet.container, function () {
        if (props.disabled) {
          return sheet.disabled;
        }

        ;
        return React_Basic_Native_Styles.staticStyles([]);
      }(), props.style]),
      disabled: props.disabled,
      onPress: props.onPress,
      children: [React_Basic_Native_Generated.text()({
        style: sheet.title,
        children: [React_Basic_Native.string(props.title)]
      })]
    });
  };

  exports["button"] = button;
  exports["sheet"] = sheet;
})(PS);

(function (exports) {
  var RNSVG = require("react-native-svg");

  exports._svg = RNSVG.Svg;
  exports._path = RNSVG.Path;
  exports._rect = RNSVG.Rect;
})(PS["ZAP.Dart.Svg"] = PS["ZAP.Dart.Svg"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Svg"] = $PS["ZAP.Dart.Svg"] || {};
  var exports = $PS["ZAP.Dart.Svg"];
  var $foreign = $PS["ZAP.Dart.Svg"];
  var React_Basic = $PS["React.Basic"];

  var svg = function svg(dictUnion) {
    return React_Basic.element($foreign["_svg"]);
  };

  var rect = function rect(dictUnion) {
    return React_Basic.element($foreign["_rect"]);
  };

  var path = function path(dictUnion) {
    return React_Basic.element($foreign["_path"]);
  };

  exports["svg"] = svg;
  exports["path"] = path;
  exports["rect"] = rect;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Icons.CheckBox"] = $PS["ZAP.Dart.Icons.CheckBox"] || {};
  var exports = $PS["ZAP.Dart.Icons.CheckBox"];
  var ZAP_Dart_Svg = $PS["ZAP.Dart.Svg"];

  var checkBoxBlank = function checkBoxBlank(props) {
    return ZAP_Dart_Svg.svg()({
      width: props.width,
      height: props.height,
      fill: props.color,
      viewBox: "0 0 24 24",
      children: [ZAP_Dart_Svg.path()({
        fill: "none",
        d: "M0 0h24v24H0V0z"
      }), ZAP_Dart_Svg.path()({
        d: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      })]
    });
  };

  var checkBox = function checkBox(props) {
    return ZAP_Dart_Svg.svg()({
      width: props.width,
      height: props.height,
      fill: props.color,
      viewBox: "0 0 24 24",
      children: [ZAP_Dart_Svg.path()({
        fill: "none",
        d: "M0 0h24v24H0V0z"
      }), ZAP_Dart_Svg.path()({
        d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"
      })]
    });
  };

  exports["checkBox"] = checkBox;
  exports["checkBoxBlank"] = checkBoxBlank;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Components.CheckBox"] = $PS["ZAP.Dart.Components.CheckBox"] || {};
  var exports = $PS["ZAP.Dart.Components.CheckBox"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var ZAP_Dart_Icons_CheckBox = $PS["ZAP.Dart.Icons.CheckBox"];
  var component = React_Basic.createComponent("CheckBox");

  var checkBox = function () {
    var renderIconCheckBox = function () {
      var iconProps = {
        width: 20,
        height: 20,
        color: React_Basic_Native_PropTypes_Color.black
      };
      return function (v) {
        if (v) {
          return ZAP_Dart_Icons_CheckBox.checkBox(iconProps);
        }

        ;
        return ZAP_Dart_Icons_CheckBox.checkBoxBlank(iconProps);
      };
    }();

    var render = function render(props) {
      return React_Basic_Native_Generated.touchableOpacity()({
        onPress: props.onPress,
        children: [renderIconCheckBox(props.isChecked)]
      });
    };

    return React_Basic.makeStateless(component)(render);
  }();

  exports["checkBox"] = checkBox;
})(PS);

(function (exports) {
  exports.unileverLogoWhite = require('../../src/assets/images/unilever_logo_white.png');
  exports.unileverLogoColored = require('../../src/assets/images/unilever_logo_colored.png');
  exports.enterCode = require('../../src/assets/images/entercode.png');
})(PS["ZAP.Dart.Assets"] = PS["ZAP.Dart.Assets"] || {});

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Assets"] = $PS["ZAP.Dart.Assets"] || {};
  var exports = $PS["ZAP.Dart.Assets"];
  var $foreign = $PS["ZAP.Dart.Assets"];
  exports["unileverLogoWhite"] = $foreign.unileverLogoWhite;
  exports["unileverLogoColored"] = $foreign.unileverLogoColored;
  exports["enterCode"] = $foreign.enterCode;
})(PS);

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Logo"] = $PS["ZAP.Dart.Logo"] || {};
  var exports = $PS["ZAP.Dart.Logo"];
  var ZAP_Dart_Assets = $PS["ZAP.Dart.Assets"];
  var whiteLogo = ZAP_Dart_Assets.unileverLogoWhite;
  var coloredLogo = ZAP_Dart_Assets.unileverLogoColored;
  exports["coloredLogo"] = coloredLogo;
  exports["whiteLogo"] = whiteLogo;
})(PS);

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Components.ColoredLogo"] = $PS["ZAP.Dart.Components.ColoredLogo"] || {};
  var exports = $PS["ZAP.Dart.Components.ColoredLogo"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var ZAP_Dart_Logo = $PS["ZAP.Dart.Logo"];
  var sheet = {
    logoContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.width("100%"), React_Basic_Native_Styles.height(72)]),
    logo: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.width("100%"), React_Basic_Native_Styles.height("100%"), React_Basic_Native_Styles.resizeMode(React_Basic_Native_Styles.contain)])
  };
  var coloredLogo = React_Basic_Native_Generated.view()({
    style: sheet.logoContainer,
    children: [React_Basic_Native_Generated.image()({
      style: sheet.logo,
      source: ZAP_Dart_Logo.coloredLogo
    })]
  });
  exports["coloredLogo"] = coloredLogo;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Components.DashedInput"] = $PS["ZAP.Dart.Components.DashedInput"] || {};
  var exports = $PS["ZAP.Dart.Components.DashedInput"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Array = $PS["Data.Array"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var Data_String_Regex = $PS["Data.String.Regex"];
  var Data_String_Regex_Flags = $PS["Data.String.Regex.Flags"];
  var Data_String_Regex_Unsafe = $PS["Data.String.Regex.Unsafe"];
  var Effect = $PS["Effect"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ReactNative_TextInput = $PS["ReactNative.TextInput"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];

  var Default = function () {
    function Default() {}

    ;
    Default.value = new Default();
    return Default;
  }();

  var Numeric = function () {
    function Numeric() {}

    ;
    Numeric.value = new Numeric();
    return Numeric;
  }();

  var AlphaNumeric = function () {
    function AlphaNumeric() {}

    ;
    AlphaNumeric.value = new AlphaNumeric();
    return AlphaNumeric;
  }();

  var sheet = {
    input: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.ubuntuMono.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter))]),
    dashedLineContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row)]),
    dashedLine: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.borderTopColor(React_Basic_Native_PropTypes_Color.black), React_Basic_Native_Styles.borderTopWidth(1)])
  };

  var getDInputProps = function () {
    var numericRegex = Data_String_Regex_Unsafe.unsafeRegex("^[0-9]*$")(Data_String_Regex_Flags.global);
    var alphaNumericRegex = Data_String_Regex_Unsafe.unsafeRegex("^[a-zA-Z0-9]*$")(Data_String_Regex_Flags.global);
    return function (v) {
      if (v instanceof Default) {
        return {
          keyboardType: ReactNative_TextInput["default"],
          validate: function validate(v1) {
            return true;
          }
        };
      }

      ;

      if (v instanceof Numeric) {
        return {
          keyboardType: ReactNative_TextInput.phonePad,
          validate: function validate(text) {
            return Data_String_Regex.test(numericRegex)(text);
          }
        };
      }

      ;

      if (v instanceof AlphaNumeric) {
        return {
          keyboardType: ReactNative_TextInput["default"],
          validate: function validate(text) {
            return Data_String_Regex.test(alphaNumericRegex)(text);
          }
        };
      }

      ;
      throw new Error("Failed pattern match at ZAP.Dart.Components.DashedInput (line 104, column 18 - line 116, column 6): " + [v.constructor.name]);
    };
  }();

  var component = React_Basic.createComponent("DashedInput");

  var dashedInput = function () {
    var fontWidth = function fontWidth(n) {
      return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(8 * n | 0)(16);
    };

    var totalFontWidth = function totalFontWidth(n) {
      return function (s) {
        return function (l) {
          return ((fontWidth(n) + s | 0) * l | 0) + 2 | 0;
        };
      };
    };

    var render = function render(props) {
      var dInputProps = getDInputProps(props.inputType);
      return React_Basic_Native_Generated.view()({
        style: React_Basic_Native_Styles["styles'"]([]),
        children: [React_Basic_Native_Generated.textInput()({
          style: React_Basic_Native_Styles["styles'"]([sheet.input, React_Basic_Native_Styles.styles([React_Basic_Native_Styles_Text.fontSize(props.fontSize), React_Basic_Native_Styles_Text.iosLetterSpacing(props.letterSpacing), React_Basic_Native_Styles.width(totalFontWidth(props.fontSize)(props.letterSpacing)(props.length))])]),
          keyboardType: dInputProps.keyboardType,
          autoCapitalize: ReactNative_TextInput.none,
          spellCheck: false,
          autoCorrect: false,
          value: props.value,
          onChangeText: function onChangeText(v) {
            return Control_Applicative.when(Effect.applicativeEffect)(Data_String_CodePoints.length(v) <= props.length && dInputProps.validate(v))(props.onChange(v))();
          }
        }), React_Basic_Native_Generated.view()({
          style: sheet.dashedLineContainer,
          children: Data_Functor.mapFlipped(Data_Functor.functorArray)(Data_Array.range(1)(props.length))(function (i) {
            return React_Basic_Native_Generated.view()({
              style: React_Basic_Native_Styles["styles'"]([sheet.dashedLine, React_Basic_Native_Styles.styles([React_Basic_Native_Styles.marginRight(props.letterSpacing), React_Basic_Native_Styles.width(fontWidth(props.fontSize))])]),
              key: Data_Show.show(Data_Show.showInt)(i)
            });
          })
        })]
      });
    };

    return React_Basic.makeStateless(component)(render);
  }();

  exports["dashedInput"] = dashedInput;
  exports["Default"] = Default;
  exports["Numeric"] = Numeric;
  exports["AlphaNumeric"] = AlphaNumeric;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Icons.Phone"] = $PS["ZAP.Dart.Icons.Phone"] || {};
  var exports = $PS["ZAP.Dart.Icons.Phone"];
  var ZAP_Dart_Svg = $PS["ZAP.Dart.Svg"];

  var phone = function phone(props) {
    return ZAP_Dart_Svg.svg()({
      width: props.width,
      height: props.height,
      fill: props.color,
      viewBox: "0 0 18 31",
      children: [ZAP_Dart_Svg.path()({
        d: "M14.803.729H2.953A2.954 2.954 0 0 0 0 3.682v23.684a2.956 2.956 0 0 0 2.953 2.957H14.8a2.956 2.956 0 0 0 2.956-2.954V3.682A2.954 2.954 0 0 0 14.803.73zM8.878 28.843c-1.02 0-1.851-.662-1.851-1.48s.827-1.48 1.851-1.48c1.02 0 1.851.662 1.851 1.48 0 .819-.83 1.48-1.85 1.48zm5.919-4.44H2.959V3.689h11.838v20.716z"
      })]
    });
  };

  exports["phone"] = phone;
})(PS);

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Screens.EnterMobile"] = $PS["ZAP.Dart.Screens.EnterMobile"] || {};
  var exports = $PS["ZAP.Dart.Screens.EnterMobile"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ReactNative_Linking = $PS["ReactNative.Linking"];
  var ReactNative_Platform = $PS["ReactNative.Platform"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var ZAP_Dart_Components_Button = $PS["ZAP.Dart.Components.Button"];
  var ZAP_Dart_Components_CheckBox = $PS["ZAP.Dart.Components.CheckBox"];
  var ZAP_Dart_Components_ColoredLogo = $PS["ZAP.Dart.Components.ColoredLogo"];
  var ZAP_Dart_Components_DashedInput = $PS["ZAP.Dart.Components.DashedInput"];
  var ZAP_Dart_Icons_Phone = $PS["ZAP.Dart.Icons.Phone"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var sheet = {
    container: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginHorizontal(40), React_Basic_Native_Styles.marginTop(24)]),
    instructionText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginTop(60), React_Basic_Native_Styles_Text.fontSize(24), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg), React_Basic_Native_Styles_Text.iosLetterSpacing(2), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign))]),
    mobileInputContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginVertical(60)]),
    mobileInputPrefix: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(24), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.ubuntuMono.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg), React_Basic_Native_Styles_Text.iosLetterSpacing(4), React_Basic_Native_Styles.marginHorizontal(12)]),
    centerRow: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter))]),
    checkBoxContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginRight(4)]),
    tosConfirmationContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.marginBottom(36), React_Basic_Native_Styles.maxWidth(360)]),
    tosLink: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.primary)]),
    tosText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.fontSize(14)]),
    regularText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg), React_Basic_Native_Styles_Text.fontSize(16), React_Basic_Native_Styles_Text.iosLetterSpacing(1)]),
    submitButton: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.maxWidth(360), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width("100%")])
  };
  var component = React_Basic.createComponent("EnterMobile");

  var enterMobile = function () {
    var initialState = {
      mobileNumber: "",
      isTosChecked: false
    };

    var handleLink = function handleLink(url) {
      return React_Basic_Events.handler_(function () {
        if (ReactNative_Platform.os instanceof ReactNative_Platform.Web) {
          return Data_Functor["void"](Effect.functorEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.open(url)("_blank")("")));
        }

        ;
        return Effect_Aff.runAff_(function (v) {
          if (v instanceof Data_Either.Right && v.value0) {
            return ReactNative_Linking.openURL(url);
          }

          ;
          return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        })(ReactNative_Linking.canOpenURL(url));
      }());
    };

    var render = function render(self) {
      return React_Basic_Native_Generated.view()({
        style: sheet.container,
        children: [ZAP_Dart_Components_ColoredLogo.coloredLogo, React_Basic_Native_Generated.text()({
          style: sheet.instructionText,
          children: [React_Basic_Native.string("Enter mobile number")]
        }), React_Basic_Native_Generated.view()({
          style: sheet.mobileInputContainer,
          children: [ZAP_Dart_Icons_Phone.phone({
            width: 18,
            height: 31,
            color: React_Basic_Native_PropTypes_Color.black
          }), React_Basic_Native_Generated.text()({
            style: sheet.mobileInputPrefix,
            children: [React_Basic_Native.string("+63")]
          }), ZAP_Dart_Components_DashedInput.dashedInput({
            value: self.state.mobileNumber,
            length: 10,
            fontSize: 24,
            letterSpacing: 4,
            inputType: ZAP_Dart_Components_DashedInput.Numeric.value,
            onChange: function onChange(text) {
              return self.setState(function (v) {
                return {
                  mobileNumber: text,
                  isTosChecked: v.isTosChecked
                };
              });
            }
          })]
        }), React_Basic_Native_Generated.view()({
          style: sheet.tosConfirmationContainer,
          children: [React_Basic_Native_Generated.view()({
            style: sheet.checkBoxContainer,
            children: [ZAP_Dart_Components_CheckBox.checkBox({
              isChecked: self.state.isTosChecked,
              onPress: React_Basic_Events.handler_(self.setState(function (s) {
                return {
                  mobileNumber: s.mobileNumber,
                  isTosChecked: !s.isTosChecked
                };
              }))
            })]
          }), React_Basic_Native_Generated.text()({
            style: sheet.tosText,
            children: [React_Basic_Native.string("By registering, I agree to receive notifications from trusted Unilever brands and programs. Click to read "), React_Basic_Native_Generated.text()({
              style: sheet.tosLink,
              onPress: handleLink("https://www.unilever.com.ph/privacy-policy.html"),
              children: [React_Basic_Native.string("Unilever Privacy Policy.")]
            })]
          })]
        }), ZAP_Dart_Components_Button.button({
          style: sheet.submitButton,
          disabled: !self.state.isTosChecked || Data_String_CodePoints.length(self.state.mobileNumber) < 10,
          onPress: React_Basic_Events.handler_(self.props.onAction(new ZAP_Dart_Types.PgSubmit("63" + self.state.mobileNumber))),
          title: "SUBMIT"
        })]
      });
    };

    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  }();

  exports["enterMobile"] = enterMobile;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.EnterNumber"] = $PS["ZAP.Dart.Screens.EnterNumber"] || {};
  var exports = $PS["ZAP.Dart.Screens.EnterNumber"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var component = React_Basic.createComponent("EnterNumber");

  var enterNumber = function () {
    var render = function render(self) {
      return React_Basic_Native_Generated.view_([React_Basic_Native_Generated.text_([React_Basic_Native.string("Enter Your Number")]), React_Basic_Native_Generated.textInput()({
        onChangeText: function onChangeText(text) {
          return self.setState(function (s) {
            return {
              mobileNumber: text
            };
          })();
        }
      }), React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(ZAP_Dart_Types.PgSubmit.create(ZAP_Dart_Types.MobileNumber(self.state.mobileNumber)))),
        title: "Submit"
      }), React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(ZAP_Dart_Types.PgCancel.value)),
        title: "Cancel"
      })]);
    };

    var initialState = {
      mobileNumber: ""
    };
    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  }();

  exports["enterNumber"] = enterNumber;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.EnterPin"] = $PS["ZAP.Dart.Screens.EnterPin"] || {};
  var exports = $PS["ZAP.Dart.Screens.EnterPin"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var component = React_Basic.createComponent("EnterPin");

  var enterPin = function () {
    var render = function render(self) {
      return React_Basic_Native_Generated.view_([React_Basic_Native_Generated.text_([React_Basic_Native.string("Enter Your 6-Digit Pincode")]), React_Basic_Native_Generated.textInput()({
        onChangeText: function onChangeText(text) {
          return self.setState(function (v) {
            return {
              pin: text
            };
          })();
        }
      }), React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(new ZAP_Dart_Types.PgSubmit(ZAP_Dart_Types.EPAResetPin.value))),
        title: "Reset"
      }), React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(ZAP_Dart_Types.PgSubmit.create(ZAP_Dart_Types.EPASubmitPin.create(self.state.pin)))),
        title: "Submit"
      }), React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(ZAP_Dart_Types.PgCancel.value)),
        title: "Cancel"
      })]);
    };

    var initialState = {
      pin: ""
    };
    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  }();

  exports["enterPin"] = enterPin;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.EnterShortCode"] = $PS["ZAP.Dart.Screens.EnterShortCode"] || {};
  var exports = $PS["ZAP.Dart.Screens.EnterShortCode"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ZAP_Dart_Assets = $PS["ZAP.Dart.Assets"];
  var ZAP_Dart_Components_Button = $PS["ZAP.Dart.Components.Button"];
  var ZAP_Dart_Components_ColoredLogo = $PS["ZAP.Dart.Components.ColoredLogo"];
  var ZAP_Dart_Components_DashedInput = $PS["ZAP.Dart.Components.DashedInput"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var sheet = {
    container: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.marginTop(24), React_Basic_Native_Styles.paddingHorizontal(40), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter))]),
    enterCodeImage: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.width(121), React_Basic_Native_Styles.height(137), React_Basic_Native_Styles.marginVertical(40)]),
    instructionText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginTop(40), React_Basic_Native_Styles_Text.fontSize(24), React_Basic_Native_Styles_Text.iosLetterSpacing(2), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.marginBottom(40), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg)]),
    submitButton: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.maxWidth(360), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width("100%")])
  };
  var component = React_Basic.createComponent("EnterShortCode");

  var enterShortCode = function () {
    var render = function render(self) {
      return React_Basic_Native_Generated.view()({
        style: sheet.container,
        children: [ZAP_Dart_Components_ColoredLogo.coloredLogo, React_Basic_Native_Generated.text()({
          style: sheet.instructionText,
          children: [React_Basic_Native.string("Input the code found on the packaging")]
        }), ZAP_Dart_Components_DashedInput.dashedInput({
          value: self.state.shortCode,
          length: 8,
          fontSize: 32,
          letterSpacing: 16,
          inputType: ZAP_Dart_Components_DashedInput.AlphaNumeric.value,
          onChange: function onChange(text) {
            return self.setState(function (v) {
              return {
                shortCode: text
              };
            });
          }
        }), React_Basic_Native_Generated.image()({
          source: ZAP_Dart_Assets.enterCode,
          style: sheet.enterCodeImage
        }), ZAP_Dart_Components_Button.button({
          style: sheet.submitButton,
          disabled: Data_String_CodePoints.length(self.state.shortCode) < 8,
          onPress: React_Basic_Events.handler_(self.props.onAction(new ZAP_Dart_Types.PgSubmit(new ZAP_Dart_Types.ShortCode(self.state.shortCode)))),
          title: "SUBMIT"
        })]
      });
    };

    var initialState = {
      shortCode: ""
    };
    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  }();

  exports["enterShortCode"] = enterShortCode;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.GetStarted"] = $PS["ZAP.Dart.Screens.GetStarted"] || {};
  var exports = $PS["ZAP.Dart.Screens.GetStarted"];
  var Data_Unit = $PS["Data.Unit"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var component = React_Basic.createComponent("GetStarted");

  var getStarted = function () {
    var render = function render(self) {
      return React_Basic_Native_Generated.view_([React_Basic_Native_Generated.button()({
        onPress: React_Basic_Events.handler_(self.props.onAction(new ZAP_Dart_Types.PgSubmit(Data_Unit.unit))),
        title: "Get Started"
      })]);
    };

    var initialState = {
      foo: 0
    };
    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  }();

  exports["getStarted"] = getStarted;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.Receipt"] = $PS["ZAP.Dart.Screens.Receipt"] || {};
  var exports = $PS["ZAP.Dart.Screens.Receipt"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HugeNum = $PS["Data.HugeNum"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Number_Format = $PS["Data.Number.Format"];
  var Data_Show = $PS["Data.Show"];
  var Data_Unit = $PS["Data.Unit"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ZAP_Dart_Api_Types = $PS["ZAP.Dart.Api.Types"];
  var ZAP_Dart_Components_Button = $PS["ZAP.Dart.Components.Button"];
  var ZAP_Dart_Components_ColoredLogo = $PS["ZAP.Dart.Components.ColoredLogo"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var sheet = {
    container: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.marginTop(24), React_Basic_Native_Styles.paddingHorizontal(40)]),
    receiptsListContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginBottom(72)]),
    receiptContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginTop(56)]),
    receiptTop: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(24), React_Basic_Native_Styles.marginBottom(16), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.iosLetterSpacing(2.4), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg)]),
    receiptPoints: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(70), React_Basic_Native_Styles.marginBottom(16), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles_Text.iosLetterSpacing(7), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg)]),
    receiptCurrencyName: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(24), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.iosLetterSpacing(2.4), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg)]),
    receiptCouponId: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(70), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles_Text.iosLetterSpacing(7), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg)]),
    submitButton: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.maxWidth(360), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width("100%")])
  };
  var component = React_Basic.createComponent("Receipt");

  var receipt = function receipt(method) {
    return function (claims) {
      var receipts = Data_Functor.mapFlipped(Data_Functor.functorArray)(claims)(function (v) {
        if (v instanceof ZAP_Dart_Api_Types.Points) {
          return React_Basic_Native_Generated.view()({
            style: sheet.receiptContainer,
            children: [React_Basic_Native_Generated.text()({
              style: sheet.receiptTop,
              children: [React_Basic_Native.string("You have earned")]
            }), React_Basic_Native_Generated.text()({
              style: sheet.receiptPoints,
              children: [React_Basic_Native.string(Data_Number_Format.toStringWith(Data_Number_Format.fixed(2))(Data_HugeNum.toNumber(v.value0.earned)))]
            }), React_Basic_Native_Generated.text()({
              style: sheet.receiptCurrencyName,
              children: [React_Basic_Native.string(Data_Maybe.fromMaybe("points")(v.value0.currencyName) + "!")]
            })]
          });
        }

        ;

        if (v instanceof ZAP_Dart_Api_Types.Coupon) {
          return React_Basic_Native_Generated.view()({
            style: sheet.receiptContainer,
            children: [React_Basic_Native_Generated.text()({
              style: sheet.receiptTop,
              children: [React_Basic_Native.string("You have earned a coupon:")]
            }), React_Basic_Native_Generated.text()({
              style: sheet.receiptCouponId,
              children: [React_Basic_Native.string(Data_Show.show(Data_Show.showInt)(v.value0.couponId))]
            })]
          });
        }

        ;
        throw new Error("Failed pattern match at ZAP.Dart.Screens.Receipt (line 57, column 27 - line 99, column 10): " + [v.constructor.name]);
      });

      var render = function render(self) {
        return React_Basic_Native_Generated.view()({
          style: sheet.container,
          children: [ZAP_Dart_Components_ColoredLogo.coloredLogo, React_Basic_Native_Generated.view()({
            style: sheet.receiptsListContainer,
            children: receipts
          }), ZAP_Dart_Components_Button.button({
            style: sheet.submitButton,
            disabled: false,
            title: function () {
              if (method instanceof ZAP_Dart_Types.CMScanQR) {
                return "SCAN ANOTHER";
              }

              ;

              if (method instanceof ZAP_Dart_Types.CMEnterShortCode) {
                return "INPUT ANOTHER";
              }

              ;
              throw new Error("Failed pattern match at ZAP.Dart.Screens.Receipt (line 47, column 20 - line 50, column 11): " + [method.constructor.name]);
            }(),
            onPress: React_Basic_Events.handler_(self.props.onAction(new ZAP_Dart_Types.PgSubmit(Data_Unit.unit)))
          })]
        });
      };

      return React_Basic.make()(component)({
        initialState: Data_Unit.unit,
        render: render
      });
    };
  };

  exports["receipt"] = receipt;
})(PS);

(function (exports) {
  var React = require("react");

  var QrReader = require("react-qr-reader");

  exports._qrReader = function (props) {
    return React.createElement(QrReader, Object.assign({
      // todo: fork qr repo to expose width and height properties of video
      className: "qrscanner"
    }, props, {
      onScan: function onScan(result) {
        if (result) {
          props.onScan(result)();
        }
      },
      onError: function onError(error) {
        props.onError(error)();
      }
    }));
  };
})(PS["ZAP.Dart.Components.QrScanner"] = PS["ZAP.Dart.Components.QrScanner"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Utilities.Optional"] = $PS["ZAP.Dart.Utilities.Optional"] || {};
  var exports = $PS["ZAP.Dart.Utilities.Optional"];
  var Optional = {};

  var srInst = function srInst(dictUnion) {
    return Optional;
  };

  exports["Optional"] = Optional;
  exports["srInst"] = srInst;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Components.QrScanner"] = $PS["ZAP.Dart.Components.QrScanner"] || {};
  var exports = $PS["ZAP.Dart.Components.QrScanner"];
  var $foreign = $PS["ZAP.Dart.Components.QrScanner"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var ReactNative_Platform = $PS["ReactNative.Platform"];
  var Record = $PS["Record"];
  var ZAP_Dart_Utilities_Optional = $PS["ZAP.Dart.Utilities.Optional"];

  var qrReader = function qrReader(dictOptional) {
    return React_Basic.element($foreign["_qrReader"]);
  };

  var qrScanner = function qrScanner(props) {
    if (ReactNative_Platform.os instanceof ReactNative_Platform.Web) {
      return qrReader(ZAP_Dart_Utilities_Optional.srInst())(Record.merge()()(props)({
        showViewFinder: false,
        delay: 300
      }));
    }

    ;
    return React_Basic_Native_Generated.view_([]);
  };

  exports["qrScanner"] = qrScanner;
})(PS);

(function (exports) {
  var RNavNative = require('@react-navigation/native');

  exports.createAppContainer = RNavNative.createAppContainer;

  var RNavStack = require('react-navigation-stack');

  exports._createStackNavigator2 = RNavStack.createStackNavigator;
})(PS["ZAP.Dart.Navigator.StackNavigator"] = PS["ZAP.Dart.Navigator.StackNavigator"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Navigator.StackNavigator"] = $PS["ZAP.Dart.Navigator.StackNavigator"] || {};
  var exports = $PS["ZAP.Dart.Navigator.StackNavigator"];
  var $foreign = $PS["ZAP.Dart.Navigator.StackNavigator"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];

  var DidBlur = function () {
    function DidBlur() {}

    ;
    DidBlur.value = new DidBlur();
    return DidBlur;
  }();

  var WillFocus = function () {
    function WillFocus() {}

    ;
    WillFocus.value = new WillFocus();
    return WillFocus;
  }();

  var willFocusListenerEvent = "willFocus";

  var navigationScreenRouteConfig = function navigationScreenRouteConfig(dictUnion) {
    return Unsafe_Coerce.unsafeCoerce;
  };

  var didBlurListenerEvent = "didBlur";

  var createStackNavigator = function createStackNavigator(dictUnion) {
    return function (m) {
      return function (cfg) {
        return $foreign["_createStackNavigator2"](m, cfg);
      };
    };
  };

  exports["navigationScreenRouteConfig"] = navigationScreenRouteConfig;
  exports["willFocusListenerEvent"] = willFocusListenerEvent;
  exports["didBlurListenerEvent"] = didBlurListenerEvent;
  exports["DidBlur"] = DidBlur;
  exports["WillFocus"] = WillFocus;
  exports["createStackNavigator"] = createStackNavigator;
  exports["createAppContainer"] = $foreign.createAppContainer;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.ScanQr"] = $PS["ZAP.Dart.Screens.ScanQr"] || {};
  var exports = $PS["ZAP.Dart.Screens.ScanQr"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Effect = $PS["Effect"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ReactNative_Dimensions = $PS["ReactNative.Dimensions"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var ZAP_Dart_Components_QrScanner = $PS["ZAP.Dart.Components.QrScanner"];
  var ZAP_Dart_Navigator_StackNavigator = $PS["ZAP.Dart.Navigator.StackNavigator"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var sheet = {
    container: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.black)]),
    rectangle: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter))]),
    rectangleBorder: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.width("100%"), React_Basic_Native_Styles.height("100%"), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.top(0), React_Basic_Native_Styles.left(0), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.2)), React_Basic_Native_Styles.borderWidth(4)]),
    corner: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.width(47), React_Basic_Native_Styles.height(47), React_Basic_Native_Styles.borderStyle(React_Basic_Native_Styles.solid), React_Basic_Native_Styles.borderColor(ZAP_Dart_Styles_Colors.primary)]),
    top: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.top(0), React_Basic_Native_Styles.borderTopWidth(6)]),
    bottom: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.bottom(0), React_Basic_Native_Styles.borderBottomWidth(6)]),
    left: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.left(0), React_Basic_Native_Styles.borderLeftWidth(6)]),
    right: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.right(0), React_Basic_Native_Styles.borderRightWidth(6)]),
    bottomTextContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginTop(18), React_Basic_Native_Styles.marginBottom(32)]),
    overlayContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.transparent), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter))]),
    rectangleWrapper: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row)]),
    overlay: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.2))]),
    overlayTop: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.height(52), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.flexEnd(React_Basic_Native_Styles_Flex.justifyContentFlexSE))]),
    overlayLeft: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.width(28)]),
    overlayRight: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.width(28)]),
    enterCodeButtonLabel: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginBottom(16), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.color(React_Basic_Native_PropTypes_Color.white), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular)]),
    enterCodeButton: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.primary), React_Basic_Native_Styles_Text.fontSize(20), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign))])
  };
  var component = React_Basic.createComponent("ScanQr");

  var scanQr = function () {
    var render = function render(v) {
      var deviceD = ReactNative_Dimensions.get("window");
      return React_Basic_Native_Generated.view()({
        style: sheet.container,
        children: [function () {
          if (v.state.showQrScanner) {
            return ZAP_Dart_Components_QrScanner.qrScanner({
              style: React_Basic_Native_Styles.styles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(deviceD.width), React_Basic_Native_Styles.height(deviceD.height), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute)]),
              onScan: function onScan(d) {
                return v.props.onAction(new ZAP_Dart_Types.PgSubmit(new ZAP_Dart_Types.SQVal(new ZAP_Dart_Types.QRCode(d))));
              },
              onError: function onError(v1) {
                return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("Cannot Load QR Scanner"));
              }
            });
          }

          ;
          return Data_Monoid.mempty(React_Basic.monoidJSX);
        }(), React_Basic_Native_Generated.view()({
          style: React_Basic_Native_Styles["styles'"]([sheet.overlayContainer, React_Basic_Native_Styles.styles([React_Basic_Native_Styles.width(deviceD.width), React_Basic_Native_Styles.height(deviceD.height)])]),
          children: [React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([sheet.overlay, sheet.overlayTop, React_Basic_Native_Styles.styles([React_Basic_Native_Styles.width(deviceD.width)])])
          }), React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([sheet.rectangleWrapper, React_Basic_Native_Styles.styles([React_Basic_Native_Styles.width(deviceD.width)])]),
            children: [React_Basic_Native_Generated.view()({
              style: React_Basic_Native_Styles["styles'"]([sheet.overlay, sheet.overlayLeft])
            }), React_Basic_Native_Generated.view()({
              style: sheet.rectangle,
              children: [React_Basic_Native_Generated.view()({
                style: sheet.rectangleBorder
              }), React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([sheet.corner, sheet.top, sheet.left])
              }), React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([sheet.corner, sheet.top, sheet.right])
              }), React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([sheet.corner, sheet.bottom, sheet.left])
              }), React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([sheet.corner, sheet.bottom, sheet.right])
              })]
            }), React_Basic_Native_Generated.view()({
              style: React_Basic_Native_Styles["styles'"]([sheet.overlay, sheet.overlayRight])
            })]
          }), React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([sheet.overlay, React_Basic_Native_Styles.styles([React_Basic_Native_Styles.width(deviceD.width)])]),
            children: [React_Basic_Native_Generated.view()({
              style: sheet.bottomTextContainer,
              children: [React_Basic_Native_Generated.text()({
                style: sheet.enterCodeButtonLabel,
                children: [React_Basic_Native.string("Can't scan?")]
              }), React_Basic_Native_Generated.touchableOpacity()({
                onPress: React_Basic_Events.handler_(v.props.onAction(new ZAP_Dart_Types.PgSubmit(ZAP_Dart_Types.SQGoToEnterShortCodeScreen.value))),
                children: [React_Basic_Native_Generated.text()({
                  style: sheet.enterCodeButton,
                  children: [React_Basic_Native.string("Input Code Manually")]
                })]
              })]
            })]
          })]
        })]
      });
    };

    var initialState = {
      showQrScanner: true,
      navUnsubs: []
    };

    var didMount = function didMount(v) {
      return function __do() {
        var v1 = v.props.addListener(ZAP_Dart_Navigator_StackNavigator.DidBlur.value)(function (v1) {
          return v.setState(function (v2) {
            var $12 = {};

            for (var $13 in v2) {
              if ({}.hasOwnProperty.call(v2, $13)) {
                $12[$13] = v2[$13];
              }

              ;
            }

            ;
            $12.showQrScanner = false;
            return $12;
          });
        })();
        var v2 = v.props.addListener(ZAP_Dart_Navigator_StackNavigator.WillFocus.value)(function (v2) {
          return v.setState(function (v3) {
            var $16 = {};

            for (var $17 in v3) {
              if ({}.hasOwnProperty.call(v3, $17)) {
                $16[$17] = v3[$17];
              }

              ;
            }

            ;
            $16.showQrScanner = true;
            return $16;
          });
        })();
        return v.setState(function (v3) {
          var $20 = {};

          for (var $21 in v3) {
            if ({}.hasOwnProperty.call(v3, $21)) {
              $20[$21] = v3[$21];
            }

            ;
          }

          ;
          $20.navUnsubs = [v1.remove, v2.remove];
          return $20;
        })();
      };
    };

    var cleanup = function cleanup(state) {
      return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(state.navUnsubs)(Control_Category.identity(Control_Category.categoryFn));
    };

    var willUnmount = function willUnmount(self) {
      return cleanup(self.state);
    };

    return React_Basic.make()(component)({
      initialState: initialState,
      render: render,
      didMount: didMount,
      willUnmount: willUnmount
    });
  }();

  exports["scanQr"] = scanQr;
})(PS);

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Icons.QrTarget"] = $PS["ZAP.Dart.Icons.QrTarget"] || {};
  var exports = $PS["ZAP.Dart.Icons.QrTarget"];
  var ZAP_Dart_Svg = $PS["ZAP.Dart.Svg"];

  var qrTarget = function qrTarget(props) {
    return ZAP_Dart_Svg.svg()({
      width: props.width,
      height: props.height,
      fill: props.color,
      viewBox: "0 0 74 74",
      children: [ZAP_Dart_Svg.path()({
        d: "M1 73H0V74H1V73ZM1 1V0H0V1H1ZM73 73V74H74V73H73ZM73 1H74V0H73V1ZM0 63V73H2V63H0ZM1 74H11V72H1V74ZM2 11V1H0V11H2ZM1 2H11V0H1V2ZM72 63V73H74V63H72ZM73 72H63V74H73V72ZM74 11V1H72V11H74ZM73 0H63V2H73V0Z",
        fill: "#347DB6"
      }), ZAP_Dart_Svg.rect()({
        x: 13.5,
        y: 13.5,
        width: 19.0,
        height: 19.0,
        stroke: "black",
        strokeWidth: "5"
      }), ZAP_Dart_Svg.rect()({
        x: 20.0,
        y: 20.0,
        width: 6.0,
        height: 6.0,
        fill: "black"
      }), ZAP_Dart_Svg.path()({
        d: "M49 58H54V63H49V58Z",
        fill: "black"
      }), ZAP_Dart_Svg.path()({
        d: "M58 58H63V63H58V58Z",
        fill: "black"
      }), ZAP_Dart_Svg.rect()({
        x: 41.5,
        y: 13.5,
        width: 19.0,
        height: 19.0,
        stroke: "black",
        strokeWidth: "5"
      }), ZAP_Dart_Svg.rect()({
        x: 48.0,
        y: 20.0,
        width: 6.0,
        height: 6.0,
        fill: "black"
      }), ZAP_Dart_Svg.rect()({
        x: 13.5,
        y: 41.5,
        width: 19.0,
        height: 19.0,
        stroke: "black",
        strokeWidth: "5"
      }), ZAP_Dart_Svg.rect()({
        x: 20.0,
        y: 48.0,
        width: 6.0,
        height: 6.0,
        fill: "black"
      }), ZAP_Dart_Svg.path()({
        d: "M39 39H53V44H58V39H63V53.5H49V49H44V63H39V39Z",
        fill: "black"
      })]
    });
  };

  exports["qrTarget"] = qrTarget;
})(PS);

(function (exports) {
  var hasVideoInputDevice = require('../../src/js/hasVideoInputDevice').default;

  exports.hasVideoInputDevice = hasVideoInputDevice;
})(PS["ZAP.Dart.Utilities.DeviceInfo"] = PS["ZAP.Dart.Utilities.DeviceInfo"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Utilities.DeviceInfo"] = $PS["ZAP.Dart.Utilities.DeviceInfo"] || {};
  var exports = $PS["ZAP.Dart.Utilities.DeviceInfo"];
  var $foreign = $PS["ZAP.Dart.Utilities.DeviceInfo"];
  exports["hasVideoInputDevice"] = $foreign.hasVideoInputDevice;
})(PS);

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Screens.SelectMethod"] = $PS["ZAP.Dart.Screens.SelectMethod"] || {};
  var exports = $PS["ZAP.Dart.Screens.SelectMethod"];
  var Data_Array = $PS["Data.Array"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ZAP_Dart_Icons_QrTarget = $PS["ZAP.Dart.Icons.QrTarget"];
  var ZAP_Dart_Logo = $PS["ZAP.Dart.Logo"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var ZAP_Dart_Utilities_DeviceInfo = $PS["ZAP.Dart.Utilities.DeviceInfo"];
  var sheet = {
    container: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Styles_Colors.primary)]),
    logoContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginTop(30), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.width(280), React_Basic_Native_Styles.height(280)]),
    logo: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.width("100%"), React_Basic_Native_Styles.height("100%"), React_Basic_Native_Styles.resizeMode(React_Basic_Native_Styles.contain)]),
    instructionText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.bg), React_Basic_Native_Styles.width(240), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.lineHeight(26)]),
    selectButtonsContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles.marginTop(40), React_Basic_Native_Styles.width(280)]),
    selectButton: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.width(132), React_Basic_Native_Styles.height(132), React_Basic_Native_Styles.padding(16), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(20), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Styles_Colors.bg), React_Basic_Native_Styles.shadowOpacity(0.4), React_Basic_Native_Styles.shadowRadius(7.0), React_Basic_Native_Styles.shadowOffset({
      height: 3.0,
      width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(5)]),
    selectButtonIconContainer: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(3), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginBottom(8)]),
    selectButtonTitle: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Text.fontSize(16), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.fg)]),
    inputCodeIllus: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.black), React_Basic_Native_Styles.borderBottomWidth(1), React_Basic_Native_Styles.borderStyle(React_Basic_Native_Styles.solid), React_Basic_Native_Styles_Text.lineHeight(24), React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.iosLetterSpacing(2)])
  };

  var selectButton = function selectButton(props) {
    return React_Basic_Native_Generated.touchableOpacity()({
      style: React_Basic_Native_Styles["styles'"]([sheet.selectButton, React_Basic_Native_Styles.styles([React_Basic_Native_Styles.opacity(function () {
        if (props.disabled) {
          return 0.4;
        }

        ;
        return 1.0;
      }())])]),
      onPress: props.onPress,
      disabled: props.disabled,
      children: [React_Basic_Native_Generated.view()({
        style: sheet.selectButtonIconContainer,
        children: [props.icon]
      }), React_Basic_Native_Generated.text()({
        style: sheet.selectButtonTitle,
        children: [React_Basic_Native.string(props.title)]
      })]
    });
  };

  var component = React_Basic.createComponent("SelectMethod");

  var selectMethod = function () {
    var render = function render(v) {
      if (v.state.hasQrScanSupport instanceof Data_Maybe.Just) {
        return React_Basic_Native_Generated.view()({
          style: sheet.container,
          children: [React_Basic_Native_Generated.view()({
            style: sheet.logoContainer,
            children: [React_Basic_Native_Generated.image()({
              source: ZAP_Dart_Logo.whiteLogo,
              style: sheet.logo
            })]
          }), React_Basic_Native_Generated.text()({
            style: sheet.instructionText,
            children: [React_Basic_Native.string("Choose a method to start earning Unilever Points!")]
          }), React_Basic_Native_Generated.view()({
            style: sheet.selectButtonsContainer,
            children: [selectButton({
              icon: ZAP_Dart_Icons_QrTarget.qrTarget({
                width: 108,
                height: 108,
                color: React_Basic_Native_PropTypes_Color.white
              }),
              title: "Scan Codes",
              onPress: React_Basic_Events.handler_(v.props.onAction(new ZAP_Dart_Types.PgSubmit(ZAP_Dart_Types.CMScanQR.value))),
              disabled: !v.state.hasQrScanSupport.value0
            }), selectButton({
              icon: React_Basic_Native_Generated.text()({
                style: sheet.inputCodeIllus,
                children: Data_Array.singleton(React_Basic_Native.string("XXXXXX"))
              }),
              title: "Input Codes",
              disabled: false,
              onPress: React_Basic_Events.handler_(v.props.onAction(new ZAP_Dart_Types.PgSubmit(ZAP_Dart_Types.CMEnterShortCode.value)))
            })]
          })]
        });
      }

      ;

      if (v.state.hasQrScanSupport instanceof Data_Maybe.Nothing) {
        return Data_Monoid.mempty(React_Basic.monoidJSX);
      }

      ;
      throw new Error("Failed pattern match at ZAP.Dart.Screens.SelectMethod (line 43, column 7 - line 85, column 26): " + [v.state.hasQrScanSupport.constructor.name]);
    };

    var initialState = {
      hasQrScanSupport: Data_Maybe.Nothing.value
    };

    var didMount = function didMount(self) {
      return ZAP_Dart_Utilities_DeviceInfo.hasVideoInputDevice(function (bool) {
        return self.setState(function (v) {
          var $8 = {};

          for (var $9 in v) {
            if ({}.hasOwnProperty.call(v, $9)) {
              $8[$9] = v[$9];
            }

            ;
          }

          ;
          $8.hasQrScanSupport = new Data_Maybe.Just(bool);
          return $8;
        });
      });
    };

    return React_Basic.make()(component)({
      render: render,
      initialState: initialState,
      didMount: didMount
    });
  }();

  exports["selectMethod"] = selectMethod;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens.Splash"] = $PS["ZAP.Dart.Screens.Splash"] || {};
  var exports = $PS["ZAP.Dart.Screens.Splash"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var component = React_Basic.createComponent("Splash");

  var splash = function () {
    var render = function render(v) {
      return React_Basic_Native_Generated.view_([React_Basic_Native_Generated.text_([React_Basic_Native.string("Dart Splash")])]);
    };

    return React_Basic.makeStateless(component)(render);
  }();

  exports["splash"] = splash;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Screens"] = $PS["ZAP.Dart.Screens"] || {};
  var exports = $PS["ZAP.Dart.Screens"];
  var Control_Category = $PS["Control.Category"];
  var Data_Functor = $PS["Data.Functor"];
  var ZAP_Dart_Screens_Catalog = $PS["ZAP.Dart.Screens.Catalog"];
  var ZAP_Dart_Screens_CreatePin = $PS["ZAP.Dart.Screens.CreatePin"];
  var ZAP_Dart_Screens_EnterMobile = $PS["ZAP.Dart.Screens.EnterMobile"];
  var ZAP_Dart_Screens_EnterNumber = $PS["ZAP.Dart.Screens.EnterNumber"];
  var ZAP_Dart_Screens_EnterPin = $PS["ZAP.Dart.Screens.EnterPin"];
  var ZAP_Dart_Screens_EnterShortCode = $PS["ZAP.Dart.Screens.EnterShortCode"];
  var ZAP_Dart_Screens_GetStarted = $PS["ZAP.Dart.Screens.GetStarted"];
  var ZAP_Dart_Screens_Receipt = $PS["ZAP.Dart.Screens.Receipt"];
  var ZAP_Dart_Screens_ScanQr = $PS["ZAP.Dart.Screens.ScanQr"];
  var ZAP_Dart_Screens_SelectMethod = $PS["ZAP.Dart.Screens.SelectMethod"];
  var ZAP_Dart_Screens_Splash = $PS["ZAP.Dart.Screens.Splash"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];

  var Splash = function () {
    function Splash(value0) {
      this.value0 = value0;
    }

    ;

    Splash.create = function (value0) {
      return new Splash(value0);
    };

    return Splash;
  }();

  var GetStarted = function () {
    function GetStarted(value0) {
      this.value0 = value0;
    }

    ;

    GetStarted.create = function (value0) {
      return new GetStarted(value0);
    };

    return GetStarted;
  }();

  var EnterNumber = function () {
    function EnterNumber(value0) {
      this.value0 = value0;
    }

    ;

    EnterNumber.create = function (value0) {
      return new EnterNumber(value0);
    };

    return EnterNumber;
  }();

  var EnterPin = function () {
    function EnterPin(value0) {
      this.value0 = value0;
    }

    ;

    EnterPin.create = function (value0) {
      return new EnterPin(value0);
    };

    return EnterPin;
  }();

  var CreatePin = function () {
    function CreatePin(value0) {
      this.value0 = value0;
    }

    ;

    CreatePin.create = function (value0) {
      return new CreatePin(value0);
    };

    return CreatePin;
  }();

  var ConfirmPin = function () {
    function ConfirmPin(value0) {
      this.value0 = value0;
    }

    ;

    ConfirmPin.create = function (value0) {
      return new ConfirmPin(value0);
    };

    return ConfirmPin;
  }();

  var ShowCatalog = function () {
    function ShowCatalog(value0) {
      this.value0 = value0;
    }

    ;

    ShowCatalog.create = function (value0) {
      return new ShowCatalog(value0);
    };

    return ShowCatalog;
  }();

  var SelectMethod = function () {
    function SelectMethod(value0) {
      this.value0 = value0;
    }

    ;

    SelectMethod.create = function (value0) {
      return new SelectMethod(value0);
    };

    return SelectMethod;
  }();

  var ScanQr = function () {
    function ScanQr(value0) {
      this.value0 = value0;
    }

    ;

    ScanQr.create = function (value0) {
      return new ScanQr(value0);
    };

    return ScanQr;
  }();

  var EnterShortCode = function () {
    function EnterShortCode(value0) {
      this.value0 = value0;
    }

    ;

    EnterShortCode.create = function (value0) {
      return new EnterShortCode(value0);
    };

    return EnterShortCode;
  }();

  var EnterMobile = function () {
    function EnterMobile(value0) {
      this.value0 = value0;
    }

    ;

    EnterMobile.create = function (value0) {
      return new EnterMobile(value0);
    };

    return EnterMobile;
  }();

  var Receipt = function () {
    function Receipt(value0, value1, value2) {
      this.value0 = value0;
      this.value1 = value1;
      this.value2 = value2;
    }

    ;

    Receipt.create = function (value0) {
      return function (value1) {
        return function (value2) {
          return new Receipt(value0, value1, value2);
        };
      };
    };

    return Receipt;
  }();

  var screenRendererScreen = new ZAP_Dart_Types.ScreenRenderer(function (v) {
    if (v instanceof Splash) {
      return function (v1) {
        return ZAP_Dart_Screens_Splash.splash({});
      };
    }

    ;

    if (v instanceof GetStarted) {
      return function ($177) {
        return ZAP_Dart_Screens_GetStarted.getStarted(ZAP_Dart_Types.contramapPageProps(v.value0)($177));
      };
    }

    ;

    if (v instanceof EnterNumber) {
      return function ($178) {
        return ZAP_Dart_Screens_EnterNumber.enterNumber(ZAP_Dart_Types.contramapPageProps(v.value0)($178));
      };
    }

    ;

    if (v instanceof EnterPin) {
      return function ($179) {
        return ZAP_Dart_Screens_EnterPin.enterPin(ZAP_Dart_Types.contramapPageProps(v.value0)($179));
      };
    }

    ;

    if (v instanceof CreatePin) {
      return function ($180) {
        return ZAP_Dart_Screens_CreatePin.createPin(ZAP_Dart_Types.contramapPageProps(v.value0)($180));
      };
    }

    ;

    if (v instanceof ConfirmPin) {
      return function ($181) {
        return ZAP_Dart_Screens_CreatePin.confirmPin(ZAP_Dart_Types.contramapPageProps(v.value0)($181));
      };
    }

    ;

    if (v instanceof ShowCatalog) {
      return function (v1) {
        return ZAP_Dart_Screens_Catalog.catalog({});
      };
    }

    ;

    if (v instanceof SelectMethod) {
      return function ($182) {
        return ZAP_Dart_Screens_SelectMethod.selectMethod(ZAP_Dart_Types.contramapPageProps(v.value0)($182));
      };
    }

    ;

    if (v instanceof ScanQr) {
      return function ($183) {
        return ZAP_Dart_Screens_ScanQr.scanQr(ZAP_Dart_Types.contramapPageProps(v.value0)($183));
      };
    }

    ;

    if (v instanceof EnterShortCode) {
      return function ($184) {
        return ZAP_Dart_Screens_EnterShortCode.enterShortCode(ZAP_Dart_Types.contramapPageProps(v.value0)($184));
      };
    }

    ;

    if (v instanceof EnterMobile) {
      return function ($185) {
        return ZAP_Dart_Screens_EnterMobile.enterMobile(ZAP_Dart_Types.contramapPageProps(v.value0)($185));
      };
    }

    ;

    if (v instanceof Receipt) {
      return function ($186) {
        return ZAP_Dart_Screens_Receipt.receipt(v.value0)(v.value1)(ZAP_Dart_Types.contramapPageProps(v.value2)($186));
      };
    }

    ;
    throw new Error("Failed pattern match at ZAP.Dart.Screens (line 105, column 1 - line 105, column 55): " + [v.constructor.name]);
  });
  var scSelectMethod = new SelectMethod(Control_Category.identity(Control_Category.categoryFn));
  var scScanQr = new ScanQr(Control_Category.identity(Control_Category.categoryFn));

  var scReceipt = function scReceipt(method) {
    return function (claims) {
      return new Receipt(method, claims, Control_Category.identity(Control_Category.categoryFn));
    };
  };

  var scEnterShortCode = new EnterShortCode(Control_Category.identity(Control_Category.categoryFn));
  var scEnterMobile = new EnterMobile(Control_Category.identity(Control_Category.categoryFn));
  var functorScreen = new Data_Functor.Functor(function (f) {
    return function (m) {
      if (m instanceof Splash) {
        return new Splash(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof GetStarted) {
        return new GetStarted(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof EnterNumber) {
        return new EnterNumber(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof EnterPin) {
        return new EnterPin(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof CreatePin) {
        return new CreatePin(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof ConfirmPin) {
        return new ConfirmPin(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof ShowCatalog) {
        return new ShowCatalog(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof SelectMethod) {
        return new SelectMethod(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof ScanQr) {
        return new ScanQr(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof EnterShortCode) {
        return new EnterShortCode(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof EnterMobile) {
        return new EnterMobile(Data_Functor.map(Data_Functor.functorFn)(f)(m.value0));
      }

      ;

      if (m instanceof Receipt) {
        return new Receipt(m.value0, m.value1, Data_Functor.map(Data_Functor.functorFn)(f)(m.value2));
      }

      ;
      throw new Error("Failed pattern match at ZAP.Dart.Screens (line 102, column 8 - line 102, column 48): " + [m.constructor.name]);
    };
  });
  exports["Splash"] = Splash;
  exports["GetStarted"] = GetStarted;
  exports["EnterNumber"] = EnterNumber;
  exports["EnterPin"] = EnterPin;
  exports["CreatePin"] = CreatePin;
  exports["ConfirmPin"] = ConfirmPin;
  exports["ShowCatalog"] = ShowCatalog;
  exports["SelectMethod"] = SelectMethod;
  exports["ScanQr"] = ScanQr;
  exports["EnterShortCode"] = EnterShortCode;
  exports["EnterMobile"] = EnterMobile;
  exports["Receipt"] = Receipt;
  exports["scSelectMethod"] = scSelectMethod;
  exports["scScanQr"] = scScanQr;
  exports["scEnterShortCode"] = scEnterShortCode;
  exports["scEnterMobile"] = scEnterMobile;
  exports["scReceipt"] = scReceipt;
  exports["functorScreen"] = functorScreen;
  exports["screenRendererScreen"] = screenRendererScreen;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Flows.Mvp"] = $PS["ZAP.Dart.Flows.Mvp"] || {};
  var exports = $PS["ZAP.Dart.Flows.Mvp"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Either = $PS["Data.Either"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Now = $PS["Effect.Now"];
  var Record = $PS["Record"];
  var ZAP_Dart_ApiClient_ClaimQr = $PS["ZAP.Dart.ApiClient.ClaimQr"];
  var ZAP_Dart_Client_Constants = $PS["ZAP.Dart.Client.Constants"];
  var ZAP_Dart_Screens = $PS["ZAP.Dart.Screens"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var ZRPC_Clients_Broker = $PS["ZRPC.Clients.Broker"];

  var getDialogOpts = function getDialogOpts(cm) {
    return function (ce) {
      var invalidData = function invalidData(claimMethod) {
        return {
          title: "Sorry, we don't recognize that code.",
          message: function () {
            if (claimMethod instanceof ZAP_Dart_Types.CMScanQR) {
              return "Scan";
            }

            ;

            if (claimMethod instanceof ZAP_Dart_Types.CMEnterShortCode) {
              return "Input";
            }

            ;
            throw new Error("Failed pattern match at ZAP.Dart.Flows.Mvp (line 78, column 18 - line 81, column 9): " + [claimMethod.constructor.name]);
          }() + " codes found in Unilever products."
        };
      };

      var general = {
        title: "Oops, something's not right.",
        message: "We are unable to process your request right now."
      };
      return Record.insert(new Data_Symbol.IsSymbol(function () {
        return "primaryActionLabel";
      }))()()(Data_Symbol.SProxy.value)(function () {
        if (cm instanceof ZAP_Dart_Types.CMScanQR) {
          return "Scan Another";
        }

        ;

        if (cm instanceof ZAP_Dart_Types.CMEnterShortCode) {
          return "Input Another";
        }

        ;
        throw new Error("Failed pattern match at ZAP.Dart.Flows.Mvp (line 61, column 6 - line 64, column 5): " + [cm.constructor.name]);
      }())(function () {
        if (ce instanceof ZRPC_Clients_Broker.ClaimErrorQrCodeAlreadyClaimed) {
          return {
            title: "Nothing's here",
            message: "Looks like this code has already been used."
          };
        }

        ;

        if (ce instanceof ZRPC_Clients_Broker.ClaimErrorInvalidQrData) {
          return invalidData(cm);
        }

        ;

        if (ce instanceof ZRPC_Clients_Broker.ClaimErrorInvalidManualData) {
          return invalidData(cm);
        }

        ;

        if (ce instanceof ZRPC_Clients_Broker.ClaimErrorQrCodeNotFound) {
          return invalidData(cm);
        }

        ;
        return general;
      }());
    };
  };

  var mvpFlow = function () {
    var getClaimCode = function getClaimCode(v) {
      if (v instanceof ZAP_Dart_Types.CMScanQR) {
        return Control_Bind.bind(ZAP_Dart_Types.bindNavAction)(ZAP_Dart_Types.navPush(ZAP_Dart_Screens.scScanQr))(function (v1) {
          if (v1 instanceof ZAP_Dart_Types.SQGoToEnterShortCodeScreen) {
            return ZAP_Dart_Types.navPush(ZAP_Dart_Screens.scEnterShortCode);
          }

          ;

          if (v1 instanceof ZAP_Dart_Types.SQVal) {
            return Control_Applicative.pure(ZAP_Dart_Types.applicativeNavAction)(v1.value0);
          }

          ;
          throw new Error("Failed pattern match at ZAP.Dart.Flows.Mvp (line 32, column 40 - line 34, column 36): " + [v1.constructor.name]);
        });
      }

      ;

      if (v instanceof ZAP_Dart_Types.CMEnterShortCode) {
        return ZAP_Dart_Types.navPush(ZAP_Dart_Screens.scEnterShortCode);
      }

      ;
      throw new Error("Failed pattern match at ZAP.Dart.Flows.Mvp (line 31, column 20 - line 35, column 51): " + [v.constructor.name]);
    };

    var createPayload = function createPayload(v) {
      return function (cCode) {
        return function (now) {
          if (cCode instanceof ZAP_Dart_Types.QRCode) {
            return {
              mobileNumber: v,
              claimMethod: new ZRPC_Clients_Broker.ClaimQrMethodQrData({
                value: cCode.value0
              }),
              claimedAt: now,
              holdingId: ZAP_Dart_Client_Constants.holdingId
            };
          }

          ;

          if (cCode instanceof ZAP_Dart_Types.ShortCode) {
            return {
              mobileNumber: v,
              claimMethod: new ZRPC_Clients_Broker.ClaimQrMethodShortCode({
                value: cCode.value0
              }),
              claimedAt: now,
              holdingId: ZAP_Dart_Client_Constants.holdingId
            };
          }

          ;
          throw new Error("Failed pattern match at ZAP.Dart.Flows.Mvp (line 43, column 50 - line 55, column 10): " + [cCode.constructor.name]);
        };
      };
    };

    var submit = function submit(mob) {
      return function (cCode) {
        return Effect_Aff_Class.liftAff(ZAP_Dart_Types.monadAffNavAction)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Now.nowDateTime))(function (v) {
          return ZAP_Dart_ApiClient_ClaimQr.claimQr(createPayload(mob)(cCode)(v));
        }));
      };
    };

    return ZAP_Dart_Types.initNav(ZAP_Dart_Screens.scSelectMethod)(function (claimMethod) {
      return Control_Bind.bind(ZAP_Dart_Types.bindNavAction)(getClaimCode(claimMethod))(function (v) {
        return Control_Bind.bind(ZAP_Dart_Types.bindNavAction)(ZAP_Dart_Types.navPush(ZAP_Dart_Screens.scEnterMobile))(function (v1) {
          return Control_Bind.bind(ZAP_Dart_Types.bindNavAction)(submit(v1)(v))(function (v2) {
            return Control_Bind.discard(Control_Bind.discardUnit)(ZAP_Dart_Types.bindNavAction)(function () {
              if (v2 instanceof Data_Either.Right) {
                return ZAP_Dart_Types.navPush(ZAP_Dart_Screens.scReceipt(claimMethod)(v2.value0));
              }

              ;

              if (v2 instanceof Data_Either.Left) {
                return ZAP_Dart_Types.snavDialog(getDialogOpts(claimMethod)(v2.value0));
              }

              ;
              throw new Error("Failed pattern match at ZAP.Dart.Flows.Mvp (line 25, column 3 - line 27, column 61): " + [v2.constructor.name]);
            }())(function () {
              return ZAP_Dart_Types.navPopToTop;
            });
          });
        });
      });
    });
  }();

  exports["mvpFlow"] = mvpFlow;
  exports["getDialogOpts"] = getDialogOpts;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Nav"] = $PS["ZAP.Dart.Nav"] || {};
  var exports = $PS["ZAP.Dart.Nav"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Class_Console = $PS["Effect.Class.Console"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Uncurried = $PS["Effect.Uncurried"];
  var Foreign = $PS["Foreign"];
  var Foreign_Object = $PS["Foreign.Object"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var ZAP_Dart_Navigator_StackNavigator = $PS["ZAP.Dart.Navigator.StackNavigator"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];

  var sc = function sc(screen) {
    return ZAP_Dart_Navigator_StackNavigator.navigationScreenRouteConfig()({
      screen: screen
    });
  };

  var $$null = Data_Nullable.toNullable(Data_Maybe.Nothing.value);

  var navPopToTop = function navPopToTop(nav) {
    return Data_Functor["void"](Effect.functorEffect)(function () {
      return nav.popToTop($$null);
    });
  };

  var navPop = function navPop(nav) {
    return Data_Functor["void"](Effect.functorEffect)(function () {
      return nav.pop($$null, $$null);
    });
  };

  var navPageName = "NavPage";
  var navPageComponent = React_Basic.createComponent(navPageName);

  var mkNavPage = function () {
    var render = function render(self) {
      return self.state.body;
    };

    var initialState = {
      body: React_Basic_Native_Generated.view_([])
    };

    var didMount = function didMount(self) {
      return function __do() {
        var v = self.props.navigation.getParam("_render");
        return self.setState(function (v1) {
          var $20 = {};

          for (var $21 in v1) {
            if ({}.hasOwnProperty.call(v1, $21)) {
              $20[$21] = v1[$21];
            }

            ;
          }

          ;
          $20.body = v(self.props.navigation);
          return $20;
        })();
      };
    };

    return React_Basic.toReactComponent()(Control_Category.identity(Control_Category.categoryFn))(navPageComponent)({
      initialState: initialState,
      didMount: didMount,
      render: render
    });
  }();

  var navPush = function navPush(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (nav) {
        return function (screen) {
          var params = mkRenderParams(dictFunctor)(dictScreenRenderer)(screen);
          return function () {
            return nav.push(navPageName, Data_Nullable.toNullable(new Data_Maybe.Just(params)), $$null);
          };
        };
      };
    };
  };

  var mkRenderParams = function mkRenderParams(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (screen) {
        var cb = function cb(nav) {
          return ZAP_Dart_Types.renderScreen(dictScreenRenderer)(screen)({
            onAction: handlePageAction(dictFunctor)(dictScreenRenderer)(screen)(nav),
            addListener: function addListener(le) {
              return function (cb$prime) {
                if (le instanceof ZAP_Dart_Navigator_StackNavigator.DidBlur) {
                  return function () {
                    return nav.addListener(ZAP_Dart_Navigator_StackNavigator.didBlurListenerEvent, Effect_Uncurried.mkEffectFn1(cb$prime));
                  };
                }

                ;

                if (le instanceof ZAP_Dart_Navigator_StackNavigator.WillFocus) {
                  return function () {
                    return nav.addListener(ZAP_Dart_Navigator_StackNavigator.willFocusListenerEvent, Effect_Uncurried.mkEffectFn1(cb$prime));
                  };
                }

                ;
                throw new Error("Failed pattern match at ZAP.Dart.Nav (line 114, column 55 - line 116, column 115): " + [le.constructor.name]);
              };
            }
          });
        };

        return Foreign_Object.singleton("_render")(Foreign.unsafeToForeign(cb));
      };
    };
  };

  var handleStackNavAction$prime = function handleStackNavAction$prime(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (nav) {
        return function (v) {
          if (v instanceof ZAP_Dart_Types.NavEff) {
            return Control_Bind.bindFlipped(Effect.bindEffect)(handleStackNavAction(dictFunctor)(dictScreenRenderer)(nav))(v.value0);
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavAff) {
            return Effect_Aff.runAff_(function (v1) {
              if (v1 instanceof Data_Either.Right) {
                return handleStackNavAction(dictFunctor)(dictScreenRenderer)(nav)(v1.value0);
              }

              ;

              if (v1 instanceof Data_Either.Left) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
              }

              ;
              throw new Error("Failed pattern match at ZAP.Dart.Nav (line 71, column 12 - line 73, column 24): " + [v1.constructor.name]);
            })(v.value0);
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavCustom && v.value0 instanceof ZAP_Dart_Types.SNavPush) {
            return navPush(dictFunctor)(dictScreenRenderer)(nav)(v.value0.value0);
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavCustom && v.value0 instanceof ZAP_Dart_Types.SNavPop) {
            return navPop(nav);
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavCustom && v.value0 instanceof ZAP_Dart_Types.SNavPopToTop) {
            return navPopToTop(nav);
          }

          ;
          return Effect_Class_Console.warn(Effect_Class.monadEffectEffect)("NYI");
        };
      };
    };
  };

  var handleStackNavAction = function handleStackNavAction(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (nav) {
        return function (na) {
          var v = Control_Monad_Free.resume(ZAP_Dart_Types["functorNavAction'"](ZAP_Dart_Types.functorStackCustomAction(dictFunctor))(dictFunctor))(Data_Newtype.unwrap(ZAP_Dart_Types.newtypeNavAction)(na));

          if (v instanceof Data_Either.Left) {
            return handleStackNavAction$prime(dictFunctor)(dictScreenRenderer)(nav)(Data_Functor.map(ZAP_Dart_Types["functorNavAction'"](ZAP_Dart_Types.functorStackCustomAction(dictFunctor))(dictFunctor))(ZAP_Dart_Types.NavAction)(v.value0));
          }

          ;

          if (v instanceof Data_Either.Right) {
            return Effect_Console.log("DONE!");
          }

          ;
          throw new Error("Failed pattern match at ZAP.Dart.Nav (line 55, column 31 - line 59, column 17): " + [v.constructor.name]);
        };
      };
    };
  };

  var handlePageAction = function handlePageAction(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (screen) {
        return function (nav) {
          return function (v) {
            if (v instanceof ZAP_Dart_Types.PgBack) {
              return navPop(nav);
            }

            ;

            if (v instanceof ZAP_Dart_Types.PgCancel) {
              return navPop(nav);
            }

            ;

            if (v instanceof ZAP_Dart_Types.PgSubmit) {
              return handleStackNavAction(dictFunctor)(dictScreenRenderer)(nav)(v.value0);
            }

            ;
            throw new Error("Failed pattern match at ZAP.Dart.Nav (line 49, column 1 - line 49, column 189): " + [screen.constructor.name, nav.constructor.name, v.constructor.name]);
          };
        };
      };
    };
  };

  var mkNav = function mkNav(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (v) {
        var routeMap = Foreign_Object.singleton(navPageName)(sc(mkNavPage));
        var initialRoute = Data_Functor.map(dictFunctor)(v.initialCb)(v.initialScreen);
        var initialRouteParams = mkRenderParams(dictFunctor)(dictScreenRenderer)(initialRoute);
        return ZAP_Dart_Navigator_StackNavigator.createStackNavigator()(routeMap)({
          initialRouteName: navPageName,
          initialRouteParams: initialRouteParams
        });
      };
    };
  };

  exports["mkNav"] = mkNav;
})(PS);

(function (exports) {
  var Modal = require('react-native-modal').default;

  exports._enhancedModal = Modal;
})(PS["ZAP.Dart.Components.External.EnhancedModal"] = PS["ZAP.Dart.Components.External.EnhancedModal"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Components.External.EnhancedModal"] = $PS["ZAP.Dart.Components.External.EnhancedModal"] || {};
  var exports = $PS["ZAP.Dart.Components.External.EnhancedModal"];
  var $foreign = $PS["ZAP.Dart.Components.External.EnhancedModal"];
  var React_Basic = $PS["React.Basic"];

  var enhancedModal_ = function enhancedModal_(dictOptional) {
    return React_Basic.element($foreign["_enhancedModal"]);
  };

  exports["enhancedModal_"] = enhancedModal_;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Icons.Clear"] = $PS["ZAP.Dart.Icons.Clear"] || {};
  var exports = $PS["ZAP.Dart.Icons.Clear"];
  var ZAP_Dart_Svg = $PS["ZAP.Dart.Svg"];

  var clear = function clear(props) {
    return ZAP_Dart_Svg.svg()({
      width: props.width,
      height: props.height,
      fill: props.color,
      viewBox: "0 0 24 24",
      children: [ZAP_Dart_Svg.path()({
        d: "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      }), ZAP_Dart_Svg.path()({
        d: "M0 0h24v24H0z",
        fill: "none"
      })]
    });
  };

  exports["clear"] = clear;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Components.Dialog"] = $PS["ZAP.Dart.Components.Dialog"] || {};
  var exports = $PS["ZAP.Dart.Components.Dialog"];
  var Data_Array = $PS["Data.Array"];
  var React_Basic_Events = $PS["React.Basic.Events"];
  var React_Basic_Native = $PS["React.Basic.Native"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var React_Basic_Native_Styles_Text = $PS["React.Basic.Native.Styles.Text"];
  var ZAP_Dart_Components_External_EnhancedModal = $PS["ZAP.Dart.Components.External.EnhancedModal"];
  var ZAP_Dart_Icons_Clear = $PS["ZAP.Dart.Icons.Clear"];
  var ZAP_Dart_Styles_Colors = $PS["ZAP.Dart.Styles.Colors"];
  var ZAP_Dart_Styles_Fonts = $PS["ZAP.Dart.Styles.Fonts"];
  var ZAP_Dart_Utilities_Optional = $PS["ZAP.Dart.Utilities.Optional"];
  var sheet = {
    container: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.white), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(12), React_Basic_Native_Styles.paddingHorizontal(12), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.1))]),
    topRow: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_Styles_Flex.flexEnd(React_Basic_Native_Styles_Flex.alignmentStartEnd)), React_Basic_Native_Styles.marginTop(12)]),
    midRow: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginVertical(12), React_Basic_Native_Styles.paddingHorizontal(20)]),
    bottomRow: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch)]),
    titleText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(20), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.lineHeight(24), React_Basic_Native_Styles_Text.iosLetterSpacing(2), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.semiBold)]),
    messageText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.fontSize(16), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.iosLetterSpacing(1.5), React_Basic_Native_Styles_Text.lineHeight(18), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular)]),
    divider: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.marginTop(16), React_Basic_Native_Styles.height(1), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Styles_Colors.fgMuted)]),
    primaryActionText: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingVertical(16), React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.lineHeight(21), React_Basic_Native_Styles_Text.iosLetterSpacing(1.8), React_Basic_Native_Styles_Text.color(ZAP_Dart_Styles_Colors.primary), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Styles_Fonts.proximaNova.regular)])
  };

  var dialog = function dialog(props) {
    return ZAP_Dart_Components_External_EnhancedModal.enhancedModal_(ZAP_Dart_Utilities_Optional.srInst())({
      children: [React_Basic_Native_Generated.view()({
        style: sheet.container,
        children: [React_Basic_Native_Generated.view()({
          style: sheet.topRow,
          children: Data_Array.singleton(React_Basic_Native_Generated.touchableOpacity()({
            onPress: React_Basic_Events.handler_(props.onRequestClose),
            children: Data_Array.singleton(ZAP_Dart_Icons_Clear.clear({
              width: 32,
              height: 32,
              color: React_Basic_Native_PropTypes_Color.black
            }))
          }))
        }), React_Basic_Native_Generated.view()({
          style: sheet.midRow,
          children: Data_Array.singleton(React_Basic_Native_Generated.text()({
            style: sheet.titleText,
            children: [React_Basic_Native.string(props.title)]
          }))
        }), React_Basic_Native_Generated.view()({
          style: sheet.midRow,
          children: Data_Array.singleton(React_Basic_Native_Generated.text()({
            style: sheet.messageText,
            children: [React_Basic_Native.string(props.message)]
          }))
        }), React_Basic_Native_Generated.view()({
          style: sheet.divider,
          children: []
        }), React_Basic_Native_Generated.touchableOpacity()({
          onPress: React_Basic_Events.handler_(props.primaryAction.onPress),
          style: sheet.bottomRow,
          children: [React_Basic_Native_Generated.text()({
            style: sheet.primaryActionText,
            children: [React_Basic_Native.string(props.primaryAction.label)]
          })]
        })]
      })],
      animationIn: "fadeIn",
      animationOut: "fadeOut",
      isVisible: props.open,
      avoidKeyboard: true,
      onBackdropPress: props.onRequestClose
    });
  };

  exports["dialog"] = dialog;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Components.FullPageSpinner"] = $PS["ZAP.Dart.Components.FullPageSpinner"] || {};
  var exports = $PS["ZAP.Dart.Components.FullPageSpinner"];
  var Data_Monoid = $PS["Data.Monoid"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_PropTypes = $PS["React.Basic.Native.PropTypes"];
  var React_Basic_Native_PropTypes_Color = $PS["React.Basic.Native.PropTypes.Color"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var React_Basic_Native_Styles_Flex = $PS["React.Basic.Native.Styles.Flex"];
  var ReactNative_ActivityIndicator = $PS["ReactNative.ActivityIndicator"];
  var ReactNative_Dimensions = $PS["ReactNative.Dimensions"];
  var component = React_Basic.createComponent("FullPageSpinner");

  var fullPageSpinner = function () {
    var render = function render(props) {
      var deviceD = ReactNative_Dimensions.get("window");

      if (props.show) {
        return React_Basic_Native_Generated.view()({
          style: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgba(255)(255)(255)(0.4)), React_Basic_Native_Styles.height(deviceD.height), React_Basic_Native_Styles.width(deviceD.width), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.top(0), React_Basic_Native_Styles.zIndex(999)]),
          children: [React_Basic_Native_Generated.activityIndicator()({
            size: ReactNative_ActivityIndicator.large,
            animating: props.show
          })]
        });
      }

      ;
      return Data_Monoid.mempty(React_Basic.monoidJSX);
    };

    return React_Basic.makeStateless(component)(render);
  }();

  exports["fullPageSpinner"] = fullPageSpinner;
})(PS);

(function (exports) {
  var history = require("history");

  exports.createBrowserHistory = function (unionDict) {
    return history.createBrowserHistory;
  };
})(PS["ZAP.Dart.WebNav.History"] = PS["ZAP.Dart.WebNav.History"] || {});

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.WebNav.History"] = $PS["ZAP.Dart.WebNav.History"] || {};
  var exports = $PS["ZAP.Dart.WebNav.History"];
  var $foreign = $PS["ZAP.Dart.WebNav.History"];
  exports["createBrowserHistory"] = $foreign.createBrowserHistory;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.WebNav"] = $PS["ZAP.Dart.WebNav"] || {};
  var exports = $PS["ZAP.Dart.WebNav"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Class_Console = $PS["Effect.Class.Console"];
  var Foreign = $PS["Foreign"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_Native_Generated = $PS["React.Basic.Native.Generated"];
  var React_Basic_Native_Styles = $PS["React.Basic.Native.Styles"];
  var Record = $PS["Record"];
  var ZAP_Dart_Client_Constants = $PS["ZAP.Dart.Client.Constants"];
  var ZAP_Dart_Components_Dialog = $PS["ZAP.Dart.Components.Dialog"];
  var ZAP_Dart_Components_FullPageSpinner = $PS["ZAP.Dart.Components.FullPageSpinner"];
  var ZAP_Dart_Navigator_StackNavigator = $PS["ZAP.Dart.Navigator.StackNavigator"];
  var ZAP_Dart_Types = $PS["ZAP.Dart.Types"];
  var ZAP_Dart_WebNav_History = $PS["ZAP.Dart.WebNav.History"];

  var ListenerEventCB = function () {
    function ListenerEventCB(value0, value1) {
      this.value0 = value0;
      this.value1 = value1;
    }

    ;

    ListenerEventCB.create = function (value0) {
      return function (value1) {
        return new ListenerEventCB(value0, value1);
      };
    };

    return ListenerEventCB;
  }();

  var popStack = function popStack(self) {
    return self.setState(function (s) {
      return {
        nextListenerId: s.nextListenerId,
        stack: Data_Maybe.fromMaybe([])(Data_Array.init(s.stack)),
        store: s.store,
        loading: s.loading,
        history: s.history,
        dialog: s.dialog
      };
    });
  };

  var listenerEventCBEq = new Data_Eq.Eq(function (v) {
    return function (v1) {
      return v.value0 === v1.value0;
    };
  });

  var mkAddListener = function mkAddListener(dictCons) {
    return function (dictIsSymbol) {
      return function (self) {
        return function (key) {
          return function (stackIndex) {
            return function (f) {
              var newlecb = new ListenerEventCB(self.state.nextListenerId, f);
              var unsubscriber = self.setState(function (state) {
                var stackM = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(state.stack)(stackIndex))(function (v) {
                  return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.findIndex(function (v1) {
                    return Data_Eq.eq(listenerEventCBEq)(v1)(newlecb);
                  })(Record.get(dictIsSymbol)(dictCons)(key)(v)))(function (v1) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.deleteAt(v1)(Record.get(dictIsSymbol)(dictCons)(key)(v)))(function (v2) {
                      return Data_Array.updateAt(stackIndex)(Record.set(dictIsSymbol)(dictCons)(dictCons)(key)(v2)(v))(state.stack);
                    });
                  });
                });
                return {
                  nextListenerId: state.nextListenerId,
                  stack: Data_Maybe.fromMaybe(state.stack)(stackM),
                  store: state.store,
                  loading: state.loading,
                  history: state.history,
                  dialog: state.dialog
                };
              });

              var getCurrent = function getCurrent(state) {
                return Data_Array.index(state.stack)(stackIndex);
              };

              var addListenerToState = self.setState(function (state) {
                return {
                  nextListenerId: self.state.nextListenerId + 1 | 0,
                  stack: Data_Maybe.fromMaybe(state.stack)(Control_Bind.bind(Data_Maybe.bindMaybe)(getCurrent(state))(function (v1) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Record.set(dictIsSymbol)(dictCons)(dictCons)(key)(Data_Array.snoc(Record.get(dictIsSymbol)(dictCons)(key)(v1))(newlecb))(v1)))(function (v2) {
                      return Data_Array.updateAt(stackIndex)(v2)(state.stack);
                    });
                  })),
                  store: state.store,
                  loading: state.loading,
                  history: state.history,
                  dialog: state.dialog
                };
              });
              return Data_Functor.mapFlipped(Effect.functorEffect)(addListenerToState)(function (v) {
                return {
                  remove: unsubscriber
                };
              });
            };
          };
        };
      };
    };
  };

  var screenToJsx = function screenToJsx(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (sc) {
        return function (self) {
          return function (stackIndex) {
            var addListener = function addListener(listenerEvent) {
              return function (f) {
                if (listenerEvent instanceof ZAP_Dart_Navigator_StackNavigator.DidBlur) {
                  return mkAddListener()(new Data_Symbol.IsSymbol(function () {
                    return "didBlur";
                  }))(self)(Data_Symbol.SProxy.value)(stackIndex)(f);
                }

                ;

                if (listenerEvent instanceof ZAP_Dart_Navigator_StackNavigator.WillFocus) {
                  return mkAddListener()(new Data_Symbol.IsSymbol(function () {
                    return "willFocus";
                  }))(self)(Data_Symbol.SProxy.value)(stackIndex)(f);
                }

                ;
                throw new Error("Failed pattern match at ZAP.Dart.WebNav (line 232, column 35 - line 234, column 82): " + [listenerEvent.constructor.name]);
              };
            };

            return ZAP_Dart_Types.renderScreen(dictScreenRenderer)(sc)({
              onAction: handlePageAction(dictFunctor)(dictScreenRenderer)(self),
              addListener: addListener
            });
          };
        };
      };
    };
  };

  var handleStackNavAction$prime = function handleStackNavAction$prime(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (self) {
        return function (v) {
          if (v instanceof ZAP_Dart_Types.NavEff) {
            return Control_Bind.bind(Effect.bindEffect)(v.value0)(handleStackNavAction(dictFunctor)(dictScreenRenderer)(self));
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavAff) {
            var cb = function cb(e) {
              return self.setStateThen(function (v1) {
                return {
                  nextListenerId: v1.nextListenerId,
                  stack: v1.stack,
                  store: v1.store,
                  loading: false,
                  history: v1.history,
                  dialog: v1.dialog
                };
              })(function () {
                if (e instanceof Data_Either.Right) {
                  return handleStackNavAction(dictFunctor)(dictScreenRenderer)(self)(e.value0);
                }

                ;

                if (e instanceof Data_Either.Left) {
                  return self.setState(function (v1) {
                    return {
                      nextListenerId: v1.nextListenerId,
                      stack: v1.stack,
                      store: v1.store,
                      loading: v1.loading,
                      history: v1.history,
                      dialog: Data_Maybe.Just.create(Record.merge()()(ZAP_Dart_Client_Constants.networkDialogOpts)({
                        onAction: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
                      }))
                    };
                  });
                }

                ;
                throw new Error("Failed pattern match at ZAP.Dart.WebNav (line 305, column 8 - line 313, column 14): " + [e.constructor.name]);
              }());
            };

            return Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v1) {
              return {
                nextListenerId: v1.nextListenerId,
                stack: v1.stack,
                store: v1.store,
                loading: true,
                history: v1.history,
                dialog: v1.dialog
              };
            }))(Effect_Aff.runAff_(cb)(v.value0));
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavCustom && v.value0 instanceof ZAP_Dart_Types.SNavPush) {
            return self.setState(function (st) {
              return {
                nextListenerId: st.nextListenerId,
                stack: Data_Array.snoc(st.stack)({
                  jsx: screenToJsx(dictFunctor)(dictScreenRenderer)(v.value0.value0)(self)(Data_Array.length(st.stack)),
                  didBlur: [],
                  willFocus: []
                }),
                store: st.store,
                loading: st.loading,
                history: st.history,
                dialog: st.dialog
              };
            });
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavCustom && v.value0 instanceof ZAP_Dart_Types.SNavPop) {
            return popStack(self);
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavCustom && v.value0 instanceof ZAP_Dart_Types.SNavPopToTop) {
            return self.setState(function (st) {
              return {
                nextListenerId: st.nextListenerId,
                stack: Data_Array.take(1)(st.stack),
                store: st.store,
                loading: st.loading,
                history: st.history,
                dialog: st.dialog
              };
            });
          }

          ;

          if (v instanceof ZAP_Dart_Types.NavCustom && v.value0 instanceof ZAP_Dart_Types.SNavDialog) {
            return self.setState(function (v1) {
              return {
                nextListenerId: v1.nextListenerId,
                stack: v1.stack,
                store: v1.store,
                loading: v1.loading,
                history: v1.history,
                dialog: new Data_Maybe.Just({
                  title: v.value0.value0.title,
                  message: v.value0.value0.message,
                  primaryActionLabel: v.value0.value0.primaryActionLabel,
                  onAction: handleStackNavAction(dictFunctor)(dictScreenRenderer)(self)(v.value0.value1)
                })
              };
            });
          }

          ;
          return Effect_Class_Console.warn(Effect_Class.monadEffectEffect)("NYI");
        };
      };
    };
  };

  var handleStackNavAction = function handleStackNavAction(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (self) {
        return function (na) {
          var v = Control_Monad_Free.resume(ZAP_Dart_Types["functorNavAction'"](ZAP_Dart_Types.functorStackCustomAction(dictFunctor))(dictFunctor))(Data_Newtype.unwrap(ZAP_Dart_Types.newtypeNavAction)(na));

          if (v instanceof Data_Either.Left) {
            return handleStackNavAction$prime(dictFunctor)(dictScreenRenderer)(self)(Data_Functor.map(ZAP_Dart_Types["functorNavAction'"](ZAP_Dart_Types.functorStackCustomAction(dictFunctor))(dictFunctor))(ZAP_Dart_Types.NavAction)(v.value0));
          }

          ;

          if (v instanceof Data_Either.Right) {
            return Effect_Class_Console.log(Effect_Class.monadEffectEffect)("DONE!");
          }

          ;
          throw new Error("Failed pattern match at ZAP.Dart.WebNav (line 285, column 32 - line 289, column 17): " + [v.constructor.name]);
        };
      };
    };
  };

  var handlePageAction = function handlePageAction(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (v) {
        return function (v1) {
          if (v1 instanceof ZAP_Dart_Types.PgBack) {
            return popStack(v);
          }

          ;

          if (v1 instanceof ZAP_Dart_Types.PgCancel) {
            return Effect_Class_Console.log(Effect_Class.monadEffectEffect)("TODO - call callback");
          }

          ;

          if (v1 instanceof ZAP_Dart_Types.PgSubmit) {
            return handleStackNavAction(dictFunctor)(dictScreenRenderer)(v)(v1.value0);
          }

          ;
          throw new Error("Failed pattern match at ZAP.Dart.WebNav (line 270, column 1 - line 276, column 17): " + [v.constructor.name, v1.constructor.name]);
        };
      };
    };
  };

  var createListenerP = function createListenerP(actionType) {
    return function (listenerE) {
      return {
        action: {
          type: actionType,
          key: "StackWebNav"
        },
        context: "",
        lastState: Foreign.unsafeToForeign({}),
        state: Foreign.unsafeToForeign({}),
        type: listenerE
      };
    };
  };

  var component = React_Basic.createComponent("WebNav");

  var mkWebNav = function mkWebNav(dictFunctor) {
    return function (dictScreenRenderer) {
      return function (v) {
        var updateHistory = function updateHistory(self) {
          return function (prevS) {
            var pathCount = Data_Show.show(Data_Show.showInt)(Data_Array.length(self.state.stack));
            var isInitial = Data_Array.length(self.state.stack) === 1 && Data_Array.length(self.state.store) === 0;
            var didPush = (Data_Array.length(self.state.stack) - Data_Array.length(prevS.stack) | 0) === 1;
            var didNotUseBrowserControls = Data_Array.length(prevS.store) === Data_Array.length(self.state.store);
            var $88 = didPush && didNotUseBrowserControls;

            if ($88) {
              if (isInitial) {
                return function () {
                  return self.state.history.replace("/" + pathCount, {});
                };
              }

              ;
              return function () {
                return self.state.history.push("/" + pathCount, {});
              };
            }

            ;
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
        };

        var listenToHistory = function listenToHistory(v1) {
          return function () {
            return v1.state.history.listen(function (loc, action) {
              if (action === "POP") {
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.last(Data_String_Common.split("/")(v1.state.history.location.pathname)))(Data_Int.fromString))(function (pathCount) {
                  return v1.setState(function (s) {
                    var $92 = (Data_Array.length(s.stack) - pathCount | 0) === 1;

                    if ($92) {
                      return {
                        nextListenerId: s.nextListenerId,
                        stack: Data_Array.take(pathCount)(s.stack),
                        store: function () {
                          var v3 = Data_Array.index(s.stack)(pathCount);

                          if (v3 instanceof Data_Maybe.Just) {
                            return Data_Array.snoc(s.store)(v3.value0);
                          }

                          ;

                          if (v3 instanceof Data_Maybe.Nothing) {
                            return s.store;
                          }

                          ;
                          throw new Error("Failed pattern match at ZAP.Dart.WebNav (line 130, column 29 - line 132, column 41): " + [v3.constructor.name]);
                        }(),
                        loading: s.loading,
                        history: s.history,
                        dialog: s.dialog
                      };
                    }

                    ;
                    var $95 = (pathCount - Data_Array.length(s.stack) | 0) === 1;

                    if ($95) {
                      var v2 = Data_Array.unsnoc(s.store);

                      if (v2 instanceof Data_Maybe.Just) {
                        return {
                          nextListenerId: s.nextListenerId,
                          stack: Data_Array.snoc(s.stack)(v2.value0.last),
                          store: v2.value0.init,
                          loading: s.loading,
                          history: s.history,
                          dialog: s.dialog
                        };
                      }

                      ;

                      if (v2 instanceof Data_Maybe.Nothing) {
                        return s;
                      }

                      ;
                      throw new Error("Failed pattern match at ZAP.Dart.WebNav (line 135, column 17 - line 140, column 31): " + [v2.constructor.name]);
                    }

                    ;
                    return s;
                  });
                })();
              }

              ;
              return Data_Unit.unit;
            });
          };
        };

        var initialState = {
          nextListenerId: 0,
          stack: [],
          store: [],
          history: ZAP_Dart_WebNav_History.createBrowserHistory()({}),
          loading: false,
          dialog: Data_Maybe.Nothing.value
        };

        var initialStackE = function initialStackE(self) {
          return {
            jsx: screenToJsx(dictFunctor)(dictScreenRenderer)(Data_Functor.map(dictFunctor)(v.initialCb)(v.initialScreen))(self)(0),
            didBlur: [],
            willFocus: []
          };
        };

        var didMount = function didMount(self) {
          return function __do() {
            self.setState(function (v1) {
              return {
                stack: [initialStackE(self)],
                dialog: v1.dialog,
                history: v1.history,
                loading: v1.loading,
                nextListenerId: v1.nextListenerId,
                store: v1.store
              };
            })();
            var v1 = listenToHistory(self)();
            return Data_Unit.unit;
          };
        };

        var closeDialog = function closeDialog(self) {
          return self.setState(function (v1) {
            var $104 = {};

            for (var $105 in v1) {
              if ({}.hasOwnProperty.call(v1, $105)) {
                $104[$105] = v1[$105];
              }

              ;
            }

            ;
            $104.dialog = Data_Maybe.Nothing.value;
            return $104;
          });
        };

        var render = function render(v1) {
          var len = Data_Array.length(v1.state.stack);

          var isLastNdx = function isLastNdx(n) {
            return (len - 1 | 0) === n;
          };

          var styleForNdx = function styleForNdx(n) {
            if (isLastNdx(n)) {
              return React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1)]);
            }

            ;

            if (Data_Boolean.otherwise) {
              return React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.opacity(0.0), React_Basic_Native_Styles.height(0), React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.unsafeStyleProp("display")("none")]);
            }

            ;
            throw new Error("Failed pattern match at ZAP.Dart.WebNav (line 180, column 9 - line 180, column 37): " + [n.constructor.name]);
          };

          var wrappedEls = Data_Array.mapWithIndex(function (ndx) {
            return function (el) {
              return React_Basic_Native_Generated.view()({
                children: [el.jsx],
                style: styleForNdx(ndx)
              });
            };
          })(v1.state.stack);
          return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1)]),
            children: [ZAP_Dart_Components_FullPageSpinner.fullPageSpinner({
              show: v1.state.loading
            }), ZAP_Dart_Components_Dialog.dialog({
              open: Data_Maybe.isJust(v1.state.dialog),
              title: Data_Maybe.maybe("")(function (v2) {
                return v2.title;
              })(v1.state.dialog),
              message: Data_Maybe.maybe("")(function (v2) {
                return v2.message;
              })(v1.state.dialog),
              onRequestClose: closeDialog(v1),
              primaryAction: {
                label: Data_Maybe.maybe("")(function (v2) {
                  return v2.primaryActionLabel;
                })(v1.state.dialog),
                onPress: Control_Apply.applySecond(Effect.applyEffect)(closeDialog(v1))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (v2) {
                  return v2.onAction;
                })(v1.state.dialog))
              }
            }), React_Basic_Native_Generated.view()({
              style: React_Basic_Native_Styles.staticStyles([React_Basic_Native_Styles.flex(1)]),
              children: wrappedEls
            })]
          });
        };

        var callEventListeners = function callEventListeners(curr) {
          return function (prev) {
            var $111 = (Data_Array.length(curr) - Data_Array.length(prev) | 0) === 1;

            if ($111) {
              return function __do() {
                Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(function (cs) {
                  return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(cs.didBlur)(function (v1) {
                    return v1.value1(createListenerP("PUSH")(ZAP_Dart_Navigator_StackNavigator.didBlurListenerEvent));
                  });
                })(Data_Array.index(curr)(Data_Array.length(prev) - 1 | 0))();
                return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(function (cs) {
                  return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(cs.willFocus)(function (v1) {
                    return v1.value1(createListenerP("PUSH")(ZAP_Dart_Navigator_StackNavigator.willFocusListenerEvent));
                  });
                })(Data_Array.last(curr))();
              };
            }

            ;
            var $118 = (Data_Array.length(prev) - Data_Array.length(curr) | 0) === 1;

            if ($118) {
              return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(function (cs) {
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(cs.willFocus)(function (v1) {
                  return v1.value1(createListenerP("PUSH")(ZAP_Dart_Navigator_StackNavigator.willFocusListenerEvent));
                });
              })(Data_Array.last(curr));
            }

            ;
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
        };

        var didUpdate = function didUpdate(v1) {
          return function (v2) {
            return function __do() {
              callEventListeners(v1.state.stack)(v2.prevState.stack)();
              return updateHistory(v1)(v2.prevState)();
            };
          };
        };

        return React_Basic.toReactComponent()(Control_Category.identity(Control_Category.categoryFn))(component)({
          initialState: initialState,
          didMount: didMount,
          didUpdate: didUpdate,
          render: render
        });
      };
    };
  };

  exports["mkWebNav"] = mkWebNav;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.App"] = $PS["ZAP.Dart.App"] || {};
  var exports = $PS["ZAP.Dart.App"];
  var ReactNative_Platform = $PS["ReactNative.Platform"];
  var ZAP_Dart_Flows_Mvp = $PS["ZAP.Dart.Flows.Mvp"];
  var ZAP_Dart_Nav = $PS["ZAP.Dart.Nav"];
  var ZAP_Dart_Navigator_StackNavigator = $PS["ZAP.Dart.Navigator.StackNavigator"];
  var ZAP_Dart_Screens = $PS["ZAP.Dart.Screens"];
  var ZAP_Dart_WebNav = $PS["ZAP.Dart.WebNav"];

  var appReactComponent = function () {
    if (ReactNative_Platform.os instanceof ReactNative_Platform.Web) {
      return ZAP_Dart_WebNav.mkWebNav(ZAP_Dart_Screens.functorScreen)(ZAP_Dart_Screens.screenRendererScreen)(ZAP_Dart_Flows_Mvp.mvpFlow);
    }

    ;
    return ZAP_Dart_Navigator_StackNavigator.createAppContainer(ZAP_Dart_Nav.mkNav(ZAP_Dart_Screens.functorScreen)(ZAP_Dart_Screens.screenRendererScreen)(ZAP_Dart_Flows_Mvp.mvpFlow));
  }();

  exports["appReactComponent"] = appReactComponent;
})(PS);

(function ($PS) {
  "use strict";

  $PS["ZAP.Dart.Web.Titles"] = $PS["ZAP.Dart.Web.Titles"] || {};
  var exports = $PS["ZAP.Dart.Web.Titles"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect = $PS["Effect"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Location = $PS["Web.HTML.Location"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var hostname$prime = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.hostname);
  var documentTitles = Foreign_Object.insert("unilever.zap.com.ph")("Unilever Rewards")(Foreign_Object.empty);
  var getTitle = Data_Functor.mapFlipped(Effect.functorEffect)(hostname$prime)(function (hn) {
    return Data_Maybe.fromMaybe("ZAP Rewards")(Foreign_Object.lookup(hn)(documentTitles));
  });
  exports["documentTitles"] = documentTitles;
  exports["getTitle"] = getTitle;
})(PS);

(function ($PS) {
  // Generated by purs version 0.12.5
  "use strict";

  $PS["ZAP.Dart.Web.Main"] = $PS["ZAP.Dart.Web.Main"] || {};
  var exports = $PS["ZAP.Dart.Web.Main"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Exception = $PS["Effect.Exception"];
  var React_Basic = $PS["React.Basic"];
  var React_Basic_DOM = $PS["React.Basic.DOM"];
  var Web_DOM_NonElementParentNode = $PS["Web.DOM.NonElementParentNode"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var ZAP_Dart_App = $PS["ZAP.Dart.App"];
  var ZAP_Dart_Web_Titles = $PS["ZAP.Dart.Web.Titles"];

  var main = function __do() {
    var v = Web_HTML.window();
    var v1 = Web_HTML_Window.document(v)();
    var v2 = ZAP_Dart_Web_Titles.getTitle();
    Web_HTML_HTMLDocument.setTitle(v2)(v1)();
    var v3 = Web_DOM_NonElementParentNode.getElementById("root")(Web_HTML_HTMLDocument.toNonElementParentNode(v1))();

    if (v3 instanceof Data_Maybe.Nothing) {
      return Effect_Exception["throw"]("Container element not found")();
    }

    ;

    if (v3 instanceof Data_Maybe.Just) {
      React_Basic_DOM.render(React_Basic.element(ZAP_Dart_App.appReactComponent)({}))(v3.value0)();
      return Effect_Console.log("Successfully Rendered!")();
    }

    ;
    throw new Error("Failed pattern match at ZAP.Dart.Web.Main (line 27, column 3 - line 31, column 11): " + [v3.constructor.name]);
  };

  exports["main"] = main;
})(PS);

module.exports = PS;